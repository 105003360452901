import { z } from 'zod';

import { newError } from '@/services/errors/errors';

export type ErrorCode = `${string}-${string}`;

export const parseWithZod = <TResponse>(
  schema: z.Schema<TResponse>,
  data: unknown,
  uniqueErrorCode: ErrorCode,
  option: Partial<{
    withToast: boolean;
    withSentry: boolean;
  }> = {
    withToast: false,
    withSentry: true
  }
): Maybe<TResponse> => {
  const parsed = schema.safeParse(data);

  if (!parsed.success) {
    // eslint-disable-next-line no-restricted-syntax
    console.error(uniqueErrorCode);
    // eslint-disable-next-line no-restricted-syntax
    console.error(parsed.error.message);

    if (option.withSentry) {
      const showToastWhenDev = process.env.NODE_ENV === 'development';
      newError(
        uniqueErrorCode,
        parsed.error,
        option?.withToast ?? showToastWhenDev
      );
      //? only here
      // eslint-disable-next-line no-restricted-syntax
      console.error({ uniqueErrorCode, data });
    }
    return undefined;
  }

  return parsed.data;
};

export const isValidType = <TResponse>(
  schema: z.Schema<TResponse>,
  data: unknown,
  uniqueErrorCode: `${string}-${string}`
): data is TResponse => {
  return !!parseWithZod(schema, data, uniqueErrorCode);
};
