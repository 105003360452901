import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';

import useProcess from '@hooks/useProcess';
import { useWorkflow } from '@hooks/useWorkflow';

import { WorkflowModel } from '@models/workflow.model';

import { ParamsList } from '@/routes/routes.types';

import { HeaderDropdown } from './headerDropdown';

export const WorkflowSelector = observer(() => {
  const navigate = useNavigate();

  const workflowId = useParams()[ParamsList.WorkflowId] as string;
  const process = useProcess();
  const { workflow } = useWorkflow();

  const changeWorkflow = (workflowId: WorkflowModel['id']) => {
    navigate(`/${process?.id}/${workflowId}/editor`);
  };

  const dropdownItems = (process?.workflows ?? []).map((workflow) => ({
    id: workflow.id,
    name: workflow.name
  }));

  if (!workflow || !process) return null;
  return (
    <HeaderDropdown
      type="workflow"
      items={dropdownItems}
      currentItemId={workflowId}
      onSelect={changeWorkflow}
    />
  );
});
