import { observer } from 'mobx-react';

import ActionButton from '@atoms/button';
import { BasicModal } from '@atoms/modal';

import { Button, DialogActions, Divider } from '@mui/joy';

interface DeleteEtlModalProps {
  onDelete: () => Promise<void>;
  closeModal: () => void;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteEtlModal = ({
  onDelete,
  openModal,
  setOpenModal,
  closeModal
}: DeleteEtlModalProps) => {
  return (
    <BasicModal
      open={openModal}
      setOpen={setOpenModal}
      title={`Delete the analytics ETL`}
      width="500px"
    >
      <Divider />

      <h2 className="text-base text-left leading-6">
        Are you sure you want to delete the analytics ETL ?
      </h2>
      <DialogActions>
        <ActionButton
          color="danger"
          onClick={() => {
            void onDelete();
          }}
          value={'Delete'}
        />
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
      </DialogActions>
    </BasicModal>
  );
};

export default observer(DeleteEtlModal);
