import { z } from 'zod';

import { Atom, AtomSchema } from '../atom';
import { DatabaseSchema } from '../database/database.schema';
import {
  GenerationMetadataSchema,
  HydratedGenerationSchema
} from '../generation/generation.schema';
import { NotificationTemplateSchema } from './notification.schema';
import { TransitionSchema } from './transition.schema';
import { BaseWorkflowSchema, WorkflowSchema } from './workflow.schema';

const PermissionSchema = z.object({
  can_edit: z.boolean(),
  can_view: z.boolean(),
  can_delete: z.boolean(),
  can_leave: z.boolean()
});

const CollaboratorSchema = z.object({
  name: z.string(),
  email: z.string(),
  id: z.string(),
  permissions: PermissionSchema,
  avatar: z.string().nullish()
});
export type Collaborator = z.infer<typeof CollaboratorSchema>;

export type Permission = z.infer<typeof PermissionSchema>;

const BaseSchema = z.object({
  id: z.string(),
  name: z.string(),
  icon: z.string(),
  published: z.boolean(),
  atom: z.array(AtomSchema) as unknown as z.ZodType<Atom[]>,
  permission: PermissionSchema,
  collaborators: z.array(CollaboratorSchema),
  is_public: z.boolean(),
  updatedAt: z.string(),
  createdAt: z.string(),
  deletedAt: z.string().datetime().nullable().optional(),
  image: z.string().optional().nullable(),
  databases: z.array(DatabaseSchema),
  generationsMetadata: GenerationMetadataSchema,
  generations: z.array(HydratedGenerationSchema)
});
export type BaseProcess = z.infer<typeof BaseSchema>;

export const DraftSchema = BaseSchema.extend({
  draft: z.literal(true),
  workflows: z.array(BaseWorkflowSchema)
});

export const ProcessSchema = BaseSchema.extend({
  draft: z.literal(false),
  notificationTemplates: z.array(NotificationTemplateSchema),
  Transition: z.array(TransitionSchema),
  workflows: z.array(WorkflowSchema)
});

export const GenericStudioProcessSchema = z.discriminatedUnion('draft', [
  DraftSchema,
  ProcessSchema
]);

export type GenericStudioProcess = z.infer<typeof GenericStudioProcessSchema>;

export type StratumnProcess = z.infer<typeof ProcessSchema>;
export type DraftProcess = z.infer<typeof DraftSchema>;

export const SmallProcessSchema = z.object({
  id: z.string(),
  name: z.string(),
  updatedAt: z.string(),
  createdAt: z.string(),
  published: z.boolean(),
  icon: z.string(),
  draft: z.boolean(),
  permission: PermissionSchema,
  workflows: z.array(
    z.object({
      id: z.string(),
      published_id: z.string().nullable()
    })
  )
});
