import { BarChart3, Settings, Unplug } from 'lucide-react';

export type MenuItem = {
  id: number;
  name: string;
  icon: React.ReactElement;
  link: string;
};

export const STRATUMN_PROCESS_MENU_ITEMS: MenuItem[] = [
  {
    id: 1,
    name: 'General',
    icon: <Settings size={20} />,
    link: 'general'
  },
  {
    id: 2,
    name: 'Database',
    icon: <Settings size={20} />,
    link: 'database'
  },
  {
    id: 3,
    name: 'Generations',
    icon: <Unplug size={20} />,
    link: 'generation'
  },

  {
    id: 4,
    name: 'Settings',
    icon: <Settings size={20} />,
    link: 'settings'
  }
  // {
  //   id: 5,
  //   name: 'Notifications',
  //   icon: <Bell size={20} />,
  //   link: 'notification'
  // },
];

export const DRAFT_PROCESS_MENU_ITEMS: MenuItem[] = [
  {
    id: 1,
    name: 'General',
    icon: <Settings size={20} />,
    link: 'general'
  },
  {
    id: 2,
    name: 'Database',
    icon: <Settings size={20} />,
    link: 'database'
  },
  {
    id: 3,
    name: 'Generations',
    icon: <Unplug size={20} />,
    link: 'generation'
  },
  {
    id: 4,
    name: 'Settings',
    icon: <Settings size={20} />,
    link: 'settings'
  }
  // {
  //   id: 4,
  //   name: 'Notifications',
  //   icon: <Bell size={20} />,
  //   link: 'notification'
  // },
];

export const WORKFLOW_MENU_ITEMS: MenuItem[] = [
  {
    id: 1,
    name: 'Editor',
    icon: <Settings size={20} />,
    link: 'editor'
  },
  // {
  //   id: 3,
  //   name: 'Notes',
  //   icon: <AppWindow size={20} />,
  //   link: 'trace-info'
  // },
  // {
  //   id: 4,
  //   name: 'Overview',
  //   icon: <Grid3X3 size={20} />,
  //   link: 'trace-overview'
  // },
  // {
  //   id: 5,
  //   name: 'Variables',
  //   icon: <Variable size={20} />,
  //   link: 'global-variables'
  // },
  {
    id: 6,
    name: 'Analytics',
    icon: <BarChart3 size={20} />,
    link: 'analytics'
  },
  {
    id: 7,
    name: 'Settings',
    icon: <Settings size={20} />,
    link: 'settings'
  }
];
