import {
  CellDataType,
  Columns,
  SynchronizeDataType,
  SynchronizeOptionsType,
  SynchronizeSource
} from 'shared/src/database/database.schema';

export const DEFAULT_PROGRAMS_COLUMNS: Columns = [
  {
    field: 'name',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true
    }
  },
  {
    field: 'proportional',
    cellDataType: CellDataType.Boolean
  }
];

export const DEFAULT_ENTITIES_COLUMNS: Columns = [
  {
    field: 'label',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true,
      editableAfterGeneration: false,
      regex: '^[a-zA-Z]+$'
    }
  },
  {
    field: 'name',
    cellDataType: CellDataType.Text
  },
  {
    field: 'programs',
    cellDataType: CellDataType.Object,
    context: {
      reference: {
        databaseId: 'TO_REPLACE'
      }
    }
  },
  {
    field: 'BUIDS',
    cellDataType: CellDataType.Object,
    context: {
      reference: {
        databaseId: 'TO_REPLACE'
      }
    }
  }
];

export const DEFAULT_BUIDS_COLUMNS: Columns = [
  {
    field: 'BUID',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true
    }
  }
];

export const DEFAULT_CAG_COLUMNS: Columns = [
  {
    field: 'user',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true,
      synchronizeOptions: {
        type: SynchronizeOptionsType.Fetch,
        syncedData: {
          source: SynchronizeSource.StratumnAccountMembers,
          type: SynchronizeDataType.TeamMembers,
          options: {
            teamRowId: '37'
          }
        }
      }
    }
  },
  {
    field: 'assigned teams',
    cellDataType: CellDataType.Object,
    context: {
      multiSelectOptions: [
        'CAG General Team',
        'CAG Leadership Team',
        'CAG Search All Agents'
      ]
    }
  }
];

export const DEFAULT_FRAUD_COLUMNS: Columns = [
  {
    field: 'user',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true,
      synchronizeOptions: {
        type: SynchronizeOptionsType.Fetch,
        syncedData: {
          source: SynchronizeSource.StratumnAccountMembers,
          type: SynchronizeDataType.TeamMembers,
          options: {
            teamRowId: '38'
          }
        }
      }
    }
  },
  {
    field: 'assigned teams',
    cellDataType: CellDataType.Object,
    context: {
      multiSelectOptions: [
        'Day 9-10 Team',
        'High-Dollar Team',
        'Auto-Approval Team',
        'Fraud General Team',
        'Fraud Leadership Team',
        'Fraud Search All Agents'
      ]
    }
  }
];

export const DEFAULT_NON_FRAUD_COLUMNS: Columns = [
  {
    field: 'user',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true,
      synchronizeOptions: {
        type: SynchronizeOptionsType.Fetch,
        syncedData: {
          source: SynchronizeSource.StratumnAccountMembers,
          type: SynchronizeDataType.TeamMembers,
          options: {
            teamRowId: '39'
          }
        }
      }
    }
  },
  {
    field: 'assigned teams',
    cellDataType: CellDataType.Object,
    context: {
      multiSelectOptions: [
        'Non Fraud General Team',
        'Non Fraud Leadership Team',
        'Non Fraud Search All Agents'
      ]
    }
  }
];

export const DEFAULT_TEAMS_COLUMNS: Columns = [
  {
    field: 'code',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true
    }
  },
  {
    field: 'name',
    cellDataType: CellDataType.Text,
    context: {
      regex: '^(?!.*").*$'
    }
  }
];

export const DEFAULT_SQUADS_COLUMNS: Columns = [
  {
    field: 'name',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true,
      regex: '^(?!.*").*$'
    }
  },
  {
    field: 'team',
    cellDataType: CellDataType.Object,
    context: {
      reference: {
        databaseId: 'TO_REPLACE'
      }
    }
  }
];
