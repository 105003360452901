import { Link } from 'react-router-dom';

import { ROUTE_PROCESSES } from '@/utils/constants';

import LogoSVG from './logo.svg';

export const Logo = () => {
  return (
    <Link
      className="flex items-center justify-center h-9 border-0 bg-transparent pl-3 pr-4 pb-3 pt-2"
      to={ROUTE_PROCESSES}
    >
      <LogoSVG />
    </Link>
  );
};
