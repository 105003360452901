import { Action, BaseWorkflow, EtlJob, Workflow } from 'shared';

import { ActionModel } from '@models/action/action.model';
import { WorkflowModel } from '@models/workflow.model';

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { DndLibrary } from '@library';

export class WorkflowStore extends BaseStore<WorkflowModel> {
  currentWorkflow: Maybe<WorkflowModel>;
  constructor(rootStore: RootStore) {
    super(rootStore, WorkflowModel, 'workflow');
  }

  public setCurrentWorkflow(workflowModel: WorkflowModel) {
    this.currentWorkflow = workflowModel;
  }

  public loadWorkflows = (loadedWorkflows: Workflow[]): string[] => {
    return loadedWorkflows.map((loadedWorkflow) => {
      return this.loadWorkflow(loadedWorkflow);
    });
  };

  public loadBaseWorkflow = (
    workflowLoaded: BaseWorkflow
  ): Pick<BaseWorkflow, 'id' | 'published_id'> => {
    const workflow = new WorkflowModel(this, {
      id: workflowLoaded.id,
      workflowXML: workflowLoaded.workflow_xml,
      name: workflowLoaded.metadata.name ?? 'undefined',
      published_id: workflowLoaded.published_id,
      id_environment_variable_name:
        workflowLoaded.metadata.id_environment_variable_name,
      updatedAt: workflowLoaded.updatedAt
    });

    this.set(workflow.id, workflow);
    // /* ------------------------- Handle analytics ------------------------- */
    if (workflow.published_id) {
      void this.fetchAnalyticsForWorkflow(workflow, 1);
    }
    return {
      id: workflow.id,
      published_id: workflow.published_id
    };
  };

  private loadWorkflow = (workflowLoaded: Workflow): string => {
    const dndStore = this.rootStore.dndStore;
    const actionStore = this.rootStore.actionStore;
    const traceInfoDND = dndStore.createDndFromLoaded(
      DndLibrary.TraceInfo,
      workflowLoaded.traceInfo
    );

    const traceOverviewDND = dndStore.createDndFromLoaded(
      DndLibrary.TraceOverview,
      workflowLoaded.traceOverview
    );

    const globalVariablesDND = dndStore.createDndFromLoaded(
      DndLibrary.GlobalVariables,
      workflowLoaded.globalVariables
    );

    /* ---------------------- Handle actions and their DNDs --------------------- */

    const actionIds: ActionModel['id'][] = [];
    workflowLoaded.Action.forEach((action: Action) => {
      const newAction = actionStore.addLoadedActionToStore(action);

      actionIds.push(newAction.id);
    });

    /* ------------------------ Instanciate WorkflowModel ----------------------- */
    const workflow = new WorkflowModel(this, {
      id: workflowLoaded.id,
      workflowXML: workflowLoaded.workflow_xml,
      published_id: workflowLoaded.published_id,
      traceInfoDNDId: traceInfoDND.id,
      traceOverviewDndId: traceOverviewDND.id,
      globalVariablesDndId: globalVariablesDND.id,
      actionIds,
      name: workflowLoaded.metadata.name ?? 'No name',
      updatedAt: workflowLoaded.updatedAt
    });

    this.rootStore.workflowStore.set(workflowLoaded.id, workflow);
    // /* ------------------------- Handle analytics ------------------------- */
    if (workflow.published_id) {
      void this.fetchAnalyticsForWorkflow(workflow, 1);
    }

    return workflow.id;
  };

  public async fetchAnalyticsForWorkflow(
    workflow: WorkflowModel,
    page: number
  ) {
    if (
      this.rootStore.analyticsStore.isAnalyticsPageFetching(workflow.id, page)
    ) {
      return;
    }

    if (!workflow.published_id) {
      newError('ETL-YddiJ', 'There is no etl on this workflow');
      return;
    }
    if (workflow.published_id)
      this.rootStore.analyticsStore.setAnalyticsPageFetching(
        workflow.id,
        page,
        true
      );

    const take = workflow.analyticPageStore.getItemsPerPage();

    const fetchedAnalytics = await this.httpWrapper
      .get<
        EtlJob[]
      >(`/${workflow.published_id}/analytics?page=${page}&take=${take}`)
      .catch((error) => {
        newError('ETL-YddiJ', error);
        workflow.analyticPageStore.setPage(page, []);

        this.rootStore.analyticsStore.setAnalyticsPageFetching(
          workflow.id,
          page,
          false
        );
        return;
      });

    this.rootStore.analyticsStore.setAnalyticsPageFetching(
      workflow.id,
      page,
      false
    );

    if (!fetchedAnalytics) {
      newError(
        'ETL-VviGQ',
        `Failed to fetch page ${page} of analytics for workflow ${workflow.id}`
      );
      return;
    }
    if (fetchedAnalytics.length === 0) {
      workflow.analyticPageStore.setPage(page, []);
      return;
    }
    const parsedAnalytics = fetchedAnalytics.map((analytics) =>
      this.rootStore.analyticsStore.parseFetchedAnalytics(analytics)
    );
    workflow.analyticPageStore.setTotalNumberOfItemsInDB(
      parsedAnalytics[0]?.numberOfPods ?? 0
    );

    const filteredParsedAnalytics = parsedAnalytics.filter(
      (analytics) => analytics !== undefined
    );

    const loadedAnalytics = filteredParsedAnalytics.map((analytics) =>
      this.rootStore.analyticsStore.loadEtlJob(analytics)
    );

    if (!loadedAnalytics) return;

    // Set analytics page
    workflow.analyticPageStore.setPage(
      page,
      loadedAnalytics.map((a) => a.id)
    );

    if (loadedAnalytics.length < 1) return;
    // Set Pods page
    this.rootStore.analyticsStore.setPodPage(
      loadedAnalytics[0].id,
      page,
      loadedAnalytics[0].pods ?? []
    );

    return loadedAnalytics;
  }
}
