import { makeObservable, observable } from 'mobx';
import { HydratedGeneration, HydratedGenerationSchema } from 'shared';

import { GenerationModel } from '@models/generation.model';

import { newError } from '@/services/errors/errors';

import BaseStore from './base/base.store';
import RootStore from './root.store';

export default class GenerationStore extends BaseStore<GenerationModel> {
  private fetchingGenerations: Set<string> = new Set();

  constructor(rootStore: RootStore) {
    super(rootStore, GenerationModel, 'generations');

    makeObservable<GenerationStore, 'fetchingGenerations'>(this, {
      fetchingGenerations: observable
    });
  }

  /* --------------------------------- Helpers -------------------------------- */
  public computeCurrentlyDeployedGeneration() {
    const generations = this.toArray();
    generations.sort((generationA, generationB) => {
      return (
        new Date(generationB.getCreatedAt()).getTime() -
        new Date(generationA.getCreatedAt()).getTime()
      );
    });

    const latestSuccessfulGeneration = generations.find(
      (generation) => generation.hasSucceeded
    );

    if (latestSuccessfulGeneration) {
      generations.forEach((generation) => {
        generation.setCurrentlyDeployed(
          generation.id === latestSuccessfulGeneration.id
        );
      });
    }
  }

  public async fetchAndParseGeneration(
    id: string
  ): Promise<HydratedGeneration | undefined> {
    if (this.fetchingGenerations.has(id)) return;

    this.fetchingGenerations.add(id);

    const polledGenerationDTO = await this.httpWrapper
      .get<HydratedGeneration>(`/${id}`)
      .catch((error) => {
        newError('GNST-5LsZp', error);
      });

    this.fetchingGenerations.delete(id);

    if (!polledGenerationDTO) {
      newError('GNST-9GPnC', `Failed to poll generation ${id}`);
      return;
    }

    const parsedGeneration = this.parseFetchedGeneration(polledGenerationDTO);

    return parsedGeneration;
  }

  public loadParsedGeneration(
    hydratedGeneration: HydratedGeneration
  ): GenerationModel {
    const newGeneration = new GenerationModel(
      this,
      hydratedGeneration.id,
      hydratedGeneration.createdBy,
      hydratedGeneration.createdAt,
      hydratedGeneration.job_id,
      hydratedGeneration.process_id,
      hydratedGeneration.source,
      hydratedGeneration.pods
    );

    this.set(hydratedGeneration.id, newGeneration);

    this.computeCurrentlyDeployedGeneration();

    return newGeneration;
  }

  public parseFetchedGeneration(
    hydratedGenerationData: unknown
  ): HydratedGeneration | undefined {
    const parsedGenerationData = HydratedGenerationSchema.safeParse(
      hydratedGenerationData
    );

    if (!parsedGenerationData.success) {
      newError('GNST-ZB_ZN', parsedGenerationData.error);
      return;
    }

    return parsedGenerationData.data;
  }
}
