import { useParams } from 'react-router-dom';
import { Atom, AtomType, MetaInfo, VariableInfo } from 'shared';

import { AtomModel } from '@models/atom.model';

import { ParamsList } from '@/routes/routes.types';

import useStores from './useStore';

type UseDataProps<
  TType extends AtomType,
  TInitialData extends Atom<TType>['data']
> = {
  /**
   * @description the atom type (which schema should be used to decode data)
   */
  type: TType;

  /**
   * @description Initial data of the atom (at creation)
   */
  initialData: TInitialData;

  /**
   * @description The ID of the element using the atom
   */
  sourceId: MetaInfo['source']['elementId'];

  /**
   * @description The ID of the parent containing the element
   */
  parentId: MetaInfo['source']['parentId'];

  /**
   * @description The kind of parent
   * @example database, trace_overview
   */
  parentKind: MetaInfo['source']['parentKind'];

  variableInfo?: VariableInfo;
};

/**
 * Returns the atom associated with a Drag & Drop block.
 * If the atom doesn't exist, it will be created.
 */
export const useAtom = <TType extends AtomType>({
  type,
  initialData,
  sourceId,
  parentId,
  parentKind,
  variableInfo
}: UseDataProps<TType, Atom<TType>['data']>): Maybe<
  AtomModel<Atom<TType>['data']>
> => {
  const { atomStore } = useStores();
  const processId = useParams()[ParamsList.ProcessId] as string;

  /* eslint-disable */
  return atomStore.useAtom<TType, any>(
    type,
    initialData,
    sourceId,
    parentId,
    parentKind,
    processId,
    variableInfo
  ) as Maybe<AtomModel<Atom<TType>['data']>>;
};
