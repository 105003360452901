import { CustomCellRendererProps } from 'ag-grid-react';
import { RowData } from 'shared';
import {
  AtomReference,
  RowReference
} from 'shared/src/atom/atomReference.schema';

import { GroupedBadges } from '@components/database/groupedBadges';

import { useRowReferences } from '@hooks/useRowReferences';

import { SelectorOption } from '../customEditors/multi-select.editor';

export const BadgeRenderer = (
  params: CustomCellRendererProps<
    RowData,
    AtomReference[] | string[] | undefined
  >
) => {
  // TODO: find a working way to simulate focus/edit state to trigger tooltip
  // useEffect(() => {
  //   if (params.node.rowIndex !== null && params.column?.getId() !== undefined) {
  //     params.api.setFocusedCell(params.node.rowIndex, params.column.getId());
  //   }
  // }, []);

  const finalSelectedOptions: SelectorOption[] = [];
  const rowReferences: RowReference[] = useRowReferences(
    params.value && params.value.every((val) => typeof val !== 'string')
      ? params.value
      : []
  );

  if (params.value && params.value.every((val) => typeof val !== 'string')) {
    finalSelectedOptions.push(
      ...rowReferences.map((rowRef) => ({
        label: rowRef.displayedValue,
        value: rowRef.dataItemId
      }))
    );
  }

  if (params.value && params.value.every((val) => typeof val === 'string')) {
    finalSelectedOptions.push(
      ...params.value.map((val) => ({
        label: val,
        value: val
      }))
    );
  }

  return <GroupedBadges selectedOptions={finalSelectedOptions} />;
};
