import { useEffect } from 'react';

import { GitCommitHorizontal } from 'lucide-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@components/ui/accordion';
import { Badge } from '@components/ui/badge';
import { SettingsContainer, SettingsContent } from '@components/ui/settings';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { LoaderBox } from '@/components/ui/loader';
import { ParamsList } from '@/routes/routes.types';
import { ENV, ENV_TO_READABLE_NAME } from '@/utils/constants';
import { CircularProgress } from '@mui/joy';
import { LogViewer } from '@patternfly/react-log-viewer';

import { POLL_INTERVAL, generationStatusToProgress } from '..';
import { GenerationDetailsContainer } from './generationDetails.style';

const GenerationDetails = () => {
  const process = useProcess();
  const { generationStore } = useStores();
  const generationId = useParams<string>()[ParamsList.GenerationId];

  const generation = generationStore.get(generationId);

  const formatLogsForDisplay = (logs?: string | null) => {
    if (!logs || typeof logs != 'string') return [];

    try {
      const parsedLogs = JSON.parse(logs) as Record<string, unknown> | string;

      if (typeof parsedLogs !== 'object') {
        return parsedLogs;
      }

      if (parsedLogs.stack) {
        return parsedLogs.stack as string;
      }

      return JSON.stringify(parsedLogs, null, 2);
    } catch (_error) {
      return logs;
    }
  };

  useEffect(() => {
    if (!generation || !generation.shouldBePolled) return;

    const interval = setInterval((): void => {
      void generationStore
        .fetchAndParseGeneration(generation.id)
        .then((parsedGeneration) => {
          if (!parsedGeneration) {
            return;
          }

          generation.setPods(parsedGeneration.pods);

          if (!generation.hasFinished) {
            return;
          }

          generation.setCurrentlyDeployed(generation.hasSucceeded);
          generationStore.computeCurrentlyDeployedGeneration();

          return clearInterval(interval);
        });
    }, POLL_INTERVAL);

    return () => clearInterval(interval);
  }, [generation, generationStore]);

  if (!generationId) {
    return <div>Generation ID not found</div>;
  }

  if (!generation) {
    void generationStore
      .fetchAndParseGeneration(generationId)
      .then((parsedGeneration) => {
        if (!parsedGeneration) {
          return;
        }
        return generationStore.loadParsedGeneration(parsedGeneration);
      });
  }

  if (!process || process.isSmallProcess) {
    return (
      <LoaderBox>
        <CircularProgress />
      </LoaderBox>
    );
  }

  if (!generation) {
    return <div>Generation not found</div>;
  }

  return (
    <SettingsContainer>
      <SettingsContent>
        <GenerationDetailsContainer>
          <div className="flex mb-5">
            <div className="flex-1">
              <div className="flex flex-col leading-6">
                <div className="text-zinc-500">Generation ID</div>
                <div className="font-medium text-zinc-950">{generation.id}</div>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col leading-6">
                <div className="text-zinc-500">Status</div>
                <div className="flex flex-row items-center">
                  {generationStatusToProgress(generation.status)}
                  <div className="ml-2 font-medium text-zinc-950">
                    {generation.status}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col leading-6">
                <div className="text-zinc-500">Deployed to</div>
                <div className="flex flex-row items-center font-medium text-zinc-950">
                  {ENV_TO_READABLE_NAME[ENV]}
                  {generation.getCurrentlyDeployed() && (
                    <Badge className="ml-2 font-normal" variant={'pastelBlue'}>
                      Current
                    </Badge>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex mb-5">
            <div className="flex-1">
              <div className="flex flex-col leading-6">
                <div className="text-zinc-500">Deployed by</div>
                <div className="flex flex-row items-center font-medium text-zinc-950">
                  {generation.getCreatedBy().name}
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col leading-6">
                <div className="text-zinc-500">Triggered at</div>
                <div className="flex flex-row items-center font-medium text-zinc-950">
                  {new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'medium',
                    timeStyle: 'short'
                  }).format(new Date(generation.getCreatedAt()))}
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col leading-6">
                <div className="text-zinc-500">Duration</div>
                <div className="flex flex-row items-center font-medium text-zinc-950">
                  {generation.duration}
                </div>
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="flex-1">
              <div className="flex flex-col leading-6">
                <div className="text-zinc-500">Source</div>
                <div className="flex flex-row items-center">
                  <GitCommitHorizontal size={18} />
                  <div className="font-medium ml-2 text-zinc-950">
                    {generation.sourceTag}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GenerationDetailsContainer>

        <div className="border-b border-solid border-zinc-200 -mx-144 mb-10"></div>

        <h1 className="mb-6 font-medium">Generation details</h1>

        <Accordion type="single" collapsible>
          <AccordionItem
            value="item-1"
            className="rounded-md border border-solid border-zinc-300 bg-white shadow-md bg-clip-border text-sm font-normal"
          >
            <AccordionTrigger className="px-4">
              <div className="flex items-center">
                <div className="mr-2">Logs</div>
                {generationStatusToProgress(generation.status)}
              </div>
            </AccordionTrigger>
            <AccordionContent className="bg-zinc-50 h-72 rounded-b-md border-t border-solid border-zinc-300 font-mono pl-1">
              <LogViewer
                data={formatLogsForDisplay(generation.targetedPod?.logs)}
                height="100%"
                hasLineNumbers={true}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </SettingsContent>
    </SettingsContainer>
  );
};

export default observer(GenerationDetails);
