import { cn } from '@/lib/utils';

export const LoaderContainer = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={cn(
        className,
        'h-screen w-screen absolute top-0 left-0 bg-white'
      )}
    />
  );
};

export const LoaderBox = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={cn(className, 'h-3/4 flex justify-center items-center')}
    />
  );
};
