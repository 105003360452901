import { useState } from 'react';

import {
  Activity,
  MoreHorizontal,
  Pencil,
  Pyramid,
  Settings,
  Trash
} from 'lucide-react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { DeleteDialogContent } from '@components/dialogs/delete.dialog';
import { Dialog, DialogTrigger } from '@components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@components/ui/dropdown-menu';

import useStores from '@hooks/useStore';

import { ProcessModel } from '@models/process.model';

import { TRACE_UI_URL } from '@/utils/constants';
import { Skeleton, Tooltip } from '@mui/joy';

import { ViewType } from '../home.utils';
import {
  LastEditContainer,
  LastEditInfo,
  ProcessBody,
  ProcessCardContainer,
  ProcessFooter,
  ProcessHeader,
  ProcessIcon,
  ProcessInfos,
  ProcessName,
  ProcessNameEllipsis,
  ProcessStatus,
  RightSide
} from './processCard.style';
import { getTimeAgo } from './processCard.utils';

interface ProcessCardProps {
  process: ProcessModel;
  view: ViewType;
  loading?: boolean;
}

const ProcessCard = ({ process, view, loading = false }: ProcessCardProps) => {
  const [isIconLoading, setIsIconLoading] = useState<boolean>(true);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const hasBeenGenerated =
    (process &&
      process.workflows.length > 0 &&
      process.workflows[0]?.published_id) ||
    process?.published;

  const divElement = document.getElementById(process.id);

  const isTextTruncated =
    divElement && divElement.scrollWidth > divElement?.clientWidth;

  const renderFooter = () => {
    return (
      <ProcessFooter $view={view}>
        <LastEditContainer>
          {loading ? (
            <Skeleton
              variant="circular"
              width={18}
              height={18}
              loading={loading}
              animation="wave"
            />
          ) : (
            <Activity size={14} />
          )}

          <LastEditInfo color="neutral" level="title-sm" noWrap>
            <Skeleton loading={loading} animation="wave">
              Last updated {getTimeAgo(process.createdAt)}
            </Skeleton>
          </LastEditInfo>
        </LastEditContainer>
      </ProcessFooter>
    );
  };

  const { processStore } = useStores();

  async function deleteProcess() {
    const response = await processStore.deleteProcess(process.id);
    if (response) {
      window.location.assign('/');
    }
  }

  const stratumnEditorPath = `${process.id}`;

  return (
    <ProcessCardContainer
      $isLoading={loading}
      $view={view}
      to={loading ? '' : `${stratumnEditorPath}/general`}
      draggable="false"
      data-process-name={process.name ?? 'Unnamed process'}
    >
      <ProcessHeader>
        <ProcessIcon src={process.icon} onLoad={() => setIsIconLoading(false)}>
          <Skeleton loading={loading || isIconLoading} animation="wave" />
        </ProcessIcon>
        <ProcessInfos>
          <ProcessName level="title-md">
            <Skeleton loading={loading} animation="wave">
              <Tooltip
                title={process.name}
                enterDelay={200}
                variant="outlined"
                color="neutral"
                disableHoverListener={!isTextTruncated}
              >
                <ProcessNameEllipsis id={process.id}>
                  {process.name}
                </ProcessNameEllipsis>
              </Tooltip>
            </Skeleton>
          </ProcessName>
          {!process.draft && (
            <LastEditInfo color="neutral" level="title-sm" noWrap>
              <Skeleton loading={loading} animation="wave">
                Process with 1 workflow
              </Skeleton>
            </LastEditInfo>
          )}
        </ProcessInfos>
        {view === ViewType.List && renderFooter()}
        <RightSide>
          {loading ? (
            <Skeleton
              variant="circular"
              width={8}
              height={8}
              loading={loading}
              animation="wave"
            />
          ) : (
            !process.draft && (
              <Tooltip
                title={
                  process.published
                    ? 'This workflow is in production'
                    : 'This workflow is not yet in production'
                }
                enterDelay={700}
                variant="outlined"
                color="primary"
              >
                <ProcessStatus $isPublished={process.published}></ProcessStatus>
              </Tooltip>
            )
          )}

          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DropdownMenu>
              <DropdownMenuTrigger
                className="hover:bg-muted p-2 rounded-md focus:outline-none"
                onClick={(e) => e.stopPropagation()}
              >
                <MoreHorizontal size={16} />
              </DropdownMenuTrigger>
              <DropdownMenuContent onClick={(e) => e.stopPropagation()}>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${stratumnEditorPath}/general`);
                  }}
                >
                  <Pencil size={16} /> Editor
                </DropdownMenuItem>
                {!process.draft && hasBeenGenerated && (
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        `${TRACE_UI_URL}/workflowoverview/${process?.workflows[0]?.published_id}`,
                        '_blank',
                        'noopener,noreferrer'
                      );
                    }}
                  >
                    <Pyramid size={16} /> Open in Trace
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${process.id}/settings`);
                  }}
                >
                  <Settings size={16} /> Settings
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DialogTrigger asChild>
                  <DropdownMenuItem
                    className="focus:text-red-600"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Trash size={16} /> Delete
                  </DropdownMenuItem>
                </DialogTrigger>
              </DropdownMenuContent>
            </DropdownMenu>
            <DeleteDialogContent
              setOpen={setIsDialogOpen}
              nuke={deleteProcess}
              isDraft={process.draft}
            />
          </Dialog>
        </RightSide>
      </ProcessHeader>
      {view === ViewType.Grid && (
        <>
          <ProcessBody></ProcessBody>
          {renderFooter()}
        </>
      )}
    </ProcessCardContainer>
  );
};

export default observer(ProcessCard);
