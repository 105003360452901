import { Link, LinkProps } from 'react-router-dom';

import { cn } from '@/lib/utils';

const OverviewContainer = ({ children, className = '' }) => (
  <div className={cn(`w-full max-w-[1150px] flex flex-col gap-14`, className)}>
    {children}
  </div>
);
interface WorkflowCardProps extends LinkProps {}

export const workflowCardClassName =
  'relative flex flex-col box-border rounded-lg bg-white transition-shadow duration-150 h-full overflow-hidden tracking-normal shadow-verail hover:shadow-verailHover';

const WorkflowCardContainer = ({ children, ...props }: WorkflowCardProps) => (
  <Link className={workflowCardClassName} {...props}>
    {children}
  </Link>
);

const WorkflowPreview = ({ children, className = '' }) => (
  <div className={cn(`w-full h-[60%]`, className)}>{children}</div>
);
const WorkflowInfos = ({ children, className = '' }) => (
  <div className={cn(`flex justify-between gap-3 p-3 pb-2`, className)}>
    {children}
  </div>
);
const WorkflowName = ({ children, className = '' }) => (
  <span
    className={cn(`h-6 text-sm font-medium text-zinc-950 truncate`, className)}
  >
    {children}
  </span>
);
const WorkflowStatus = ({ children, className = '' }) => (
  <span className={cn(`h-6`, className)}>{children}</span>
);

const LastEditInfo = ({ children, className = '' }) => (
  <span className={cn(`text-sm text-zinc-500`, className)}>{children}</span>
);

const Side = ({ children, className = '' }) => (
  <div className={cn(`flex flex-col gap-[0.4rem]`, className)}>{children}</div>
);

export {
  OverviewContainer,
  WorkflowCardContainer,
  WorkflowName,
  WorkflowStatus,
  WorkflowPreview,
  WorkflowInfos,
  Side,
  LastEditInfo
};
