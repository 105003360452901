import { createZodDto } from 'nestjs-zod';
import { z } from 'zod';

import { EtlJob } from '../analytics/etl.schema';
import { PodSchema } from './pod.schema';

/* ---------------------------------- ENUMS --------------------------------- */

export enum JobStatus {
  RECEIVED = 'RECEIVED',
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

/* ---------------------------------- DTO FOR METIER JOBS ---------------------------------- */
export const jobSchema = <T extends z.ZodRawShape>(
  typeSchema: z.ZodObject<T>
) =>
  z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().optional().nullable(),
    cron_expression: z.string().optional(),
    data: typeSchema,
    metadata: z
      .object({
        store_execution_logs: z.boolean().optional()
      })
      .optional()
      .nullish(),
    status: z.enum([
      JobStatus.COMPLETED,
      JobStatus.IN_PROGRESS,
      JobStatus.RECEIVED,
      JobStatus.SCHEDULED
    ]),
    created_by: z.string(),
    starts_at: z.string().optional(),
    ends_at: z.string().optional().nullable(),
    sentry_dsn: z.string().optional().nullable(),
    sentry_monitor: z.string().optional().nullable(),
    image: z.string().optional().nullable(),
    pods: z.array(PodSchema).optional().nullable()
  });

export const createJobSchema = <T extends z.ZodRawShape>(
  typeSchema: z.ZodObject<T>
) =>
  jobSchema(typeSchema).omit({
    id: true,
    status: true,
    pods: true
  });

export const updateJobSchema = <T extends z.ZodRawShape>(
  typeSchema: z.ZodObject<T>
) =>
  jobSchema(typeSchema).omit({
    id: true,
    pods: true
  });

export const webhookDataSchema = z.object({
  webhook_url: z.string(),
  webhook_payload: z.string()
});

export const compilerDataSchema = z
  .object({
    workflows: z.record(z.string(), z.string()).optional(),
    repositories: z.record(z.string(), z.any())
  })
  .strict();
export type CompilerDataType = z.infer<typeof compilerDataSchema>;

const compilerJobSchema = jobSchema(compilerDataSchema);
const createCompilerJobSchema = createJobSchema(compilerDataSchema);

export type CompilerJobDTO = z.infer<typeof compilerJobSchema>;
export type CreateCompilerJobDTO = z.infer<typeof createCompilerJobSchema>;

const webhookJobSchema = jobSchema(webhookDataSchema);
export type WebhookJobDTO = z.infer<typeof webhookJobSchema>;

export type AllJobsDTO = EtlJob | CompilerJobDTO | WebhookJobDTO;

/* ---------------------------------- PAGINATION --------------------------------- */

export const GetJobParamsSchema = z
  .object({
    page: z.coerce.number().optional(),
    take: z.coerce.number().optional()
  })
  .refine(
    (params) => {
      if (params.page !== undefined) {
        return params.take !== undefined;
      }
      return true;
    },
    {
      message:
        "If 'page' is defined, 'take' must also be defined in query params",
      path: ['take']
    }
  );

export class GetJobParamsDto extends createZodDto(GetJobParamsSchema) {}
