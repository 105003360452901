import React, { useEffect, useRef } from 'react';

import NavigatedViewer from 'bpmn-js/lib/NavigatedViewer';
import gridModule from 'diagram-js-grid';

import { Canvas } from '@components/editor/plugin/dragging/Dragging';

import { WorkflowModel } from '@models/workflow.model';

import { newError } from '@/services/errors/errors';

interface BpmnPreviewProps {
  xml: WorkflowModel['workflowXML'];
}
const BpmnPreview = ({ xml }: BpmnPreviewProps) => {
  const bpmnContainerRef = useRef(null);

  useEffect(() => {
    const viewer = new NavigatedViewer({
      container: bpmnContainerRef.current ?? undefined,
      additionalModules: [
        gridModule,
        {
          moveCanvas: ['value', null],
          zoomScroll: ['value', null],
          interactionEvents: ['value', null]
        }
      ]
    });

    viewer
      .importXML(xml)
      .then(({ warnings }) => {
        if (warnings.length) {
          console.warn(warnings);
        }

        const canvas = viewer.get<Canvas>('canvas');
        canvas.zoom('fit-viewport');
        canvas.zoom(canvas.zoom() * 0.85);
      })
      .catch((error) => {
        newError('EDITOR-fg7jz', error, true);
      });

    document.getElementsByClassName('bjs-powered-by')[0]?.remove();

    return () => {
      viewer.destroy();
    };
  }, [xml]);

  return <div className="h-full bg-gray-100" ref={bpmnContainerRef}></div>;
};

export default BpmnPreview;
