import { makeObservable, observable } from 'mobx';
import { Class } from 'utility-types';

import Model from '@models/base/base.model';

import BaseStore from '@stores/base/base.store';
import RootStore from '@stores/root.store';

export default class AsyncStore<
  GenericModel extends Model
> extends BaseStore<GenericModel> {
  loading: boolean;

  constructor(
    rootStore: RootStore,
    model: Class<GenericModel>,
    override_store_url?: string
  ) {
    super(rootStore, model, override_store_url);
    this.loading = false;

    makeObservable(this, {
      loading: observable
    });
  }

  public async createNew<T = GenericModel>(el: unknown): Promise<T> {
    const data = await this.httpWrapper.post<T>('/', el);

    if (!data) {
      throw Error('Error while creating the Generic model');
    }

    return data;
  }
}
