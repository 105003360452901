import { StudioUser } from 'shared';

import { Avatar, AvatarFallback, AvatarImage } from '@components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@components/ui/dropdown-menu';

import { ACCOUNT_UI_URL } from '@/utils/constants';
import { logout } from '@/utils/logout';

interface HeaderUserMenuProps {
  user: StudioUser;
}

export const HeaderUserMenu = ({ user }: HeaderUserMenuProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar className="cursor-pointer h-9 w-9">
          <AvatarImage
            src={
              user?.avatar != null
                ? user?.avatar
                : 'https://www.w3schools.com/howto/img_avatar.png'
            }
          />
          {user && (
            <AvatarFallback>{`${user.firstName && user.firstName[0]}${user.lastName && user?.lastName[0]}`}</AvatarFallback>
          )}
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-2 border border-solid border-gray-200">
        <DropdownMenuLabel>
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {user.firstName} {user.lastName}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {user.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="cursor-pointer"
            onClick={() => (location.href = ACCOUNT_UI_URL)}
          >
            Account
          </DropdownMenuItem>
          <DropdownMenuItem className="cursor-pointer" onClick={() => logout()}>
            Logout
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
