import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  CreateEtlJobDTO,
  EtlJob,
  JobStatus,
  PauseJobDTO,
  Pod,
  PodSchema,
  PodStatus,
  UpdateEtlJobDTO,
  createEtlJobSchema,
  etlJobSchema,
  updateEtlJobSchema
} from 'shared';

import { AnalyticsModel } from '@models/analytics.model';
import { WorkflowModel } from '@models/workflow.model';

import { newError } from '@/services/errors/errors';
import { ENV } from '@/utils/constants';
import { parseWithZod } from '@/utils/parseZodSchema';

import BaseStore from './base/base.store';
import RootStore from './root.store';

export class AnalyticsStore extends BaseStore<AnalyticsModel> {
  fetchingAnalyticsPages: Map<string, Set<number>> = new Map();
  fetchingAnalytics: Set<string> = new Set();
  private paginatedPods: Map<AnalyticsModel['id'], Map<number, Pod[]>> =
    new Map();
  pollingIntervals: Map<string, NodeJS.Timeout> = new Map();
  constructor(rootStore: RootStore) {
    super(rootStore, AnalyticsModel, 'analytics');
    this.store_ready = true;

    makeObservable(this, {
      fetchingAnalyticsPages: observable,
      fetchingAnalytics: observable,
      fetchAndParseAnalytics: action
    });
  }
  /* --------------------------------- Helpers -------------------------------- */

  public async fetchAndParseAnalytics(
    etl_id: AnalyticsModel['id'],
    workflow_published_id: string
  ): Promise<EtlJob | undefined> {
    if (this.fetchingAnalytics.has(workflow_published_id)) return;

    this.fetchingAnalytics.add(workflow_published_id);
    const fetchedSingleEtl = await this.httpWrapper
      .get<EtlJob>(`/etl/${etl_id}`)
      .catch((error) => {
        newError('ETL-N0i0W', error);
        this.fetchingAnalytics.delete(workflow_published_id);
        return;
      });

    runInAction(() => {
      this.fetchingAnalytics.delete(workflow_published_id);
    });

    if (!fetchedSingleEtl || Object.keys(fetchedSingleEtl).length === 0) {
      newError('ETL-MWwSj', `ETL with ID  ${etl_id} not found`);
      return;
    }

    const parsedAnalytics = this.parseFetchedAnalytics(fetchedSingleEtl);
    if (!parsedAnalytics) return;

    // Updating pods
    if (parsedAnalytics.pods && parsedAnalytics.pods.length > 0) {
      const analyticsToUpdate = this.analyticsToPoll.find(
        (a) => a.id === etl_id
      );
      runInAction(() => {
        parsedAnalytics.pods &&
          analyticsToUpdate?.setPods(parsedAnalytics.pods);
      });
    }

    return parsedAnalytics;
  }

  public parseFetchedAnalytics(analytics: unknown): EtlJob | undefined {
    const parsedAnalyticsData = etlJobSchema.safeParse(analytics);

    if (!parsedAnalyticsData.success) {
      newError('ETL-oVUUz', parsedAnalyticsData.error);
      return;
    }
    return parsedAnalyticsData.data;
  }

  public loadEtlJob(analytics: EtlJob): AnalyticsModel {
    const newAnalytics = new AnalyticsModel(this, analytics);

    this.set(analytics.id, newAnalytics);

    return newAnalytics;
  }

  getJobById(id: Maybe<AnalyticsModel['id']>): Maybe<AnalyticsModel> | Error {
    if (!id) return;
    const job: Maybe<AnalyticsModel> = this.get(id);

    if (!job) {
      newError(
        'ETL-usKgP',
        `job with id ${id} not found`,
        ['local', 'staging'].includes(ENV),
        {
          errorType: 'warning'
        }
      );
      return;
    }

    return job;
  }

  public async createEtlJob(job: CreateEtlJobDTO) {
    try {
      const parsedData = parseWithZod(createEtlJobSchema, job, 'ETL-Lm9nc');

      if (!parsedData) {
        throw newError('DND-lyVp4', 'Invalid input : Failed Zod validation.');
      }

      const result = await this.httpWrapper.post<EtlJob>('/', parsedData);

      if (!result || !result.id) {
        throw newError(
          'ETL-upnOp',
          `Failed to create ETL job. Invalid response: ${JSON.stringify(result)}`
        );
      }

      return this.loadEtlJob(result);
    } catch (error) {
      newError('ETL-MIkoD', error, true);
      return null;
    }
  }

  public async updateEtlJob(id: AnalyticsModel['id'], job: UpdateEtlJobDTO) {
    const parsedData = parseWithZod(updateEtlJobSchema, job, 'ETL-AUxGX');
    if (!parsedData) return;
    const etlJob: Maybe<AnalyticsModel> =
      await this.httpWrapper.post<AnalyticsModel>(`/${id}`, parsedData);
    const etlStore = this.get(id);
    if (etlJob && etlStore) {
      runInAction(() => {
        Object.assign(etlStore, job);
      });
    }

    return etlJob;
  }

  public async pauseEtlJob(id: AnalyticsModel['id'], data: PauseJobDTO) {
    const etlJob: Maybe<AnalyticsModel> =
      await this.httpWrapper.post<AnalyticsModel>(`/${id}/pause`, data);
    const etlStore = this.get(id);
    const newStatus =
      data && data.pause === true ? JobStatus.RECEIVED : JobStatus.IN_PROGRESS;
    if (etlJob && etlStore) {
      runInAction(() => {
        etlStore.status = newStatus;
      });
    }

    return etlJob;
  }

  public async triggerEtlJob(
    id: AnalyticsModel['id'],
    workflow: WorkflowModel
  ): Promise<Pod | undefined> {
    const pod = await this.httpWrapper.post<Pod>(`/${id}/trigger`, {});
    const parsePod = PodSchema.safeParse(pod);
    if (!parsePod.success) {
      newError('ETL-6Ka4t', parsePod.error);
      return;
    }
    workflow.analyticPageStore.setTotalNumberOfItemsInDB(
      workflow.analyticPageStore.getTotalNumberOfItemsInDB() + 1
    );

    const etlStore = this.get(id);
    if (etlStore) {
      runInAction(() => {
        etlStore.pods = [parsePod.data, ...etlStore.pods];
      });
    }
    const currentPodPage = this.getPodPageItems(id, 1);
    const newPodList = [parsePod.data, ...currentPodPage];
    this.setPodPage(id, 1, newPodList);
    return pod;
  }

  get analyticsToPoll(): AnalyticsModel[] {
    return this.toArray().filter((analytics) => analytics.shouldBePolled);
  }

  get hasRunningJobs() {
    return this.analyticsToPoll.some((a) =>
      a.pods.some((pod) => pod.status === PodStatus.RUNNING)
    );
  }

  public startPolling(workflow: WorkflowModel) {
    if (!workflow || this.analyticsToPoll.length < 1) return;

    this.analyticsToPoll.forEach((analytic) => {
      if (analytic.areAllPodsFinished) return;

      if (this.pollingIntervals.has(analytic.id)) return;

      const interval = setInterval(() => {
        if (!workflow?.published_id) return;

        void this.fetchAndParseAnalytics(analytic.id, workflow.published_id)
          .then((updatedAnalytics) => {
            if (!updatedAnalytics) return;
            if (analytic.areAllPodsFinished) {
              clearInterval(interval);
              return;
            }
          })
          .catch((error) => newError('ETL-OlKv', error));
      }, 5000);

      this.pollingIntervals.set(analytic.id, interval);
    });
  }

  public startSinglePolling(
    analytics: AnalyticsModel,
    workflow: WorkflowModel
  ) {
    if (!analytics || !analytics.shouldBePolled || !workflow?.published_id)
      return;

    if (this.pollingIntervals.has(analytics.id)) return;

    const interval = setInterval(() => {
      if (!workflow?.published_id) return;

      void this.fetchAndParseAnalytics(analytics.id, workflow.published_id)
        .then((updatedAnalytics) => {
          if (!updatedAnalytics || analytics.areAllPodsFinished) {
            this.stopPolling(analytics.id);
          }
        })
        .catch((error) => newError('ETL-OlKv', error));
    }, 5000);

    this.pollingIntervals.set(analytics.id, interval);
  }
  public stopPolling(analyticId: AnalyticsModel['id']) {
    if (this.pollingIntervals.has(analyticId)) {
      clearInterval(this.pollingIntervals.get(analyticId));
      this.pollingIntervals.delete(analyticId);
    }
  }

  public stopAllPolling() {
    this.pollingIntervals.forEach((interval) => clearInterval(interval));
    this.pollingIntervals.clear();
  }

  /* --------------------------------- Pagination -------------------------------- */

  public isAnalyticsPageFetching(workflow_id: string, page: number): boolean {
    return this.fetchingAnalyticsPages.get(workflow_id)?.has(page) ?? false;
  }
  public setAnalyticsPageFetching(
    workflow_id: string,
    page: number,
    isFetching: boolean
  ): void {
    if (!this.fetchingAnalyticsPages.has(workflow_id)) {
      this.fetchingAnalyticsPages.set(workflow_id, new Set());
    }
    const pages = this.fetchingAnalyticsPages.get(workflow_id);
    if (!pages) return;

    isFetching ? pages.add(page) : pages.delete(page);
  }

  setPodPage(analyticsId: string, page: number, pods: Pod[]): void {
    if (!this.paginatedPods.has(analyticsId)) {
      this.paginatedPods.set(analyticsId, new Map());
    }
    const pages = this.paginatedPods.get(analyticsId);
    pages?.set(page, pods);
  }

  getPodPageItems(analyticsId: string, page: number): Pod[] {
    const pages = this.paginatedPods.get(analyticsId);
    if (!pages) {
      return [];
    }
    return pages.get(page) || [];
  }
}
