import { useState } from 'react';

import { ChevronsUpDown, Rocket, Share2 } from 'lucide-react';
import { observer } from 'mobx-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StudioUser } from 'shared';
import { toast } from 'sonner';
import styled from 'styled-components';

import GenerateDialog from '@components/generateModal';
import ShareDialog from '@components/share';

import useStores from '@hooks/useStore';

import { ProcessModel } from '@models/process.model';

import ActionButton from '@atoms/button';

import { ParamsList } from '@/routes/routes.types';
import theme from '@/styles/theme';
import { TRACE_UI_URL } from '@/utils/constants';
import { Button, Dropdown, Menu, MenuButton } from '@mui/joy';

import { CustomMenuItem, ProcessList } from '../../header.comp';

interface HeaderNavRightProps {
  currentProcessId: string;
  can_edit?: boolean;
  hasBeenGenerated: boolean | undefined;
  workflowList: ProcessModel['workflows'];
  user: StudioUser;
}

const NavbarEditorMenu = styled(Menu)`
  z-index: 100001 !important;
  max-width: 400px;
  .MuiInput-root {
    background-color: transparent;
  }

  .Mui-focusVisible {
    outline: none !important;
  }

  .Mui-focused::before {
    box-shadow: none;
  }
`;

const HeaderNavRight = ({
  currentProcessId,
  can_edit,
  hasBeenGenerated,
  workflowList,
  user
}: HeaderNavRightProps) => {
  const { processStore } = useStores();
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [openModalShare, setOpenModalShare] = useState(false);
  const [openModalGenerate, setOpenModalGenerate] = useState(false);
  const navigate = useNavigate();
  const processId = useParams()[ParamsList.ProcessId] as string;

  const generateProcess = () => {
    const prom = processStore.generateProcess(currentProcessId ?? '');

    toast.promise(prom, {
      loading: 'Triggering process generation...',
      success: (loadedGeneration) => {
        return (
          <span>
            Process generation successfully queued !
            <br />
            <a
              onClick={() =>
                loadedGeneration
                  ? navigate(`/${processId}/generation/${loadedGeneration.id}`)
                  : navigate(`/${processId}/generation`)
              }
              className="cursor-pointer underline"
            >
              {loadedGeneration
                ? 'Go to ongoing generation'
                : 'Go to generations'}
            </a>
          </span>
        );
      },
      error: (error) => {
        if (error instanceof Error) {
          return 'An error occured while generating your process: '.concat(
            error.message
          );
        }
        return 'An error occured while generating your process: '.concat(
          JSON.stringify(error, Object.getOwnPropertyNames(error))
        );
      }
    });
  };

  return (
    <>
      <>
        {can_edit && (
          <ActionButton
            value="Generate"
            startDecorator={<Rocket size={18} />}
            size="sm"
            onClick={() => {
              setOpenModalGenerate(!openModalGenerate);
            }}
          />
        )}

        {hasBeenGenerated &&
          (workflowList.length === 1 ? (
            <Link
              to={`${TRACE_UI_URL}/workflowoverview/${workflowList[0].published_id}`}
              target="_blank"
            >
              <Button variant="outlined">Trace</Button>{' '}
            </Link>
          ) : (
            <Dropdown onOpenChange={() => setIsSelectorOpen(!isSelectorOpen)}>
              <MenuButton
                size="sm"
                endDecorator={
                  <ChevronsUpDown color={theme.color.grey[6]} size={14} />
                }
                variant="outlined"
                sx={{ gap: '10px' }}
              >
                Trace
              </MenuButton>

              <NavbarEditorMenu>
                <ProcessList>
                  {workflowList.map((wf) => (
                    <CustomMenuItem key={wf.id}>
                      <Link
                        to={`${TRACE_UI_URL}/workflowoverview/${wf.published_id}`}
                        target="_blank"
                      >
                        Workflow {wf.published_id}
                      </Link>
                    </CustomMenuItem>
                  ))}
                </ProcessList>
              </NavbarEditorMenu>
            </Dropdown>
          ))}
      </>
      <Button
        color="primary"
        variant="plain"
        size="sm"
        onClick={() => {
          setOpenModalShare(!openModalShare);
        }}
        startDecorator={<Share2 size={18} />}
      />

      {currentProcessId && (
        <>
          <ShareDialog
            key="share"
            openModalShare={openModalShare}
            setOpenModalShare={setOpenModalShare}
            user={user}
            processId={currentProcessId}
          />
          <GenerateDialog
            key="generate"
            openModalGenerate={openModalGenerate}
            setOpenModalGenerate={setOpenModalGenerate}
            onGenerate={generateProcess}
            workflowList={workflowList}
          />
        </>
      )}
    </>
  );
};

export default observer(HeaderNavRight);
