import React from 'react';

import { GitCommitHorizontal } from 'lucide-react';
import { Link, LinkProps } from 'react-router-dom';
import { GenerationStatus } from 'shared/src/generation/generation.schema';

import { Avatar, AvatarImage } from '@components/ui/avatar';
import { Badge } from '@components/ui/badge';

import { generationStatusToProgress } from '@pages/Settings/generation';

import { cn } from '@/lib/utils';
import { DEFAULT_AVATAR, ENV, ENV_TO_READABLE_NAME } from '@/utils/constants';

type Props = {
  to: LinkProps['to'];
  isCurrent: boolean;
  generationId: string;
  status: GenerationStatus;
  duration: string;
  elapsedTime: string;
  sourceTag: string;
  avatar: Nullish<string>;
  name: string;
  className?: string;
};

export const Generation = (props: Props) => {
  return (
    <Link
      className={cn(
        'h-20 cursor-pointer flex flex-row items-center p-4 text-sm font-normal bg-white text-zinc-500 hover:bg-gray-50 border-b border-solid border-border last:border-b-0',
        props.className
      )}
      to={props.to}
    >
      <div className="flex-1">
        <div className="font-medium text-zinc-950">{props.generationId}</div>
        <div className="flex flex-row items-center">
          {ENV_TO_READABLE_NAME[ENV]}
          {props.isCurrent && (
            <Badge className="ml-2 font-normal" variant="pastelBlue">
              Current
            </Badge>
          )}
        </div>
      </div>

      <div className="basis-36 grow-0 shrink-0">
        <div className="flex items-center">
          {generationStatusToProgress(props.status)}
          <span className="ml-2.5 font-medium">{props.status}</span>
        </div>
        <div className="pl-6">
          {props.duration}({props.elapsedTime})
        </div>
      </div>

      <div className="flex-1">
        <div className="flex items-center">
          <GitCommitHorizontal size={18} />
          <span className="ml-2 text-zinc-950">{props.sourceTag}</span>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <span className="truncate max-w-56">
          {props.elapsedTime} by {props.name}
        </span>
        <Avatar className="size-6">
          <AvatarImage src={props.avatar ?? DEFAULT_AVATAR} />
        </Avatar>
      </div>
    </Link>
  );
};

export const GenerationContainer = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <section
      className={cn(
        'w-full shadow-verail transition-shadow duration-300 rounded-md overflow-hidden',
        className
      )}
      {...props}
    >
      {children}
    </section>
  );
};
