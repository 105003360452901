import { Permission } from 'shared/src/other/process.schema';

import { DeleteRoleDraft, UserRoles } from '@/types/process.types';

export const renderPermissions = (
  role: UserRoles | undefined | null
): { label: string; value: UserRoles | DeleteRoleDraft }[] => {
  switch (role) {
    case UserRoles.Owner:
      return [
        { label: 'can edit', value: UserRoles.Editor },
        { label: 'can view', value: UserRoles.Viewer }
      ];
    case UserRoles.Editor:
      return [
        { label: 'can edit', value: UserRoles.Editor },
        { label: 'can view', value: UserRoles.Viewer }
      ];
    case UserRoles.Viewer:
      return [];
    default:
      return [];
  }
};

export const MenuRoleChoice = [
  { value: UserRoles.Editor, label: 'edit' },
  { value: UserRoles.Viewer, label: 'view' }
];

export const PublicAccessChoice = [
  { value: true, label: 'Anyone with the link can view the process' },
  { value: false, label: 'Only people invited can view the process' }
];

export const roleToPermissions = (role: UserRoles): Permission => {
  switch (role) {
    case UserRoles.Owner:
      return {
        can_edit: true,
        can_view: true,
        can_delete: true,
        can_leave: true
      };
    case UserRoles.Editor:
      return {
        can_edit: true,
        can_view: true,
        can_delete: false,
        can_leave: true
      };
    case UserRoles.Viewer:
      return {
        can_edit: false,
        can_view: true,
        can_delete: false,
        can_leave: true
      };
    default:
      return {
        can_edit: false,
        can_view: false,
        can_delete: false,
        can_leave: false
      };
  }
};

export function generateStrongPassword(length: number) {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]<>?';
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
}

export const permissionsToUserRole = (permissions: Permission): UserRoles => {
  if (permissions.can_delete) return UserRoles.Owner;
  if (permissions.can_edit) return UserRoles.Editor;
  if (permissions.can_view) return UserRoles.Viewer;
  return UserRoles.Viewer;
};
