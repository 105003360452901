import { motion } from 'framer-motion';

import { Skeleton } from '@components/ui/skeleton';

import { cn } from '@/lib/utils';

type Props = {
  index: number;
  className?: string;
};
export const GenerationSkeleton = ({ index, className }: Props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className={cn(
        'h-20 flex flex-row items-center p-4 gap-4 bg-white border-b border-solid border-border last:border-b-0',
        className
      )}
    >
      <div className="flex-1 space-y-2">
        <Skeleton className="h-4 w-16" />
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-24" />
          {index === 0 && <Skeleton className="h-5 w-16 rounded-lg" />}
        </div>
      </div>
      <div className="basis-36 grow-0 shrink-0 space-y-2">
        <div className="flex items-center gap-2">
          <Skeleton className="size-4" />
          <Skeleton className="h-4 w-16" />
        </div>
        <Skeleton className="h-4 w-24 ml-6" />
      </div>
      <div className="flex-1">
        <div className="flex items-center gap-2">
          <Skeleton className="size-4" />
          <Skeleton className="h-4 w-36" />
        </div>
      </div>
      <div className="justify-self-end">
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-40" />
          <Skeleton className="size-5 rounded-full" />
        </div>
      </div>
    </motion.div>
  );
};
