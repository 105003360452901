import React from 'react';

import { observer } from 'mobx-react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@components/ui/accordion';
import { Input } from '@components/ui/input';
import InputWithWarning from '@components/ui/inputWithWarning';
import { Label } from '@components/ui/label';

import { AnalyticsModel } from '@models/analytics.model';

import { ColumnElement } from '@atoms/columnElement';
import { BasicModal } from '@atoms/modal';

import { Button, DialogActions, Divider } from '@mui/joy';

import { validateFiltersInput, validateSnakeCase } from '../utils';

interface EditEtlModalProps {
  closeModal: () => void;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  analytics: AnalyticsModel;
  handleInputChange: (field: string, value: string) => Promise<void>;
}
const EditEtlModal = ({
  openModal,
  setOpenModal,
  closeModal,
  analytics,
  handleInputChange
}: EditEtlModalProps) => {
  return (
    <BasicModal
      open={openModal}
      setOpen={setOpenModal}
      title={`Edit the analytics ETL`}
      width="600px"
    >
      <Divider />
      <div className="space-y-4 mt-4">
        <div className="grid grid-cols-2 gap-4">
          <ColumnElement>
            <Label htmlFor="description">Description</Label>
            <Input
              id="description"
              type="text"
              placeholder="description"
              value={analytics.description ?? ''}
              onChange={(e) => {
                void handleInputChange('description', e.target.value);
              }}
            />
          </ColumnElement>

          <ColumnElement>
            <Label htmlFor="starts_at">Starts At</Label>
            <Input
              id="starts_at"
              type="date"
              className="block"
              placeholder="Starts at"
              value={
                analytics.starts_at
                  ? new Date(analytics.starts_at ?? '')
                      .toISOString()
                      .split('T')[0]
                  : ''
              }
              onChange={(e) => {
                void handleInputChange(
                  'starts_at',
                  new Date(e.target.value).toISOString()
                );
              }}
            />
          </ColumnElement>
          <ColumnElement>
            <Label htmlFor="ends_at">Ends At</Label>
            <Input
              id="ends_at"
              type="date"
              className="block"
              placeholder="Ends at"
              value={
                analytics.ends_at
                  ? new Date(analytics.ends_at ?? '')
                      ?.toISOString()
                      .split('T')[0]
                  : ''
              }
              onChange={(e) => {
                void handleInputChange(
                  'ends_at',
                  new Date(e.target.value).toISOString()
                );
              }}
            />
          </ColumnElement>
          <ColumnElement>
            <Label htmlFor="image">Docker Image</Label>
            <Input
              id="image"
              type="text"
              placeholder="Docker image"
              value={analytics.image ?? ''}
              onChange={(e) => {
                void handleInputChange('image', e.target.value);
              }}
            />
          </ColumnElement>
        </div>

        <Accordion type="single" collapsible>
          <AccordionItem value="etl">
            <AccordionTrigger>ETL details</AccordionTrigger>
            <AccordionContent>
              <div className="grid grid-cols-2 gap-4 p-0.5">
                <ColumnElement>
                  <Label htmlFor="schema">Schema</Label>
                  <InputWithWarning
                    id="schema"
                    type="text"
                    placeholder="Schema"
                    value={analytics.data.schema}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      validateSnakeCase(e.target.value);
                      void handleInputChange('data.schema', e.target.value);
                    }}
                    warningMessage="Caution: Changes here may affect data storage settings, possibly resulting in data loss for existing dashboards."
                  />
                </ColumnElement>
                <ColumnElement>
                  <Label htmlFor="rs_database">Database</Label>
                  <InputWithWarning
                    type="text"
                    id="rs_database"
                    placeholder="Database in Analytics"
                    value={analytics.data.rs_database}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      validateSnakeCase(e.target.value);
                      void handleInputChange(
                        'data.rs_database',
                        e.target.value
                      );
                    }}
                    warningMessage="Caution: Changes here may affect data storage settings, possibly resulting in data loss for existing dashboards."
                  />
                </ColumnElement>
                <ColumnElement>
                  <Label htmlFor="filters">Filters</Label>
                  <Input
                    type="text"
                    id="filters"
                    placeholder="Remove tables from ETL"
                    value={analytics.data.filters}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      validateFiltersInput(e.target.value);
                      void handleInputChange('data.filters', e.target.value);
                    }}
                  />
                </ColumnElement>
                <ColumnElement>
                  <Label htmlFor="column_filters">Column Filters</Label>
                  <Input
                    id="column_filters"
                    type="text"
                    placeholder="Remove columns from table"
                    value={analytics.data.column_filter}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      validateFiltersInput(e.target.value);
                      void handleInputChange(
                        'data.column_filter',
                        e.target.value
                      );
                    }}
                  />
                </ColumnElement>
                <ColumnElement>
                  <Label htmlFor="sentry_dsn">Sentry DSN</Label>
                  <Input
                    id="sentry_dsn"
                    type="text"
                    placeholder="Enter your Sentry DSN..."
                    value={analytics.sentry_dsn ?? ''}
                    onChange={(e) => {
                      void handleInputChange('sentry_dsn', e.target.value);
                    }}
                  />
                </ColumnElement>
                <ColumnElement>
                  <Label htmlFor="sentry_monitor">
                    Sentry Cronjob Monitor Slug
                  </Label>
                  <Input
                    id="sentry_monitor"
                    type="text"
                    placeholder="Enter your Sentry Cronjob Monitor..."
                    value={analytics.sentry_monitor ?? ''}
                    onChange={(e) => {
                      void handleInputChange('sentry_monitor', e.target.value);
                    }}
                  />
                </ColumnElement>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
      </DialogActions>
    </BasicModal>
  );
};

export default observer(EditEtlModal);
