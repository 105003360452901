import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from '@components/ui/carousel';
import { SettingsContainer, SettingsContent } from '@components/ui/settings';

import useProcess from '@hooks/useProcess';

import { ProcessModel } from '@models/process.model';
import { WorkflowModel } from '@models/workflow.model';

import { ParamsList } from '@/routes/routes.types';

import { LastGenerations } from './lastgenerations';
import { WorkflowCard, WorkflowCardSkeleton } from './workflowCard';

const ProcessOverview = observer(() => {
  const process = useProcess();
  const processId = useParams()[ParamsList.ProcessId];

  const renderWorkflows = () => {
    if (!process || process.isSmallProcess || !process.workflows) {
      const workflowIds = ProcessModel.getLocalWorkflowIds(processId);

      if (!workflowIds) {
        return (
          <CarouselItem className="md:basis-1/2 lg:basis-1/3">
            <WorkflowCardSkeleton index={0} />
          </CarouselItem>
        );
      }

      return workflowIds.map((workflowId, index) => (
        <CarouselItem className="md:basis-1/2 lg:basis-1/3">
          <WorkflowCardSkeleton key={workflowId} index={index} />
        </CarouselItem>
      ));
    }

    return process.workflows.map((workflow: WorkflowModel) => (
      <CarouselItem key={workflow.id} className="md:basis-1/2 lg:basis-1/3">
        <WorkflowCard key={workflow.id} process={process} workflow={workflow} />
      </CarouselItem>
    ));
  };

  return (
    <SettingsContainer>
      <SettingsContent className="gap-14">
        <section>
          <h2 className="text-[1.2rem] font-semibold mb-4">Workflows</h2>
          <Carousel className="-mx-3 -my-4">
            <CarouselContent className="h-64 px-3 py-4">
              {renderWorkflows()}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </section>
        <section>
          <h2 className="text-[1.2rem] font-semibold mb-4">
            Latest generations
          </h2>
          <LastGenerations process={process} />
        </section>
      </SettingsContent>
    </SettingsContainer>
  );
});

export default ProcessOverview;
