import { Editable } from 'slate-react';
import styled from 'styled-components';

import { IconButton, Sheet } from '@mui/joy';

export const EditBody = styled(Sheet)`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
  padding: 10px;
  border-radius: 2px;
`;

export const RichTextEditor = styled(Editable)`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.color.grey[5]};
  border-radius: 5px;
  min-height: 400px !important;

  &:focus-visible {
    outline: #635cf8 auto 1px;
  }

  h1 {
    display: block;
    font-size: 2em;
    font-weight: bold;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
  }
  h4 {
    display: block;
    font-weight: bold;
  }
  h5 {
    display: block;
    font-size: 0.83em;
    font-weight: bold;
  }
  h6 {
    display: block;
    font-size: 0.67em;
    font-weight: bold;
  }

  blockquote {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 40px;
    margin-right: 40px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  span[data-slate-placeholder='true'] {
    top: 10px !important;
  }
`;

type ButtonProps = {
  $active: boolean;
  $reversed: boolean;
};

export const RichTextButton = styled(IconButton)<ButtonProps>`
  color: ${(props) =>
    props.$reversed
      ? props.$active
        ? 'black'
        : '#aaa'
      : props.$active
        ? 'black'
        : '#ccc'} !important;

  #linear-icon {
    fill: ${(props) =>
      props.$reversed
        ? props.$active
          ? 'black'
          : '#aaa'
        : props.$active
          ? 'black'
          : '#ccc'} !important;
  }
`;
type ChipProps = {
  color: string;
};

export const CustomChip = styled.span<ChipProps>`
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  background-color: ${({ color }) => color};
`;
