import React, { useState } from 'react';

import cronstrue from 'cronstrue';
import { Check, ChevronDown } from 'lucide-react';

import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList
} from '@components/ui/command';
import { Input } from '@components/ui/input';

import { cn } from '@/lib/utils';
import { newError } from '@/services/errors/errors';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@radix-ui/react-popover';
import { Separator } from '@radix-ui/react-separator';

import { CronList } from './utils';

interface Props extends React.InputHTMLAttributes<HTMLElement> {
  value: string;
  onCronChange: (cron: string) => void;
  id?: string;
  isPlain?: boolean;
  fullWidth?: boolean;
}
export const CronSelector = React.forwardRef<HTMLInputElement, Props>(
  (
    { value, onCronChange, id, isPlain = false, fullWidth = true, ...props },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [cronString, setCronString] = useState(value);
    const [humanReadable, setHumanReadable] = useState(
      cronstrue.toString(value)
    );

    const handleCronChange = (newValue: string) => {
      setCronString(newValue);

      try {
        const translation = cronstrue.toString(newValue);
        setHumanReadable(translation);
        onCronChange(newValue);
      } catch (error) {
        newError('ETL-eh8RE', error);
        setHumanReadable(`Invalid cron expression : ${JSON.stringify(error)}`);
      }
    };

    const onCustomCronChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const isValidCron = newValue.split(' ').filter(Boolean).length === 5;

      if (!isValidCron) {
        setHumanReadable('Invalid cron expression, needs to be 5 digits');
        setCronString(newValue);
      } else {
        handleCronChange(newValue);
      }
    };

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild onClick={props.onClick}>
          <div
            className={cn(
              `grid items-center gap-1.5 select-none cursor-pointer hover:bg-accent hover:text-accent-foreground hover:rounded-md`,
              fullWidth ? 'w-full' : 'w-fit'
            )}
            ref={ref}
          >
            <div
              className={`flex h-8 rounded-md hover:bg border-input bg-background px-2 py-2 text-sm ring-offset-background justify-between items-center font-normal hover:bg-accent hover:text-accent-foreground ${!isPlain && 'border border-solid'}`}
            >
              {humanReadable}
              {!isPlain && (
                <ChevronDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
              )}
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-[400px] p-0 z-50 h-auto">
          <Command loop>
            <CommandList className="max-h-fit">
              <CommandGroup heading="Predefined crons">
                {CronList.map((cronItem) => {
                  const { label, cron } = cronItem;
                  return (
                    <CommandItem
                      key={cron}
                      value={cron}
                      onSelect={(currentValue) => {
                        handleCronChange(currentValue);
                        setOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          'mr-2 h-4 w-4',
                          value === cron ? 'opacity-100' : 'opacity-0'
                        )}
                      />
                      <div className="flex items-center gap-3">
                        {label}
                        <div className="px-2 text-xs bg-gray-100 border border-gray-300 border-solid rounded-sm">
                          {cron}
                        </div>
                      </div>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              <Separator />
              <CommandGroup heading="Custom cron">
                <CommandItem className="pointer-events-none">
                  <div className="grid w-full max-w-sm items-center gap-1.5">
                    <Input
                      type="text"
                      className="w-full pointer-events-auto"
                      placeholder="Ex: 10 * * * *"
                      autoFocus
                      id={id ? `custom-cron-${id}` : 'custom-cron'}
                      value={cronString}
                      onChange={onCustomCronChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setOpen(false);
                        }
                      }}
                    />
                    <div>
                      <p className="mt-1 text-xs text-gray-600">
                        {humanReadable}
                      </p>
                    </div>
                  </div>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);

CronSelector.displayName = 'CronSelector';
