import type { CustomHeaderProps } from 'ag-grid-react';
import { KeyRound, RefreshCcw } from 'lucide-react';
import { ColumnContext } from 'shared/src/database/database.schema';
import { toast } from 'sonner';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@components/ui/tooltip';

export interface GlobalInnerHeaderProps extends CustomHeaderProps {
  columnContext: ColumnContext;
  onSyncClick?: () => Promise<void>;
}

export const GlobalInnerHeaderComponent = (props: GlobalInnerHeaderProps) => {
  const handleSyncClick = () => {
    if (!props.onSyncClick) return;
    const onSyncPromise = props.onSyncClick();
    toast.promise(onSyncPromise, {
      loading: 'Synchronizing your data...',
      success: 'Data synchronized successfully',
      error: 'An error occurred while synchronizing your data'
    });
  };

  return (
    <div className="flex items-center gap-3">
      <span>{props.displayName}</span>
      {props.columnContext?.isPrimaryKey && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <KeyRound size={16} color="#635CF8" />
            </TooltipTrigger>
            <TooltipContent>
              <p>Primary key</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
      {props.onSyncClick && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <RefreshCcw
                size={16}
                color="#635CF8"
                onClick={() => handleSyncClick()}
                className="cursor-pointer"
              />
            </TooltipTrigger>
            <TooltipContent>
              <p>Synchronize data</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
};
