import { FormEvent, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import useStores from '@hooks/useStore';

import { CreateProcessDto } from '@stores/process.store';

import { DefaultPage } from '@atoms/defaultPage/style';
import { InputField } from '@atoms/input';

import { newError } from '@/services/errors/errors';
import { ROUTE_PROCESSES } from '@/utils/constants';
import { Typography } from '@mui/joy';

import FileUploadForm from './fileUpload';
import NewProcessForm from './form';
import {
  CancelButton,
  Content,
  Footer,
  FormContainer,
  ProcessBlock,
  SubmitButton
} from './newProcess.style';
import {
  FormItem,
  ProjectTypeList,
  defaultProjectType,
  defaultTemplate,
  isTextInString,
  projectTypes,
  templates
} from './newProcess.utils';

const AddNewProcess = () => {
  const { processStore } = useStores();
  const [processName, setProcessName] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] =
    useState<FormItem>(defaultTemplate);
  const [selectedProjectType, setSelectedProjectType] =
    useState<FormItem>(defaultProjectType);
  const navigate = useNavigate();
  const [bpmnFileContent, setBpmnFileContent] = useState<Maybe<string>>();

  const isDraftProcessValid = () => {
    return selectedProjectType.value === ProjectTypeList.Draft;
  };

  const isStratumnProcessValid = () => {
    return (
      selectedProjectType.value === ProjectTypeList.Stratumn && selectedTemplate
    );
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!processName || !isTextInString(processName)) return;

    if (!isDraftProcessValid() && !isStratumnProcessValid()) return;

    const newProcess: CreateProcessDto = {
      name: processName,
      draft: selectedProjectType.value === ProjectTypeList.Draft,
      template: selectedTemplate.value,
      bpmnWorkflow: bpmnFileContent
    };

    processStore
      .createNew(newProcess)
      .then(() => {
        toast.success('New process created');
      })
      .catch((error) => {
        newError('PR-1000', error, true, {
          customMessage: 'Error while creating the new process'
        });
      })
      .finally(() => {
        navigate(ROUTE_PROCESSES);
      });
  };

  const onCancel = () => {
    navigate(ROUTE_PROCESSES);
  };

  const handleFileChange = (uploadedFile: File) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const fileData = event.target?.result;
      if (typeof fileData !== 'string') return;
      setBpmnFileContent(fileData);
    };
    reader.readAsText(uploadedFile);
  };

  return (
    <DefaultPage>
      <Typography level="h3" paddingBottom={'0.5rem'} paddingTop={'2rem'}>
        Add a new Project
      </Typography>
      <Typography level="body-md">
        Quickly roll out your business process.
      </Typography>
      <br />
      <FormContainer onSubmit={onFormSubmit}>
        <ProcessBlock>
          <Content>
            <Typography level="body-sm" paddingBottom={'0.3rem'}>
              Name of your project
            </Typography>
            <InputField
              color="neutral"
              placeholder="My incredible Stratumn process"
              onChange={(e) => setProcessName(e.target.value)}
              autoFocus
            />
          </Content>
        </ProcessBlock>

        <ProcessBlock>
          <NewProcessForm
            selectedItem={selectedProjectType}
            onItemSelected={setSelectedProjectType}
            items={projectTypes}
            defaultItem={defaultProjectType}
          ></NewProcessForm>
        </ProcessBlock>

        {selectedProjectType.value === ProjectTypeList.Stratumn && (
          <ProcessBlock>
            <NewProcessForm
              selectedItem={selectedTemplate}
              onItemSelected={setSelectedTemplate}
              items={templates}
              defaultItem={defaultTemplate}
              searchPlaceholder="Search templates"
              dividerTitle="Templates"
            ></NewProcessForm>
          </ProcessBlock>
        )}

        {selectedProjectType.value === ProjectTypeList.Draft && (
          <>
            <ProcessBlock>
              <FileUploadForm
                uploadTitle="Import BPMN diagram"
                uploadHelper="accepts only .bpmn files"
                onFileChange={handleFileChange}
                accept=".bpmn"
              />
            </ProcessBlock>
          </>
        )}

        <Footer>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
          <SubmitButton type="submit">Create</SubmitButton>
        </Footer>
      </FormContainer>
    </DefaultPage>
  );
};

export default AddNewProcess;
