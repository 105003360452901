import { observer } from 'mobx-react';

import DNDBase from '@components/dnd/base';
import {
  SettingHeader,
  SettingsContainer,
  SettingsContent
} from '@components/ui/settings';

import useProcess from '@hooks/useProcess';

import { Variable } from '@stores/variables.store';

import ActionButton from '@atoms/button';

import { LoaderBox } from '@/components/ui/loader';
import { CircularProgress } from '@mui/joy';

const TraceInfo = () => {
  const process = useProcess();

  const traceInfoDnd = process?.workflows[0]?.traceInfoDnd;

  if (!process || process.isSmallProcess) {
    return (
      <LoaderBox>
        <CircularProgress />
      </LoaderBox>
    );
  }

  const onAddWindowClick = () => {
    if (!traceInfoDnd) return;
    const window = traceInfoDnd.createDndBlock('TI_Window', 'unrelevant');

    const dropZonePath = 'root:root|0';
    const stateWithNewWindow = traceInfoDnd.insertBlockInState(
      dropZonePath,
      traceInfoDnd.state,
      window
    );

    traceInfoDnd.setState(stateWithNewWindow);
  };

  const isDropInRoot = (path: string, item: Variable) => {
    return (
      (path.includes('root:root|') && item.type === 'Window') ||
      !path.includes('root:root|')
    );
  };

  return (
    <SettingsContainer>
      <SettingHeader
        title="Trace Info"
        description="Customize the panels available in each trace inspector"
      />
      {traceInfoDnd && (
        <SettingsContent>
          <div className="title-body">
            <h3>Trace Infos settings</h3>
            <ActionButton value="Add a window" onClick={onAddWindowClick} />
          </div>

          <DNDBase dnd={traceInfoDnd} restriction={isDropInRoot} />
        </SettingsContent>
      )}
    </SettingsContainer>
  );
};

export default observer(TraceInfo);
