import { useState } from 'react';

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { StudioUser } from 'shared';

import ShareDialog from '@components/share';

import { ParamsList } from '@/routes/routes.types';

import { MainHeader } from './base/mainHeader/main-header.comp';
import { HeaderRoot } from './header.comp';

interface HeaderProps {
  user: StudioUser;
}

const Header = ({ user }: HeaderProps) => {
  const processId = useParams()[ParamsList.ProcessId] as string;
  const [openModalShare, setOpenModalShare] = useState(false);

  return (
    <HeaderRoot>
      <MainHeader user={user} />
      {processId && (
        <ShareDialog
          key="modal"
          openModalShare={openModalShare}
          setOpenModalShare={setOpenModalShare}
          user={user}
          processId={processId}
        />
      )}
    </HeaderRoot>
  );
};

export default observer(Header);
