import { ChangeEvent } from 'react';

import { List, LucideIcon } from 'lucide-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { DNDBlock, TO_LIST_VIEW_TYPES } from 'shared';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { useAtom } from '@hooks/useAtom';

import { VariableSelector } from '@atoms/dataSelectors/variableSelector/variableSelector';
import { InputField } from '@atoms/input';

import { ParamsList } from '@/routes/routes.types';
import EndControlsFB from '@library/formBuilder/endControls';
import { FormControl, FormLabel, Radio, RadioGroup } from '@mui/joy';

import { initialTOListData } from './list.data';

export const ListIcon: LucideIcon = List;

const ListBlock = (block: DNDBlock) => {
  const workflowId = useParams()[ParamsList.WorkflowId] as string;

  const atom = useAtom({
    type: 'TO_List',
    sourceId: block.id,
    parentId: workflowId,
    parentKind: 'traceOverview',
    initialData: {
      ...initialTOListData,
      /* eslint-disable */
      selectedVariable: block.other.selectedVariableRef!
    }
  });

  if (!atom) return null;

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    atom.data.title = event.target.value;
  };

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={ListIcon}
      endControls={
        <EndControlsFB hasRequired={false} dndBlock={block} dataItem={atom} />
      }
    >
      <Column>
        <InputField
          label="Widget title"
          value={atom.data.title}
          onChange={onTitleChange}
          placeholder="Aa"
        />
      </Column>
      <Column $width="200px">
        <FormControl size="sm">
          <FormLabel> State variable</FormLabel>
          <VariableSelector
            editable={false}
            selectedRef={atom.data.selectedVariable}
            disabled
          />
        </FormControl>
      </Column>
      <Column>
        <FormControl size="sm">
          <FormLabel>Display type</FormLabel>
          <RadioGroup value={atom.data.viewType} name="radio-buttons-group">
            {TO_LIST_VIEW_TYPES.map((viewType) => (
              <Radio
                key={viewType}
                value={viewType}
                onChange={(e) =>
                  (atom.data.viewType = e.target.value as typeof viewType)
                }
                label={viewType}
                color="neutral"
                size="sm"
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Column>
    </BlockBase>
  );
};

export default observer(ListBlock);
