import { ChevronsUpDown } from 'lucide-react';
import { observer } from 'mobx-react';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@components/ui/popover';
import { Separator } from '@components/ui/separator';

import theme from '@/styles/theme';

type Item = { id: string; name?: string };
interface HeaderDropdownProps {
  items: Item[];
  currentItemId: string;
  onSelect: (id: string) => void;
  type: 'process' | 'workflow';
}

export const HeaderDropdown = observer(
  ({ items, currentItemId, onSelect, type }: HeaderDropdownProps) => {
    return (
      <Popover>
        <PopoverTrigger>
          <div className="w-full hover:bg-gray-100 m-1 rounded-sm pl-2 py-1 select-none cursor-pointer">
            <ChevronsUpDown color={theme.color.grey[6]} size={14} />
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0 z-50">
          <Command loop>
            <CommandInput
              placeholder={`${type === 'process' ? 'Process' : 'Workflow'} name`}
            />
            <Separator />
            <CommandList>
              <CommandEmpty>
                {' '}
                No {type === 'process' ? 'processes' : 'workflows'} found.
              </CommandEmpty>
              <CommandGroup
                heading={type === 'process' ? 'Processes' : 'Workflows'}
              >
                {items.map((item) => (
                  <CommandItem
                    key={item.id}
                    defaultChecked={currentItemId == item.id}
                    onSelect={() => onSelect(item.id)}
                  >
                    {item.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
