import { AtomReference, RowData } from 'shared';
import { RowReference } from 'shared/src/atom/atomReference.schema';

import { newError } from '@/services/errors/errors';

import useStores from './useStore';

/**
 * Returns row references from base atom references.
 */
export const useRowReferences = (atomRefs: AtomReference[]): RowReference[] => {
  const { atomStore, databaseStore } = useStores();

  const rowRefs: RowReference[] = [];

  for (const atomRef of atomRefs) {
    const matchedRowAtom = atomStore.getAtomById<RowData>(
      atomRef.dataItemId,
      'Row'
    );

    if (!matchedRowAtom || matchedRowAtom instanceof Error) {
      newError(
        'UHRR-9AHAI',
        `Row atom ${atomRef.dataItemId} 
            not found in store while building row references`
      );
      throw new Error(
        `Row atom ${atomRef.dataItemId} 
            not found in store while building row references`
      );
    }

    const matchedDatabaseOfRowAtom = databaseStore.get(
      matchedRowAtom.metaInfo.source.parentId
    );

    if (!matchedDatabaseOfRowAtom) {
      newError(
        'UHRR-NuIuV',
        `Database ${matchedRowAtom.metaInfo.source.parentId} of row atom reference 
            ${atomRef.dataItemId} not found in store`
      );
      throw new Error(
        `Database ${matchedRowAtom.metaInfo.source.parentId} of row atom reference 
            ${atomRef.dataItemId} not found in store`
      );
    }

    const primaryKeyColumn = matchedDatabaseOfRowAtom.getColumns.find(
      (column) => column.context?.isPrimaryKey
    );

    if (!primaryKeyColumn) {
      newError(
        'UHRR-N6iX7',
        `Primary key column of DTR ${matchedDatabaseOfRowAtom.id} not found.`
      );
      throw new Error(
        `Primary key column of DTR ${matchedDatabaseOfRowAtom.id} not found.`
      );
    }

    rowRefs.push({
      dataItemId: matchedRowAtom.id,
      sourceId: matchedRowAtom.metaInfo.source.parentId,
      blockType: 'Row',
      displayedValue:
        matchedRowAtom.data[primaryKeyColumn.field]?.toString() ?? ''
    });
  }

  return rowRefs;
};
