import { ZodObject, ZodType } from 'zod';

import Model from '@models/base/base.model';

import BaseStore from '@stores/base/base.store';

import { newError } from '@/services/errors/errors';
import { ErrorCode, parseWithZod } from '@/utils/parseZodSchema';

export abstract class LocalDBModel<
  TRecord extends Record<string, unknown>,
  TLocal extends Record<string, ZodType> = {
    [K in keyof TRecord]: ZodType<TRecord[K]>;
  }
> extends Model {
  constructor(
    store: BaseStore<Model>,
    public id: string,
    private localSchema: ZodObject<TLocal>,
    isLoading?: boolean
  ) {
    super(store, id, isLoading);
  }

  private getLocalKey<K extends keyof TRecord>(key: K) {
    return `${this.constructor.name}/${this.id}/${String(key)}`;
  }

  public saveLocal<K extends keyof TRecord>(key: K, value: TRecord[K]) {
    const subSchema = this.localSchema.shape[key as keyof TLocal];
    const uniqueError: ErrorCode = `${this.getLocalKey(key)}-Write`;
    const parsed = parseWithZod<TRecord[K]>(subSchema, value, uniqueError);

    if (!parsed) return;
    localStorage.setItem(this.getLocalKey(key), JSON.stringify(value));
  }

  public getLocal<K extends keyof TRecord>(key: K) {
    const value = localStorage.getItem(this.getLocalKey(key));
    if (!value) return;

    try {
      const json = JSON.parse(value) as unknown;
      const subSchema = this.localSchema.shape[key as keyof TLocal];
      const uniqueError: ErrorCode = `${this.getLocalKey(key)}-Read`;
      return parseWithZod<TRecord[K]>(subSchema, json, uniqueError);
    } catch (error) {
      newError(`${this.getLocalKey(key)}-JSON`, error);
      return;
    }
  }

  public clearLocal<K extends keyof TRecord>(key: K) {
    localStorage.removeItem(this.getLocalKey(key));
  }

  public getAllLocal(): Partial<TRecord> {
    return (Object.keys(this.localSchema.shape) as (keyof TRecord)[]).reduce(
      (acc, key) => {
        acc[key] = this.getLocal(key);
        return acc;
      },
      {} as Partial<TRecord>
    );
  }

  // Static methods
  public static getLocalKey(name: string, id: string, key: string) {
    return `${name}/${id}/${String(key)}`;
  }

  public static getLocal<T>(
    name: string,
    id: Maybe<string>,
    key: string
  ): Maybe<T> {
    if (!id) return;
    console.log('this.getLocalKey(id, key): ', this.getLocalKey(name, id, key));

    const value = localStorage.getItem(this.getLocalKey(name, id, key));
    if (!value) return;

    try {
      return JSON.parse(value) as T;
    } catch (error) {
      newError(`${this.getLocalKey(name, id, key)}-JSON`, error);
      return;
    }
  }
}
