import { useState } from 'react';

import { Info } from 'lucide-react';
import { observer } from 'mobx-react';

import {
  BlockBody,
  Column
} from '@components/dnd/base/blockBase/body.block.style';
import { SettingsContent } from '@components/ui/settings';

import useProcess from '@hooks/useProcess';
import { useWorkflow } from '@hooks/useWorkflow';

import { WorkflowModel } from '@models/workflow.model';

import { InputField } from '@atoms/input';

import { FormHelperText } from '@mui/joy';

const WorkflowSetting = () => {
  const process = useProcess();
  const { workflow } = useWorkflow();

  const [workflowName, setWorkflowName] = useState<
    WorkflowModel['name'] | undefined
  >(workflow?.name);

  const [emptyWorkflowError, setEmptyWorkflowError] = useState(false);

  const handleBlur = () => {
    if (!workflowName) {
      return setEmptyWorkflowError(true);
    } else if (emptyWorkflowError) {
      setEmptyWorkflowError(false);
    }
    if (workflow) {
      workflow.name = workflowName;
    }
  };

  return (
    <SettingsContent>
      {process && process.workflows.length > 0 && (
        <>
          <h3 className="mb-5 pb-0">Workflows</h3>
          <BlockBody>
            {workflow && (
              <>
                <Column $width="200px">
                  <InputField
                    label="Name"
                    value={workflowName}
                    onChange={(e) => setWorkflowName(e.target.value)}
                    onBlur={() => handleBlur()}
                    placeholder="Renewal"
                    error={emptyWorkflowError}
                  />
                  {emptyWorkflowError && (
                    <FormHelperText>
                      <Info size={16} />
                      Worfklow name can't be empty
                    </FormHelperText>
                  )}
                </Column>

                {process.draft && (
                  <>
                    <Column $width="200px">
                      <InputField
                        label="ID environment variable name"
                        value={
                          workflow?.id_environment_variable_name ?? 'undefined'
                        }
                        disabled
                      />
                    </Column>
                  </>
                )}
                <Column $width="200px">
                  <InputField
                    label="Published ID"
                    disabled
                    value={workflow?.published_id ?? 'Not published yet'}
                    placeholder="Not published yet"
                  />
                </Column>
              </>
            )}
          </BlockBody>
        </>
      )}
    </SettingsContent>
  );
};

export default observer(WorkflowSetting);
