import { ValueSetterParams } from 'ag-grid-community';
import { CellValues, ColumnContextSchema, RowData } from 'shared';
import { z } from 'zod';

import { newError } from '@/services/errors/errors';

const isNewPrimaryKeyValueNotEmpty = (params: ValueSetterParams): boolean => {
  const parsedNewValue = z.string().safeParse(params.newValue);

  if (!parsedNewValue.success || parsedNewValue.data.trim() === '') {
    newError(
      'DBMD-dQp2d',
      `Primary key column '${params.colDef.field}' must not be empty`,
      true
    );
    return false;
  }

  return true;
};

const isRegexSatisfied = (params: ValueSetterParams, regex: string) => {
  const regexp = new RegExp(regex);

  const parsedNewValue = z.string().safeParse(params.newValue);

  if (!parsedNewValue.success) {
    newError(
      'DBMD-EUOAi',
      `Column '${params.colDef.field}' that contains regex must contain only string values`,
      true
    );
    return false;
  }

  const regexSatisfied = regexp.test(parsedNewValue.data);

  if (!regexSatisfied) {
    newError(
      'DBMD-I7Wml',
      `Column '${params.colDef.field}' must contain only a-z, A-Z characters`,
      true
    );
    return false;
  }

  return regexSatisfied;
};

export const globalColumnValueSetter = (params: ValueSetterParams<RowData>) => {
  const parsedContext = ColumnContextSchema.safeParse(params.colDef.context);
  const columnId = params.column.getColId();

  if (!parsedContext.success) {
    newError(
      'DBMD-wgKu8',
      `Column '${params.colDef.field}' has an invalid context`,
      true
    );
    return false;
  }

  if (!parsedContext.data) {
    params.data[columnId] = params.newValue as CellValues;
    return true;
  }

  const { isPrimaryKey, regex } = parsedContext.data;

  if (isPrimaryKey) {
    const isValid = isNewPrimaryKeyValueNotEmpty(params);

    if (!isValid) {
      return false;
    }
  }

  if (regex) {
    const isValid = isRegexSatisfied(params, regex);

    if (!isValid) {
      return false;
    }
  }

  params.data[columnId] = params.newValue as CellValues;

  return true;
};
