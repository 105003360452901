import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import { ViewType } from '@pages/Home/home.utils';

import { Avatar, Typography } from '@mui/joy';

interface ProcessCardProps {
  $isLoading: boolean;
  $view: ViewType;
  'data-process-name': string;
}

export const ProcessCardContainer = styled(Link)<ProcessCardProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(p) => p.theme.verRail.background.content};
  box-shadow: ${(p) => p.theme.verRail.boxShadow};
  transition: box-shadow 0.15s ease;
  &:hover {
    box-shadow: ${(p) => p.theme.verRail.boxShadowHover};
  }
  overflow: hidden;
  ${(p) =>
    p.$isLoading &&
    css`
      cursor: auto;
    `}

  ${(p) => {
    if (p.$view === ViewType.Grid) {
      return css`
        padding: 20px 16px 10px 25px;
        min-height: 168px;
      `;
    } else {
      return css`
        padding: 12px 16px 12px 25px;

        min-height: 0;
      `;
    }
  }}
`;

export const ProcessHeader = styled.header`
  width: 100%;

  height: 2.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
`;

export const ProcessIcon = styled(Avatar)`
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
`;

export const ProcessInfos = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  width: 60%;
`;
export const ProcessName = styled(Typography)`
  font-size: 1rem;
  color: black;
  line-height: 1;
  white-space: nowrap;
`;

export const ProcessNameEllipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  z-index: 0;
`;
export const LastEditInfo = styled(Typography)`
  font-size: 14px;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  gap: 0.8rem;
`;

// TODO factorise

const PulseGreen = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 6px rgba(51, 217, 178, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
	}
`;

const PulseYellow = keyframes`
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 6px rgba(255, 177, 66, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
	}
`;

const DraftCss = css`
  background: rgba(255, 177, 66, 1);
  box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
  animation: ${PulseYellow} 2s infinite;
`;

const PublishedCss = css`
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: ${PulseGreen} 2s infinite;
`;

export const ProcessStatus = styled.div<{ $isPublished: boolean }>`
  border-radius: 50%;
  height: 8px;
  aspect-ratio: 1 / 1;
  transform: scale(1);

  ${(p) => {
    if (p.$isPublished) {
      return PublishedCss;
    } else {
      return DraftCss;
    }
  }}
`;

export const ProcessBody = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  flex-shrink: 0;
  flex-grow: 1;
`;

interface ProcessFooterProps {
  $view: ViewType;
}
export const ProcessFooter = styled.footer<ProcessFooterProps>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-grow: 0;
  height: 1.5rem;
  ${(p) =>
    p.$view === ViewType.Grid
      ? css`
          width: 100%;
        `
      : css`
          padding-right: 1rem;
        `}
`;

export const LastEditContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  gap: 0.6rem;
`;
