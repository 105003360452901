import { ChangeEvent } from 'react';

import { Hash, LucideIcon } from 'lucide-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { DNDBlock, TO_NUMBER_VIEW_TYPES, TO_NumberIconSchema } from 'shared';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { useAtom } from '@hooks/useAtom';

import { VariableSelector } from '@atoms/dataSelectors/variableSelector/variableSelector';
import { InputField } from '@atoms/input';

import { ParamsList } from '@/routes/routes.types';
import { newError } from '@/services/errors/errors';
import { isValidType } from '@/utils/parseZodSchema';
import EndControlsFB from '@library/formBuilder/endControls';
import {
  Checkbox,
  FormControl,
  FormLabel,
  ListItemDecorator,
  Option,
  Radio,
  RadioGroup,
  Select,
  SelectOption
} from '@mui/joy';

import { LabeledVariable } from '../blocks.style';
import { initialTONumberData } from './number.data';
import { numberIconOptions } from './number.utils';

export const NumberIcon: LucideIcon = Hash;

const renderSelectedIconOption = (option: SelectOption<string> | null) => {
  if (!option) return null;

  if (!isValidType(TO_NumberIconSchema, option.value, 'TO_Icon-klqjw')) {
    throw newError('TO_Icon-klqjw', `Invalid icon option "${option.value}"`);
  }

  const icon = numberIconOptions.find(
    (iconOption) => iconOption.value === option.value
  )?.icon;

  return (
    <>
      <ListItemDecorator>{icon}</ListItemDecorator>
      {option.label}
    </>
  );
};

const Number = (block: DNDBlock) => {
  const workflowId = useParams()[ParamsList.WorkflowId] as string;

  const atom = useAtom({
    type: 'TO_Number',
    sourceId: block.id,
    parentId: workflowId,
    parentKind: 'traceOverview',
    initialData: {
      ...initialTONumberData,
      /* eslint-disable */
      selectedVariable: block.other.selectedVariableRef!
    }
  });

  if (!atom) return null;

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    atom.data.title = event.target.value;
  };

  return (
    <BlockBase
      dndBlock={block}
      title={atom.data.title}
      dataItem={atom}
      icon={NumberIcon}
      endControls={
        <EndControlsFB hasRequired={false} dndBlock={block} dataItem={atom} />
      }
    >
      <Column>
        <InputField
          label="Widget title"
          value={atom.data.title}
          onChange={onTitleChange}
          placeholder="Aa"
        />
      </Column>
      <Column $width="200px">
        <FormControl size="sm">
          <FormLabel> State variable</FormLabel>
          <VariableSelector
            selectedRef={atom.data.selectedVariable}
            editable={false}
            disabled
          />
        </FormControl>
      </Column>
      <Column>
        <FormControl size="sm">
          <FormLabel>Display type</FormLabel>
          <RadioGroup value={atom.data.viewType} name="radio-buttons-group">
            {TO_NUMBER_VIEW_TYPES.map((viewType) => (
              <Radio
                key={viewType}
                value={viewType}
                onChange={(e) =>
                  (atom.data.viewType = e.target.value as typeof viewType)
                }
                label={viewType}
                color="neutral"
                size="sm"
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Column>
      <Column>
        <LabeledVariable>
          <FormLabel>Icon</FormLabel>
          <Checkbox
            label="Add an icon"
            variant="outlined"
            color="neutral"
            size="sm"
            checked={atom.data.hasIcon}
            onChange={(e) => (atom.data.hasIcon = e.target.checked)}
          />
          <Select
            placeholder="Select an icon…"
            value={atom.data.icon}
            renderValue={renderSelectedIconOption}
            disabled={!atom.data.hasIcon}
            size="sm"
            sx={{
              '--ListItemDecorator-size': '20px',
              marginTop: '10px',
              width: '100%'
            }}
            onChange={(_, newValue) => {
              if (newValue) atom.data.icon = newValue;
            }}
          >
            {numberIconOptions.map((iconOption) => (
              <Option key={iconOption.value} value={iconOption.value}>
                <ListItemDecorator>{iconOption.icon}</ListItemDecorator>
                {iconOption.label}
              </Option>
            ))}
          </Select>
        </LabeledVariable>
      </Column>
    </BlockBase>
  );
};

export default observer(Number);
