import { createZodDto } from 'nestjs-zod';
import { z } from 'zod';

import { UserSchema } from '../other/user.schema';
import { PodSchema } from './pod.schema';

export const HydratedGenerationSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  createdBy: UserSchema,
  job_id: z.string(),
  process_id: z.string(),
  source: z.string(),
  pods: z.array(PodSchema)
});

export type HydratedGeneration = z.infer<typeof HydratedGenerationSchema>;

export const GenerationMetadataSchema = z.object({
  numberOfGenerations: z.number()
});
export type GenerationMetadata = z.infer<typeof GenerationMetadataSchema>;
export const GetGenerationsParamsSchema = z
  .object({
    page: z.coerce.number().optional(),
    take: z.coerce.number().optional()
  })
  .refine(
    (params) => {
      if (params.page !== undefined) {
        return params.take !== undefined;
      }
      return true;
    },
    {
      message:
        "If 'page' is defined, 'take' must also be defined in query params",
      path: ['take']
    }
  );
export class GetGenerationsParamsDto extends createZodDto(
  GetGenerationsParamsSchema
) {}
export const UpdateGenerationSchema = z.object({
  currentlyDeployed: z.boolean().optional(),
  timedOutAt: z.string().nullish()
});
export class UpdateGenerationDto extends createZodDto(UpdateGenerationSchema) {}
export enum GenerationStatus {
  RUNNING = 'Running',
  FAILED = 'Failed',
  READY = 'Ready',
  PENDING = 'Pending',
  TIMEOUT = 'Timeout'
}
