import * as React from 'react';

import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';

import { ButtonProps, buttonVariants } from './button';

type KeyboardShortcut = {
  key: string;
  ctrl?: boolean;
  alt?: boolean;
  shift?: boolean;
};

type SmartButtonProps = ButtonProps & {
  shortcuts?: KeyboardShortcut | KeyboardShortcut[];
};

const SmartButton = React.forwardRef<HTMLButtonElement, SmartButtonProps>(
  (
    { className, variant, size, asChild = false, shortcuts, onClick, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
      if (!shortcuts) return;

      const shortcutsArray = Array.isArray(shortcuts) ? shortcuts : [shortcuts];

      const handleKeyDown = (event: KeyboardEvent) => {
        if (
          shortcutsArray.some(
            ({ key, ctrl, alt, shift }) =>
              key.toLowerCase() === event.key.toLowerCase() &&
              (ctrl === undefined || ctrl === event.ctrlKey) &&
              (alt === undefined || alt === event.altKey) &&
              (shift === undefined || shift === event.shiftKey)
          )
        ) {
          event.preventDefault();
          buttonRef.current?.click();
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }, [shortcuts]);

    React.useImperativeHandle(ref, () => buttonRef.current!, [buttonRef]);

    return (
      <Comp
        ref={buttonRef}
        className={cn(buttonVariants({ variant, size, className }))}
        onClick={onClick}
        {...props}
      />
    );
  }
);

SmartButton.displayName = 'SmartButton';

export { SmartButton, type KeyboardShortcut };
