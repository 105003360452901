import { observer } from 'mobx-react';
import { SelectedDatabaseData } from 'shared';

import { Label } from '@components/ui/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectTrigger,
  SelectValue
} from '@components/ui/select';

import { newError } from '@/services/errors/errors';

type Props = {
  id: string;
  sourceData: Maybe<SelectedDatabaseData>;
  onDataChange: (data: SelectedDatabaseData) => void;
};

export const DatabaseSelector = observer(
  ({ id, sourceData, onDataChange }: Props) => {
    // todo
    const allDatabases = [];

    const onRepositoryChange = (value: string | null): void => {
      if (!value) {
        newError('REP-D-vgM', 'null value after selecting a database');
        return;
      }

      onDataChange({
        selectedDatabase: value
      });
    };

    const hasRepos = !!allDatabases?.length;

    return (
      <>
        <Label htmlFor={`dtr-source-${id}`}>Database</Label>
        <Select
          onValueChange={onRepositoryChange}
          value={sourceData?.selectedDatabase ?? ''}
        >
          <SelectTrigger id={`dtr-source-${id}`} disabled={!hasRepos}>
            <SelectValue
              placeholder={
                sourceData?.selectedDatabase ??
                (hasRepos ? 'Select a repository' : 'No repositories found')
              }
            />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {/* {allDatabases.map((repo) => (
                <SelectItem value={repo.id} key={repo.id}>
                  <span className="flex items-center gap-2">
                    <Database size={15} />
                    {repo.data.title}
                  </span>
                </SelectItem>
              ))} */}
            </SelectGroup>
          </SelectContent>
        </Select>
      </>
    );
  }
);
