import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { GenerationStatus } from 'shared';

import { Avatar, AvatarImage } from '@components/ui/avatar';
import { Skeleton } from '@components/ui/skeleton';

import { getTimeAgo } from '@pages/Home/processCard/processCard.utils';
import {
  MAX_NAME_LENGTH,
  generationStatusToProgress
} from '@pages/Settings/generation';

import { ProcessModel } from '@models/process.model';
import { WorkflowModel } from '@models/workflow.model';

import {
  LastEditInfo,
  Side,
  WorkflowCardContainer,
  WorkflowInfos,
  WorkflowName,
  WorkflowPreview,
  WorkflowStatus,
  workflowCardClassName
} from '../process.style';
import BpmnPreview from './bpmnPreview';

interface WorkflowCardProps {
  workflow: WorkflowModel;
  process: ProcessModel;
  loading?: boolean;
}

export const WorkflowCard = observer(
  ({ workflow, process, loading = false }: WorkflowCardProps) => {
    const lastGenerations = process.generationPageStore.getPageItems(1);
    const editorPath = `/${process.id}/${workflow.id}/editor`;
    const avatar = lastGenerations[0]?.getCreatedBy().avatar;

    return (
      <WorkflowCardContainer to={loading ? '' : editorPath}>
        <WorkflowPreview>
          <BpmnPreview xml={workflow.workflowXML} />
        </WorkflowPreview>

        <WorkflowInfos>
          <Side className="truncate">
            <WorkflowName>{workflow.name}</WorkflowName>

            {lastGenerations.length > 0 && (
              <div className="flex flex-col">
                <LastEditInfo>
                  last updated {getTimeAgo(workflow.updatedAt)}
                </LastEditInfo>{' '}
                <LastEditInfo className="flex">
                  by{' '}
                  {lastGenerations[0]
                    .getCreatedBy()
                    .name.slice(0, MAX_NAME_LENGTH)}{' '}
                  <Avatar className="h-5 w-5 ml-2">
                    <AvatarImage
                      src={
                        avatar ??
                        'https://www.w3schools.com/howto/img_avatar.png'
                      }
                    />
                  </Avatar>
                </LastEditInfo>
              </div>
            )}
          </Side>
          <Side className="items-end text-right">
            <WorkflowStatus>
              {lastGenerations.length > 0
                ? generationStatusToProgress(lastGenerations[0].status, 16)
                : generationStatusToProgress(GenerationStatus.PENDING, 16)}
            </WorkflowStatus>
            {lastGenerations.length > 0 && (
              <LastEditInfo>
                generated {lastGenerations[0].elapsedTime}{' '}
              </LastEditInfo>
            )}
          </Side>
        </WorkflowInfos>
      </WorkflowCardContainer>
    );
  }
);

type SkeletonProps = {
  index: number;
};
export const WorkflowCardSkeleton = ({ index }: SkeletonProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
      className={workflowCardClassName}
    >
      <WorkflowPreview>
        <Skeleton className="h-full w-full rounded-none" />
      </WorkflowPreview>
      <WorkflowInfos className="shrink">
        <Side className="gap-1">
          <Skeleton className="h-5 w-32" />
          <div className="flex flex-col gap-1 mt-2">
            <Skeleton className="h-4 w-40" />
            <div className="flex items-center">
              <Skeleton className="h-3 w-24" />
              <Skeleton className="size-4 rounded-full ml-2" />
            </div>
          </div>
        </Side>
        <Side className="items-end text-right">
          <Skeleton className="size-4 rounded-full" />
          <Skeleton className="h-3 w-28 mt-2" />
        </Side>
      </WorkflowInfos>
    </motion.div>
  );
};
