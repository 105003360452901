import { computed, makeObservable, observable } from 'mobx';
import { EtlJob, EtlJobData, JobStatus, Pod, PodStatus } from 'shared';

import { AnalyticsStore } from '@stores/analytics.store';

import Model, { ModelError } from './base/base.model';

export class AnalyticsModel extends Model {
  name: string;
  data: EtlJobData;
  cron_expression?: string;
  description?: string | null;
  created_by?: string;
  starts_at?: string;
  ends_at?: string | null;
  status: JobStatus;
  sentry_monitor?: string | null;
  sentry_dsn?: string | null;
  pods: Pod[];
  image?: string | null;
  constructor(store: AnalyticsStore, analytics: EtlJob, loading?: boolean) {
    super(store, analytics.id, loading);
    this.name = analytics.name;
    this.data = analytics.data;
    this.cron_expression = analytics.cron_expression;
    this.description = analytics.description;
    this.created_by = analytics.created_by;
    this.starts_at = analytics.starts_at;
    this.ends_at = analytics.ends_at;
    this.status = analytics.status;
    this.sentry_monitor = analytics.sentry_monitor;
    this.sentry_dsn = analytics.sentry_dsn;
    this.pods = analytics.pods || [];
    this.image = analytics.image;
    makeObservable<
      AnalyticsModel,
      | 'name'
      | 'cron_expression'
      | 'description'
      | 'data'
      | 'created_by'
      | 'starts_at'
      | 'ends_at'
      | 'status'
      | 'sentry_monitor'
      | 'sentry_dsn'
      | 'image'
      | 'pods'
    >(this, {
      name: observable,
      cron_expression: observable,
      description: observable,
      data: observable,
      created_by: observable,
      starts_at: observable,
      ends_at: observable,
      status: observable,
      sentry_monitor: observable,
      sentry_dsn: observable,
      image: observable,
      pods: observable,
      shouldBePolled: computed,
      areAllPodsFinished: computed
    });
  }

  /* ---------------------------- Computed getters ---------------------------- */

  get shouldBePolled(): boolean {
    return this.pods.some((pod) => pod.status === PodStatus.RUNNING);
  }

  get areAllPodsFinished() {
    return this.pods.every(
      (pod) =>
        pod.status === PodStatus.SUCCESS || pod.status === PodStatus.FAILURE
    );
  }

  /* ------------------------ Computed setters ------------------------ */
  setPods(pods: Pod[]): void {
    this.pods = pods;
  }

  /* ------------------------- Abstract class methods ------------------------- */
  get toJSON() {
    return {};
  }

  get isDeletable(): boolean {
    return false;
  }

  get errors(): ModelError[] {
    return [];
  }
}
