import { makeObservable, observable, reaction } from 'mobx';

import Model from '@models/base/base.model';

import { newError } from '@/services/errors/errors';

import BaseStore from './base.store';

interface PageStoreConstructorProps<T extends Model> {
  totalNumberOfItemsInDB: number;
  baseStore: BaseStore<T>;
  itemsPerPage: number;
}

export class PageStore<T extends Model> {
  private totalNumberOfItemsInDB: number;
  private paginatedItems: Map<number, string[]> = new Map();
  private itemsPerPage: number;
  private numberOfPages: number = 0;

  private baseStore: BaseStore<T>;

  constructor(props: PageStoreConstructorProps<T>) {
    this.totalNumberOfItemsInDB = props.totalNumberOfItemsInDB;
    this.baseStore = props.baseStore;
    this.itemsPerPage = props.itemsPerPage;

    makeObservable<
      PageStore<T>,
      'paginatedItems' | 'totalNumberOfItemsInDB' | 'numberOfPages'
    >(this, {
      paginatedItems: observable,
      totalNumberOfItemsInDB: observable,
      numberOfPages: observable
    });

    reaction(
      () => this.totalNumberOfItemsInDB,
      () => {
        this.numberOfPages = Math.ceil(
          this.totalNumberOfItemsInDB / this.itemsPerPage
        );
      }
    );
  }

  /* ------------------------ Class properties getters ------------------------ */
  public getTotalNumberOfItemsInDB(): number {
    return this.totalNumberOfItemsInDB;
  }

  public getTotalNumberOfPages(): number {
    return this.numberOfPages;
  }

  public getItemsPerPage(): number {
    return this.itemsPerPage;
  }

  /* ------------------------ Class properties setters ------------------------ */
  public setTotalNumberOfItemsInDB(totalNumberOfItems: number): void {
    this.totalNumberOfItemsInDB = totalNumberOfItems;
  }

  public setPage(page: number, items: string[]): void {
    this.paginatedItems.set(page, items);
  }

  /* ----------------------------- Custom getters ----------------------------- */
  public getPageItems(page: number): T[] {
    return (this.paginatedItems.get(page) ?? [])
      .map((id) => this.baseStore.get(id))
      .filter((item) => item !== undefined);
  }

  public isPageCached(page: number): boolean {
    return this.paginatedItems.has(page);
  }

  /* ----------------------------- Custom setters ----------------------------- */
  public preprendItem(page: number, itemId: string): void {
    let currentPage = page;

    while (currentPage <= this.getTotalNumberOfPages()) {
      if (!this.isPageCached(currentPage)) {
        this.invalidateCacheFromPage(currentPage);
        return;
      }

      const currentPageData = this.paginatedItems.get(currentPage);

      if (!currentPageData) return;

      if (this.hasSpaceInPage(currentPageData)) {
        currentPageData.unshift(itemId);
        return;
      }

      itemId = this.moveItemBetweenPages(currentPageData, itemId);

      if (this.isLastPage(currentPage)) {
        this.createNewPage(currentPage + 1, itemId);
      }

      currentPage++;
    }
  }

  /* --------------------------------- Helper --------------------------------- */
  private hasSpaceInPage(pageData: string[]): boolean {
    return pageData.length < this.itemsPerPage;
  }

  private isLastPage(page: number): boolean {
    return page === this.getTotalNumberOfPages();
  }

  private createNewPage(newPage: number, itemId: string): void {
    this.paginatedItems.set(newPage, [itemId]);
  }

  private moveItemBetweenPages(
    currentPageData: string[],
    incomingItemId: string
  ): string {
    const outgoingItemId = currentPageData.pop();

    if (!outgoingItemId) {
      newError('PAGEM-gH-dK', 'No outgoing item id found');
      throw new Error('No outgoing item id found');
    }

    currentPageData.unshift(incomingItemId);

    return outgoingItemId;
  }

  private invalidateCacheFromPage(startPage: number): void {
    for (let page = startPage; page <= this.getTotalNumberOfPages(); page++) {
      this.paginatedItems.delete(page);
    }
  }
}
