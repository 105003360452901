import { useEffect } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ProcessModel } from '@models/process.model';

import { ParamsList } from '@/routes/routes.types';
import { PasswordProtectedError } from '@/services/errors/studio-errors';

import useStores from './useStore';

/**
 * Returns or fetches the process found in the url parameters.
 */
const useProcess = (): ProcessModel | undefined => {
  const processId = useParams()[ParamsList.ProcessId];
  const { processStore } = useStores();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!processId) return;

    const fetchProcess = async () => {
      await processStore.getorFetchFullProcess(processId);
    };

    fetchProcess().catch((error) => {
      if (error instanceof PasswordProtectedError) {
        if (!location.pathname.includes('password-protected')) {
          navigate(`/${processId}/password-protected`, {
            state: { originUrl: location.pathname }
          });
        }
      }
    });
  }, [processId, processStore, navigate, location]);

  return processStore.get(processId);
};

export default useProcess;
