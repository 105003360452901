import React, { ChangeEvent, useState } from 'react';

import { Input } from './input';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputWithWarning = React.forwardRef<
  HTMLInputElement,
  InputProps & { warningMessage?: string }
>(
  (
    {
      onChange,
      warningMessage = 'Warning : this change will have consequences',
      ...props
    },
    ref
  ) => {
    const [showWarning, setShowWarning] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setShowWarning(true);
      if (onChange) {
        onChange(e);
      }
    };
    return (
      <div ref={ref}>
        <Input onFocus={handleChange} {...props} onChange={onChange} />
        {showWarning && (
          <p className="text-sm text-red-600 mt-2">{warningMessage}</p>
        )}
      </div>
    );
  }
);

InputWithWarning.displayName = 'InputWithWarning';

export default InputWithWarning;
