import { SynchronizeDataType } from 'shared';
import { z } from 'zod';

export interface DynamicAccountDataOptions {
  type: SynchronizeDataType;
  options?: Record<string, string>;
}

export const AccountMembersResponseSchema = z.object({
  members: z.object({
    nodes: z.array(z.object({ email: z.string() }))
  })
});
export type AccountMembersResponse = z.infer<
  typeof AccountMembersResponseSchema
>;

export const OrganizationByNameResponseSchema = z.object({
  organizationByName: AccountMembersResponseSchema
});
export type OrganizationByNameResponse = z.infer<
  typeof OrganizationByNameResponseSchema
>;

export const TeamByRowIdResponseSchema = z.object({
  teamByRowId: AccountMembersResponseSchema
});
export type TeamByRowIdResponse = z.infer<typeof TeamByRowIdResponseSchema>;
