import { z } from 'zod';

/* ---------------------------------- ENUMS --------------------------------- */
export enum PodStatus {
  RUNNING = 'RUNNING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS'
}

/* --------------------------------- SCHEMAS -------------------------------- */
export const PodSchema = z.object({
  id: z.string(),
  job_id: z.string(),
  status: z.nativeEnum(PodStatus),
  triggered_at: z.string(),
  ended_at: z.string().nullish(),
  logs: z
    .union([z.string(), z.record(z.string(), z.any())])
    .optional()
    .nullish(),
  metrics: z.any().optional()
});
export type Pod = z.infer<typeof PodSchema>;

export const PodsSchema = z.array(PodSchema);
