import React from 'react';

import { observer } from 'mobx-react';
import { JobStatus, PodStatus } from 'shared';

import { getIconColors, getIconStatus, statusIconMap } from '../utils';

interface ETLStatusIconProps {
  jobStatus: JobStatus;

  podStatus?: PodStatus;
}

const ETLStatusIcon = ({ podStatus, jobStatus }: ETLStatusIconProps) => {
  const iconStatus = getIconStatus(jobStatus, podStatus);
  const { bgColor, color } = getIconColors(iconStatus);
  if (!bgColor || !color) return;
  const IconComponent = statusIconMap[iconStatus];
  return (
    <div className="flex items-center gap-2">
      <div
        className={`flex items-center justify-center p-2 rounded-full ${bgColor} border  `}
      >
        {IconComponent ? <IconComponent className={color} size={18} /> : null}
      </div>
    </div>
  );
};

export default observer(ETLStatusIcon);
