import { makeObservable, observable } from 'mobx';

import BaseStore from '@stores/base/base.store';

import nanoID from '@/utils/nanoID';

export type ModelError = {
  name?: string;
  code?: number;
  message: string;
};

export default abstract class Model {
  constructor(
    public store: BaseStore<Model>,
    public id: string = nanoID(),
    public loading: boolean = false
  ) {
    makeObservable(
      this,
      {
        loading: observable
      },
      {
        deep: true
      }
    );
  }

  abstract get toJSON();

  public abstract get errors(): ModelError[];
}
