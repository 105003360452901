import React from 'react';

import { cn } from '@/lib/utils';

interface HeaderRootProps extends React.HTMLAttributes<HTMLElement> {
  isHomepage?: boolean;
}

const HeaderRoot = ({ children }) => {
  return <header className={cn('w-full ')}>{children}</header>;
};

const MainHeaderRoot = ({ children, isHomepage }: HeaderRootProps) => {
  return (
    <div
      className={cn(
        'w-full flex-row justify-between h-[60px] flex items-center bg-white pr-4 pl-1',
        isHomepage && 'border-b border-solid border-gray-200'
      )}
    >
      {children}
    </div>
  );
};

const HeaderDivider = () => <p className="text-gray-200 pl-0 pr-1">/</p>;

const HeaderLeft = ({ children }) => (
  <div className="flex z-[100] flex-row items-center">{children}</div>
);

const HeaderRight = ({ children }) => (
  <div className="flex  space-x-3">{children}</div>
);

const HeaderMenu = ({ children }) => {
  return (
    <div className="flex flex-row  m-1  gap-x-2 items-center select-none">
      {children}
    </div>
  );
};

const HeaderMenuItem = ({ children }) => {
  return (
    <div className="flex flex-row  items-center select-none cursor-pointer">
      {children}
    </div>
  );
};

interface HeaderMenuItemProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  onClick: () => void;
}
const HeaderMenuItemLink = ({ children, onClick }: HeaderMenuItemProps) => {
  return (
    <div
      className="flex flex-row w-full rounded-sm pl-2 py-1 gap-x-3 items-center select-none cursor-pointer"
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const HeaderMenuItemLabel = ({ children }) => {
  return (
    <p className="text-sm text-gray-700 font-semibold text-center">
      {children}
    </p>
  );
};

const EnvironmentSpan = ({ children }) => {
  return (
    <span className="bg-gradient-to-r from-[#4b35d2] to-[#5246f7] rounded-lg w-max text-white text-sm font-semibold tracking-wide leading-5 px-3 ml-3 select-none">
      {children}
    </span>
  );
};

const ProcessList = ({ children }) => {
  return <div className="max-h-[400px] overflow-auto">{children}</div>;
};

const CustomMenuItem = ({ children }) => {
  return (
    <div className="flex items-center gap-[10px] px-[10px] py-[5px] cursor-pointer hover:bg-[#f0f4f8]">
      {children}
    </div>
  );
};

export {
  HeaderRoot,
  MainHeaderRoot,
  HeaderDivider,
  HeaderLeft,
  HeaderRight,
  HeaderMenu,
  HeaderMenuItem,
  HeaderMenuItemLink,
  HeaderMenuItemLabel,
  EnvironmentSpan,
  CustomMenuItem,
  ProcessList
};
