import { useNavigate, useParams } from 'react-router-dom';

import { Skeleton } from '@components/ui/skeleton';

import { useProcesses } from '@hooks/useProcesses';

import { ProcessModel } from '@models/process.model';

import { ParamsList } from '@/routes/routes.types';

import { HeaderDropdown } from './headerDropdown';

export const ProcessSelector = () => {
  const navigate = useNavigate();

  const processId = useParams()[ParamsList.ProcessId] as string;
  const { loading, processes } = useProcesses();

  const process = processes.find((p) => p.id == processId);

  const dropdownItems = processes.map((process) => ({
    id: process.id,
    name: process.name
  }));

  const changeProcess = (processId: ProcessModel['id']) => {
    navigate(`/${processId}/general`);
  };

  if (loading) {
    return <Skeleton className="w-[100px] h-[20px]" />;
  }

  if (!process) {
    return null;
  }

  return (
    <HeaderDropdown
      type="process"
      items={dropdownItems}
      currentItemId={processId}
      onSelect={changeProcess}
    />
  );
};
