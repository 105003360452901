import { observer } from 'mobx-react';
import { Outlet, useParams } from 'react-router-dom';

import { SubheaderItem } from '@components/header/base/subheader/sub-header-item';
import { SubHeader } from '@components/header/base/subheader/sub-header.comp';

import {
  DRAFT_PROCESS_MENU_ITEMS,
  STRATUMN_PROCESS_MENU_ITEMS
} from '@pages/Settings/menu';

import useProcess from '@hooks/useProcess';

import { ParamsList } from '@/routes/routes.types';

export const ProcessLayout = () => {
  const process = useProcess();
  const processId = useParams()[ParamsList.ProcessId] as string;

  const PROCESS_MENU_ITEMS = process?.draft
    ? DRAFT_PROCESS_MENU_ITEMS
    : STRATUMN_PROCESS_MENU_ITEMS;
  const canEdit = process?.permission.can_edit;

  const navigate = (link: string) => `/${processId}/${link}`;
  return (
    canEdit && (
      <>
        {canEdit && (
          <SubHeader>
            {PROCESS_MENU_ITEMS.map(({ name, link }) => (
              <SubheaderItem
                key={link}
                name={name}
                link={link}
                to={navigate(link)}
              />
            ))}
          </SubHeader>
        )}
        <Outlet />
      </>
    )
  );
};

export default observer(ProcessLayout);
