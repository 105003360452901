import { GatewayType } from 'shared';

import { WorkflowModel } from '@models/workflow.model';

import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import {
  CreateShapeEvent,
  DeleteShapeEvent,
  ShapeEvents
} from '@/types/event.types';

import { UserAction } from './shape.plexer';

function bpmnTypeToStudioType(type: string): GatewayType | undefined {
  switch (type) {
    case 'bpmn:ParallelGateway':
      return 'PARALLEL';
    case 'bpmn:ExclusiveGateway':
      return 'EXCLUSIVE';
    case 'bpmn:InclusiveGateway':
      return 'INCLUSIVE';
    default:
      return undefined;
  }
}

export const onGatewayCreated = async (
  event: CreateShapeEvent,
  workflow: WorkflowModel,
  rootStore: RootStore
): Promise<boolean> => {
  const bpmnId = event.context.shape?.id;
  if (!bpmnId) {
    newError('GATEW-QR396', 'Event must have an id');
    return false;
  }

  const gatewayType = bpmnTypeToStudioType(event.context.shape?.type || '');

  if (!gatewayType) {
    newError(
      'GATEW-wKSTe',
      `Gateway type ${event.context.shape?.type} is not supported`,
      true
    );
    return false;
  }

  const gatewayStore = rootStore.gatewayStore;

  const res = await gatewayStore.createGateway(
    bpmnId,
    gatewayType,
    workflow.id
  );

  if (res) {
    return true;
  } else {
    return false;
  }
};

export const onGatewayDeleted = async (
  event: DeleteShapeEvent,
  _workflowModel: WorkflowModel,
  rootStore: RootStore
): Promise<boolean> => {
  const bpmnId = event.context.shape?.id;
  if (!bpmnId) {
    newError('GATEW-eoVdn', 'Event must have an id');
    return false;
  }

  const gatewayType = bpmnTypeToStudioType(event.context.shape?.type || '');

  if (!gatewayType) {
    newError(
      'GATEW-T4Kdo',
      `Gateway type ${event.context.shape?.type} is not supported`,
      true
    );
    return false;
  }

  const gatewayStore = rootStore.gatewayStore;

  return await gatewayStore.deleteById(bpmnId);
};

export const gatewayFunctionPlexer: Record<
  UserAction,
  (
    event: ShapeEvents,
    workflowModel: WorkflowModel,
    rootStore: RootStore
  ) => Promise<boolean>
> = {
  create: onGatewayCreated,
  delete: onGatewayDeleted
};
