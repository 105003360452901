import { FormControl, FormLabel, Input, InputProps } from '@mui/joy';

export type FormMessage = { name?: string; message: string };

interface InputFieldProps extends InputProps {
  label?: string;
  innerRef?: React.Ref<HTMLInputElement>;
  errors?: FormMessage[];
  warnings?: FormMessage[];
  width?: string;
}
/** @deprecated Use `Input` from `shadcn/ui` instead */
export const InputField = (props: InputFieldProps) => (
  <FormControl size={props.size || 'sm'} sx={{ width: props.width }}>
    {props.label && (
      <FormLabel required={props.required}>{props.label}</FormLabel>
    )}
    <Input
      variant="outlined"
      type="text"
      autoComplete="off"
      ref={props.innerRef}
      error={!!props.errors?.length || props.error}
      {...props}
    />
    {!!props.errors?.length && (
      <ul className="w-full text-wrap pl-3 pt-2 text-xs text-red-700">
        {props.errors.map((error) => (
          <li key={error.name} className="list-disc">
            {error.message}
          </li>
        ))}
      </ul>
    )}
    {!!props.warnings?.length && (
      <ul className="list-item w-full text-wrap pt-2 text-xs text-yellow-700">
        {props.warnings.map((warning) => (
          <li key={warning.name}>{warning.message}</li>
        ))}
      </ul>
    )}
  </FormControl>
);
