import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  AllCommunityModule,
  CellEditingStoppedEvent,
  ColDef,
  IRowNode,
  ModuleRegistry,
  RowPinnedType,
  SelectionChangedEvent,
  ValueFormatterParams,
  provideGlobalGridOptions
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ColumnContextSchema, ResolvedVariableType } from 'shared';
import { AtomReference } from 'shared/src/atom/atomReference.schema';
import { RowData } from 'shared/src/atom/sources/database/row.atom';
import { toast } from 'sonner';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { LoaderBox } from '@/components/ui/loader';
import { ParamsList } from '@/routes/routes.types';
import { newError } from '@/services/errors/errors';
import { RowDeletionError } from '@/services/errors/studio-errors';
import nanoID from '@/utils/nanoID';
import { CircularProgress } from '@mui/joy';

import { GridContainer } from './grid.style';

ModuleRegistry.registerModules([AllCommunityModule]);

provideGlobalGridOptions({ theme: 'legacy' });

const PINNED_TOP_ROW_POSITION: RowPinnedType = 'top';

const deleteRowsToastId = nanoID();
const addRowToastId = nanoID();

const Grid = () => {
  const { atomStore, modalStore } = useStores();
  const process = useProcess();
  const databaseId = useParams<string>()[ParamsList.DatabaseId];
  const [inputRow, setInputRow] = useState<RowData>({ rowAtomId: nanoID() });

  useEffect(() => {
    return () => {
      toast.dismiss(deleteRowsToastId);
      toast.dismiss(addRowToastId);
    };
  }, []);

  const gridRef = useRef<AgGridReact>(null);

  const canUserEdit = useCallback(() => {
    return process?.permission.can_edit ?? false;
  }, [process]);

  const isRowSelectable = useCallback(
    (_node: IRowNode<RowData>) => {
      return canUserEdit();
    },
    [canUserEdit]
  );

  const _isUserOwner = useCallback(() => {
    return process?.permission.can_delete ?? false;
  }, [process]);

  const isEmptyPinnedCell = (params: ValueFormatterParams<RowData>) => {
    return (
      (params.node?.rowPinned === PINNED_TOP_ROW_POSITION &&
        params.value == null) ||
      (params.node?.rowPinned === PINNED_TOP_ROW_POSITION &&
        params.value === '')
    );
  };

  const createPinnedCellPlaceholder = (
    params: ValueFormatterParams<RowData>
  ) => {
    return 'New '.concat(params.column.getColId()).concat('...');
  };

  const defaultColDef: Partial<ColDef> = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      // floatingFilter: true,
      filter: true,
      resizable: false,
      editable: canUserEdit(),
      wrapHeaderText: true,
      autoHeaderHeight: true,
      valueFormatter: (params: ValueFormatterParams<RowData, string>) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : (params.value ?? '')
    }),
    [canUserEdit]
  );

  const columnDefs = useMemo(() => {
    const database = process?.databases.find((db) => db.id === databaseId);

    if (!database) return [];

    return database.getGridColumnDefinitions();
  }, [databaseId, process]);

  if (!process || process.isSmallProcess) {
    return (
      <LoaderBox>
        <CircularProgress />
      </LoaderBox>
    );
  }

  const database = process.databases.find((db) => db.id === databaseId);

  if (!database) return;

  const deleteSelectedRows = async (selectedRows: RowData[]) => {
    const deletedRows: string[] = [];
    for (const selectedRow of selectedRows) {
      const rowAtomId = selectedRow.rowAtomId;

      if (!rowAtomId) continue;

      const atomToDelete = atomStore.getAtomById<RowData>(rowAtomId, 'Row');

      if (!atomToDelete || atomToDelete instanceof Error) continue;

      if (!atomToDelete.isDeletable) {
        modalStore.referenceModal.open(
          'atom',
          atomToDelete.id,
          'referencedBy',
          atomToDelete.type
        );
        continue;
      }

      const isRowReferenceRemoved = database.removeRowReference(rowAtomId);
      if (!isRowReferenceRemoved) continue;

      const isAtomDeleted = await atomStore.deleteAtom(rowAtomId);
      if (!isAtomDeleted) continue;

      deletedRows.push(rowAtomId);
    }

    if (deletedRows.length !== selectedRows.length) {
      throw new RowDeletionError(selectedRows.length - deletedRows.length);
    }

    return deletedRows;
  };

  const isPinnedRowEvent = (event: CellEditingStoppedEvent<RowData>) => {
    return event.rowPinned === PINNED_TOP_ROW_POSITION;
  };

  const handlePinnedRowEvent = (event: CellEditingStoppedEvent<RowData>) => {
    const primaryKey = database.getPrimaryKeyColumn();

    if (!primaryKey) return;

    const isPrimaryKeyCompleted = inputRow[primaryKey.field] !== undefined;

    if (!isPrimaryKeyCompleted) return;

    toast.message(`New ${inputRow[primaryKey.field]?.toString()}`, {
      id: addRowToastId,
      position: 'bottom-center',
      duration: Infinity,
      action: {
        label: 'Add row',
        onClick: () => {
          toast.dismiss(addRowToastId);
          const isNewRowCreated = createNewRow(event);
          if (!isNewRowCreated) {
            newError('AGMSE-8gAqW', 'Error while creating new row');
          } else {
            toast.success('New row successfully created !', {
              position: 'bottom-center'
            });
          }
        }
      },
      actionButtonStyle: {
        backgroundColor: '#635CF8'
      }
    });
  };

  const createNewRowAtom = (data: RowData) => {
    const newRowAtom = atomStore.createAtom(
      data.rowAtomId,
      'Row',
      data,
      {
        source: {
          parentId: database.id,
          elementId: database.id,
          parentKind: 'database'
        }
      },
      process.id,
      {
        resolvedType: ResolvedVariableType.Row
      }
    );

    return newRowAtom;
  };

  const isAReferenceColumn = (event: CellEditingStoppedEvent<RowData>) => {
    const parsedContext = ColumnContextSchema.safeParse(event.colDef.context);
    return parsedContext.success && parsedContext.data?.reference !== undefined;
  };

  const onSelectionChanged = (event: SelectionChangedEvent<RowData>) => {
    const rowDeletionDisabled = database?.getContext?.rowDeletionDisabled;
    const selectedRows = event.api.getSelectedRows();

    if (selectedRows.length === 0) {
      toast.dismiss(deleteRowsToastId);
      return;
    }

    toast.message(`${selectedRows.length} row(s) selected`, {
      id: deleteRowsToastId,
      duration: Infinity,
      position: 'bottom-center',
      action: !rowDeletionDisabled
        ? {
            label: 'Delete',
            onClick: () => {
              toast.dismiss(deleteRowsToastId);
              toast.promise(deleteSelectedRows(selectedRows), {
                position: 'bottom-center',
                loading: `Deleting ${selectedRows.length} row(s)...`,
                success: (deletedRows) => {
                  return `${deletedRows.length} row(s) successfully deleted`;
                },
                error: (error) => {
                  if (error instanceof RowDeletionError) {
                    return error.message;
                  }
                  return 'Unknown error while deleting rows';
                }
              });
            }
          }
        : undefined,
      actionButtonStyle: {
        backgroundColor: '#E8544D'
      }
    });
  };

  const updateRowAtomsReferences = (
    event: CellEditingStoppedEvent<RowData, AtomReference[]>
  ) => {
    const concernedRowAtomId = event.data?.rowAtomId;
    const newReferences = event.value;

    const parsedContext = ColumnContextSchema.safeParse(event.colDef.context);

    if (!parsedContext.success || !parsedContext.data) {
      newError(
        'AGMSE-w1bOq',
        `Column context is not valid while updating references, references will not be updated`
      );
      return;
    }

    const databaseReferenced = parsedContext.data.reference?.databaseId;

    if (!databaseReferenced) {
      newError(
        'AGMSE-NEkBV',
        `Database referenced not found while updating references, references will not be updated`
      );
      return;
    }

    const concernedRowAtom = atomStore.getAtomById<RowData>(
      concernedRowAtomId,
      'Row'
    );

    if (!concernedRowAtom || concernedRowAtom instanceof Error) {
      newError(
        'AGMSE-8gAqW',
        `Concerned row atom ${concernedRowAtomId} not found while updating references, 
          references will not be updated`
      );
      return;
    }

    concernedRowAtom.syncRowReferences(
      newReferences ? newReferences : [],
      databaseReferenced
    );
  };

  const createNewRow = (_event: CellEditingStoppedEvent) => {
    const newRowAtom = createNewRowAtom(inputRow);
    if (!newRowAtom || newRowAtom instanceof Error) return false;
    setInputRow({ rowAtomId: nanoID() });
    return database.addRowReference({
      dataItemId: newRowAtom.id,
      sourceId: database.id,
      blockType: 'Row'
    });
  };

  const onCellEditingStopped = (event: CellEditingStoppedEvent<RowData>) => {
    if (isPinnedRowEvent(event)) {
      handlePinnedRowEvent(event);
    }
    if (isAReferenceColumn(event)) {
      updateRowAtomsReferences(
        event as CellEditingStoppedEvent<RowData, AtomReference[]>
      );
    }
  };

  return (
    <GridContainer className="ag-theme-quartz">
      {/* <Toaster position="bottom-center" closeButton={false} /> */}
      <AgGridReact
        theme="legacy"
        ref={gridRef}
        rowData={database.getRowAtoms().map((atom) => atom.data)}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onCellEditingStopped={onCellEditingStopped}
        onSelectionChanged={onSelectionChanged}
        rowSelection={{
          mode: 'multiRow',
          isRowSelectable
        }}
        containerStyle={{
          wrapperBorder: false
        }}
        autoSizeStrategy={{
          type: 'fitGridWidth'
        }}
        // domLayout="autoHeight"
        pinnedTopRowData={
          database.hasOneSynchronizeColumn() ? undefined : [inputRow]
        }
        animateRows={true}
      />
    </GridContainer>
  );
};

export default observer(Grid);
