import { useState } from 'react';

import { observer } from 'mobx-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Button } from '@components/ui/button';
import { Input } from '@components/ui/input';

import useStores from '@hooks/useStore';

import { ParamsList } from '@/routes/routes.types';
import { newError } from '@/services/errors/errors';
import { PasswordProtectedError } from '@/services/errors/studio-errors';

export default observer(() => {
  const { state } = useLocation() as { state: { originUrl: string } };
  const { originUrl } = state;
  const navigate = useNavigate();
  const { processStore } = useStores();
  const processId = useParams()[ParamsList.ProcessId] as string;
  const [password, setPassword] = useState('');

  return (
    <div className="border">
      <div className="pb-8">
        <h3 className="font-semibold text-md">
          This process is password protected.
        </h3>
        <p className="font-medium text-sm">
          Please provide the password or ask it to the owner.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <Input
          type="password"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          placeholder="Enter password"
        />
        <div className="flex flex-row justify-end gap-x-4">
          <Button
            onClick={() => {
              navigate('/');
            }}
            variant={'ghost'}
          >
            Go back
          </Button>
          <Button
            onClick={() => {
              processStore
                .getorFetchFullProcess(processId, {
                  password
                })
                .then((process) => {
                  if (process) {
                    navigate(originUrl);
                  }
                })
                .catch((error) => {
                  if (error instanceof PasswordProtectedError) {
                    newError('PW-QxFEb', 'Invalid password', true);
                  }
                });
            }}
            variant={'default'}
            className="text-white"
          >
            Unlock
          </Button>
        </div>
      </div>
    </div>
  );
});
