import { GitCommitHorizontal, Loader2 } from 'lucide-react';
import { observer } from 'mobx-react';
import { JobStatus, Pod, PodStatus } from 'shared';

import { CancelProgress } from '@components/progress/cancelProgress';
import { DoneProgress } from '@components/progress/doneProgress';
import { TodoProgress } from '@components/progress/todoProgress';

import { AnalyticsModel } from '@models/analytics.model';

export const analyticsStatusToProgress = (status: JobStatus) => {
  switch (status) {
    case JobStatus.RECEIVED:
      return <TodoProgress size={14} />;
    case JobStatus.IN_PROGRESS:
      return <Loader2 size={14} color="#FCDD4B" />;
    case JobStatus.COMPLETED:
      return <DoneProgress size={14} color="#28a745" />;

    default:
      return <TodoProgress size={14} />;
  }
};

export const podStatusToProgress = (status: PodStatus) => {
  switch (status) {
    case PodStatus.RUNNING:
      return <Loader2 className="h-4 w-4 animate-spin" color="#4b35d2" />;
    case PodStatus.FAILURE:
      return <CancelProgress size={14} color="#eb5757" />;
    case PodStatus.SUCCESS:
      return <DoneProgress size={14} color="#28a745" />;

    default:
      return <TodoProgress size={14} />;
  }
};

interface AnalyticsProps {
  analytics: AnalyticsModel;
  pod: Pod;
}
const AnalyticsCard = ({ analytics, pod }: AnalyticsProps) => {
  return (
    <div
      className="border rounded-md shadow-sm bg-white p-5 text-sm font-normal mt-4 mb-11"
      style={{
        borderColor: '1px solid rgba(0, 0, 0, 0.08)',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.04)'
      }}
    >
      <div className="flex mb-5">
        <div className="flex flex-1 flex-col leading-6">
          <div className="text-zinc-500">ETL name</div>
          <div className="font-medium text-zinc-950">{analytics.name}</div>
        </div>
        <div className="flex flex-1 flex-col leading-6">
          <div className="text-zinc-500">ETL Status</div>
          <div className="flex flex-row items-center">
            {analytics.status !== undefined &&
              analyticsStatusToProgress(analytics.status)}
            <div className="ml-2 font-medium text-zinc-950">
              {analytics.status}
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col leading-6">
          <div className="text-zinc-500">Cron Expression</div>
          <div className="flex flex-row items-center font-medium text-zinc-950">
            {analytics.cron_expression}
          </div>
        </div>
      </div>

      <div className="flex mb-5">
        <div className="flex flex-1 flex-col leading-6">
          <div className="text-zinc-500">Pod ID</div>
          <div className="flex flex-row items-center font-medium text-zinc-950">
            {pod.id}
          </div>
        </div>
        <div className="flex flex-1 flex-col leading-6">
          <div className="text-zinc-500">Pod Status</div>
          <div className="flex flex-row items-center">
            {podStatusToProgress(pod.status)}
            <div className="ml-2 font-medium text-zinc-950">{pod.status}</div>
          </div>
        </div>
        <div className="flex flex-1 flex-col leading-6">
          <div className="text-zinc-500">Triggered at</div>
          <div className="flex flex-row items-center font-medium text-zinc-950">
            {new Intl.DateTimeFormat('en-US', {
              dateStyle: 'medium',
              timeStyle: 'short'
            }).format(new Date(pod.triggered_at))}
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col leading-6">
        <div className="text-zinc-500">Image</div>
        <div className="flex flex-row items-center">
          <GitCommitHorizontal size={18} />
          <div className="font-medium ml-2 text-zinc-950">
            {analytics.image}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AnalyticsCard);
