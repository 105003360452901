import { observer } from 'mobx-react';

import {
  Generation,
  GenerationContainer
} from '@components/generation/generation';
import { GenerationSkeleton } from '@components/generation/generation.skeleton';

import useStores from '@hooks/useStore';

import { ProcessModel } from '@models/process.model';

type Props = {
  process: Maybe<ProcessModel>;
};

export const LastGenerations = observer(({ process }: Props) => {
  const { processStore } = useStores();

  if (!process) return null;

  if (process.hasNoGenerations) {
    return (
      <div className="text-sm text-zinc-950">
        This process has not been generated yet
      </div>
    );
  }

  if (
    process.isSmallProcess ||
    processStore.isGenerationPageFetching(process.id, 1)
  ) {
    return (
      <GenerationContainer>
        {Array.from({ length: 3 }).map((_, index) => (
          <GenerationSkeleton key={index} index={index} />
        ))}
      </GenerationContainer>
    );
  }

  return (
    <GenerationContainer>
      {process.lastGenerations.map((generation) => (
        <Generation
          key={generation.id}
          generationId={generation.id}
          to={`/${process.id}/generation/${generation.id}`}
          isCurrent={generation.getCurrentlyDeployed()}
          status={generation.status}
          duration={generation.duration}
          elapsedTime={generation.elapsedTime}
          sourceTag={generation.sourceTag}
          avatar={generation.getCreatedBy().avatar}
          name={generation.getCreatedBy().name}
        />
      ))}
    </GenerationContainer>
  );
});
