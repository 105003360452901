import { useState } from 'react';

import { Info } from 'lucide-react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { JobStatus, Pod, UpdateEtlJobDTO } from 'shared';
import { toast } from 'sonner';

import { CronSelector } from '@components/cronSelector/cronSelector';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@components/ui/accordion';
import { Button } from '@components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@components/ui/card';
import { Input } from '@components/ui/input';

import useStores from '@hooks/useStore';
import { useWorkflow } from '@hooks/useWorkflow';

import { AnalyticsModel } from '@models/analytics.model';

import ActionButton from '@atoms/button';

import { cn } from '@/lib/utils';

import ETLStatusIcon from './AnalyticsStatusIcon';
import DeleteEtlModal from './DeleteEtlModal';
import EditEtlModal from './EditEtlModal';

const AnalyticsMonitor = observer(
  ({ analytics }: { analytics: AnalyticsModel }) => {
    const { workflow } = useWorkflow();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [emptyNameError, setEmptyNameError] = useState(false);

    const { analyticsStore } = useStores();
    const [analyticsName, setAnalyticsName] = useState<
      AnalyticsModel['name'] | undefined
    >(analytics?.name);
    const navigate = useNavigate();

    const handleLaunchJob = (analyticsId: string) => {
      if (!workflow) return;
      const promTrigger: Promise<Pod | undefined> =
        analyticsStore.triggerEtlJob(analyticsId, workflow);

      toast.promise(promTrigger, {
        loading: 'Triggering analytics...',
        success: (loadedAnalyticsPod) => {
          if (!loadedAnalyticsPod) return;
          return (
            <span>
              Analytics successfully triggered !
              <br />
              <a
                onClick={() =>
                  loadedAnalyticsPod &&
                  navigate(`${analyticsId}/${loadedAnalyticsPod.id}`)
                }
                className="cursor-pointer underline"
              >
                'Go to ongoing analytics'
              </a>
            </span>
          );
        },
        error: (error) => {
          if (error instanceof Error) {
            return 'An error occured while generating your analytics: '.concat(
              error.message
            );
          }
          return 'An error occured while generating your analytics: '.concat(
            JSON.stringify(error, Object.getOwnPropertyNames(error))
          );
        }
      });
    };
    const handlePause = async (analyticsId: string) => {
      await analyticsStore.pauseEtlJob(analyticsId, {
        pause: analytics.status === JobStatus.RECEIVED ? false : true
      });
    };
    const handleDelete = async () => {
      await analyticsStore.delete(analytics.id);
    };

    const handleBlur = async () => {
      if (!analyticsName) {
        return setEmptyNameError(true);
      }
      if (emptyNameError) {
        setEmptyNameError(false);
      }
      if (analytics) {
        await analyticsStore.updateEtlJob(analytics.id, {
          name: analyticsName
        });
      }
    };

    const handleInputChange = async (field: string, value: string) => {
      const [mainField, subField] = field.split('.') as [
        keyof AnalyticsModel,
        string
      ];
      if (subField) {
        const previousField = { ...analytics };
        const payload = {
          [mainField]: {
            ...previousField[mainField],
            [subField]: value
          }
        } as UpdateEtlJobDTO;
        await analyticsStore.updateEtlJob(analytics.id, payload);
        return;
      }
      await analyticsStore.updateEtlJob(analytics.id, {
        [field]: value
      });
    };

    return (
      <>
        <Card className="p-0 shadow-md relative overflow-hidden mt-4">
          <div className="flex flex-col items-start w-full p-4">
            <CardHeader className="text-start w-full">
              <CardTitle className="flex justify-between">
                <div className="flex gap-4">
                  <Input
                    className={cn(
                      'bg-transparent border-none outline-none hover:bg-gray-100 focus:outline-none focus:ring-0 focus-visible:outline-none px-2 py-1 text-gray-800 min-w-70',
                      emptyNameError && 'outline outline-1 outline-red-500'
                    )}
                    name="name"
                    value={analyticsName}
                    onChange={(e) => setAnalyticsName(e.target.value)}
                    onBlur={() => void handleBlur()}
                    onClick={(e) => e.stopPropagation()}
                    onKeyUp={(e) => e.preventDefault()}
                  />
                  {emptyNameError && (
                    <div className="text-red-500 flex text-xs items-center gap-2">
                      <Info size={12} />
                      Analytics name can't be empty
                    </div>
                  )}
                </div>
                <ETLStatusIcon
                  jobStatus={analytics.status}
                  podStatus={analytics.pods && analytics.pods?.[0]?.status}
                />
              </CardTitle>
              <CronSelector
                isPlain
                name="cron_expression"
                value={analytics.cron_expression ?? ''}
                onCronChange={(e) =>
                  void handleInputChange('cron_expression', e)
                }
                fullWidth={false}
                onClick={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.preventDefault()}
              />
            </CardHeader>

            <CardContent className="p-0 pt-2 text-start w-full text-sm">
              <Accordion type="single" collapsible>
                <AccordionItem value="controls" className="px-2">
                  <AccordionTrigger className="p-0">
                    <div className="text-sm">Controls</div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div>
                      <ActionButton
                        size="sm"
                        onClick={() => {
                          void handleLaunchJob(analytics.id);
                        }}
                        value="Trigger job"
                      />

                      <div
                        className="inline-flex rounded-md shadow-sm ml-4 mt-4"
                        role="group"
                      >
                        <Button
                          size="sm"
                          variant={'outline'}
                          className="  hover:text-blue-700 rounded-none rounded-s-lg font-normal"
                          onClick={() => {
                            setOpenModalEdit(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          size="sm"
                          variant={'outline'}
                          className=" hover:text-blue-700 rounded-none border-x-0 font-normal"
                          onClick={() => void handlePause(analytics.id)}
                        >
                          {analytics.status === JobStatus.IN_PROGRESS
                            ? 'Pause'
                            : 'Resume'}
                        </Button>
                        <Button
                          size="sm"
                          variant={'outline'}
                          className="text-red-700 rounded-none rounded-e-lg hover:text-red-800 font-normal"
                          onClick={() => setOpenModalDelete(true)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </CardContent>
          </div>
        </Card>
        <DeleteEtlModal
          openModal={openModalDelete}
          setOpenModal={setOpenModalDelete}
          onDelete={handleDelete}
          closeModal={() => setOpenModalDelete(false)}
        />
        <EditEtlModal
          openModal={openModalEdit}
          setOpenModal={setOpenModalEdit}
          analytics={analytics}
          closeModal={() => setOpenModalEdit(false)}
          handleInputChange={handleInputChange}
        />
      </>
    );
  }
);

export default AnalyticsMonitor;
