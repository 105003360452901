import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { StudioUser } from 'shared';

import {
  EnvironmentSpan,
  HeaderDivider,
  HeaderLeft,
  HeaderMenu,
  HeaderMenuItem,
  HeaderMenuItemLabel,
  HeaderMenuItemLink,
  HeaderRight,
  MainHeaderRoot
} from '@components/header/header.comp';

import useProcess from '@hooks/useProcess';

import { Logo } from '@atoms/logo/logo.comp';

import { ParamsList } from '@/routes/routes.types';
import { ENV, EnvironmentName } from '@/utils/constants';
import { Skeleton } from '@mui/joy';

import { ProcessSelector } from '../processSelector';
import { WorkflowSelector } from '../workflowSelector';
import HeaderNavRight from './headerNavRight';
import { HeaderUserMenu } from './headerUserMenu';

interface HeaderProps {
  user: StudioUser;
}

export const MainHeader = observer(({ user }: HeaderProps) => {
  const process = useProcess();

  const navigate = useNavigate();
  const currentWorkflowId = useParams()[ParamsList.WorkflowId];

  const isEnvironmentDisplayed: boolean =
    ENV !== EnvironmentName.Release &&
    !!ENV &&
    Object.values(EnvironmentName).includes(ENV);

  const hasBeenGenerated =
    (process?.workflows &&
      process?.workflows.some((wf) => wf.published_id !== null)) ||
    process?.published;

  return (
    <MainHeaderRoot isHomepage={!process}>
      <HeaderLeft>
        <Logo />
        <HeaderDivider />
        <HeaderMenu>
          {process?.name && (
            <HeaderMenuItem>
              <HeaderMenuItemLink
                onClick={() => navigate(`${process.id}/general`)}
              >
                <img
                  src={process?.icon}
                  className="mr-2 w-4 h-4 rounded-full"
                />
                <HeaderMenuItemLabel>{process.name}</HeaderMenuItemLabel>
              </HeaderMenuItemLink>
            </HeaderMenuItem>
          )}
          <HeaderMenuItem>
            <ProcessSelector />
          </HeaderMenuItem>
          {currentWorkflowId && (
            <>
              <HeaderDivider />
              {process ? (
                <HeaderMenuItem>
                  <HeaderMenuItemLink
                    onClick={() =>
                      navigate(`${process.id}/${currentWorkflowId}/editor`)
                    }
                  >
                    <HeaderMenuItemLabel>
                      {
                        process.workflows.find(
                          (wf) => wf.id === currentWorkflowId
                        )?.name
                      }
                    </HeaderMenuItemLabel>
                  </HeaderMenuItemLink>
                </HeaderMenuItem>
              ) : (
                <Skeleton className="w-[100px] h-[20px]" />
              )}
              <HeaderMenuItem>
                <WorkflowSelector />
              </HeaderMenuItem>
            </>
          )}
        </HeaderMenu>
        {isEnvironmentDisplayed && <EnvironmentSpan>{ENV}</EnvironmentSpan>}
      </HeaderLeft>
      <HeaderRight>
        {process !== undefined && (
          <HeaderNavRight
            currentProcessId={process.id}
            hasBeenGenerated={hasBeenGenerated}
            workflowList={process.workflows}
            user={user}
            can_edit={process?.permission.can_edit}
          />
        )}
        <HeaderUserMenu user={user} />
      </HeaderRight>
    </MainHeaderRoot>
  );
});
