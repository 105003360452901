import { StudioUser, SynchronizeDataType, UserSchema } from 'shared';

import { GraphQLService } from '@/services/graphql.service';
import { ACCOUNT_API_URL, ACCOUNT_UI_URL, CLIENT_ID } from '@/utils/constants';
import { parseWithZod } from '@/utils/parseZodSchema';
import { gql } from '@apollo/client';

import {
  AccountMembersResponse,
  AccountMembersResponseSchema,
  DynamicAccountDataOptions,
  OrganizationByNameResponse,
  TeamByRowIdResponse
} from './account.type';

class AccountServiceClass extends GraphQLService {
  static #instance: AccountServiceClass;

  private constructor() {
    super(ACCOUNT_API_URL + '/graphql');
  }

  public static get instance(): AccountServiceClass {
    if (!AccountServiceClass.#instance) {
      AccountServiceClass.#instance = new AccountServiceClass();
    }

    return AccountServiceClass.#instance;
  }

  async getMe(): Promise<Maybe<StudioUser>> {
    const data = await this.query<{ me: StudioUser }>({
      query: gql`
        query getUser {
          me {
            rowId
            avatar
            email
            name
            firstName
            lastName
            isSuperuser
          }
        }
      `,
      variable: {}
    });

    if (!data || !data.me) {
      const pathname = window.location.pathname;
      const isDraftEditor = pathname.endsWith('draft-editor');
      if (isDraftEditor) {
        return;
      }
      redirectToAccountUILogin();
      return;
    }

    const parsedUser = parseWithZod(UserSchema, data?.me, 'ACCNT-QbEIQ');
    if (!parsedUser) {
      redirectToAccountUILogin();
      return;
    }

    const user = parsedUser;
    return user;
  }

  async fetchAccountMembers(
    fetchOptions: DynamicAccountDataOptions
  ): Promise<AccountMembersResponse> {
    const { type, options } = fetchOptions;

    switch (type) {
      case SynchronizeDataType.OrgMembers: {
        const data = await this.query<OrganizationByNameResponse>({
          query: gql`
            query MyQuery {
              organizationByName(name: "${options?.organizationName}") {
                members {
                  nodes {
                    email
                  }
                }
              }
            }
          `,
          variable: {},
          fetchPolicy: 'no-cache'
        });
        if (!data) {
          throw new Error(
            `No data received for organization name: ${options?.organizationName} while fetching organization members`
          );
        }

        const parsedMembers = parseWithZod(
          AccountMembersResponseSchema,
          data.organizationByName,
          'ACCNT-QbEIQ'
        );
        if (!parsedMembers) {
          throw new Error(
            `Failed to parse organization members data for organization name: ${options?.organizationName}`
          );
        }
        return parsedMembers;
      }

      case SynchronizeDataType.TeamMembers: {
        const data = await this.query<TeamByRowIdResponse>({
          query: gql`
            query MyQuery {
              teamByRowId(rowId: "${options?.teamRowId}") {
                members {
                  nodes {
                    email
                  }
                }
              }
            }
          `,
          variable: {},
          fetchPolicy: 'no-cache'
        });
        if (!data) {
          throw new Error(
            `No data received for team row id: ${options?.teamRowId} while fetching team members`
          );
        }

        const parsedMembers = parseWithZod(
          AccountMembersResponseSchema,
          data.teamByRowId,
          'ACCNT-QbEIQ'
        );
        if (!parsedMembers) {
          throw new Error(
            `Failed to parse team members data for team row id: ${options?.teamRowId}`
          );
        }

        return parsedMembers;
      }
    }
  }
}

export const redirectToAccountUILogin = (): void => {
  location.href = `${ACCOUNT_UI_URL}/login?clientId=${CLIENT_ID}&redirectUri=${window.location.href}`;
};

const AccountService = AccountServiceClass.instance;

export default AccountService;
