import { cn } from '@/lib/utils';

export const SettingsContainer = ({
  className,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) => (
  <section
    className={cn(
      'size-full box-border bg-[#fafafa] flex flex-col items-center overflow-auto',
      className
    )}
  >
    {props.children}
  </section>
);

export const SettingsContent = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <section className={'size-full p-8 flex justify-center'} {...props}>
      <div className={cn('size-full max-w-7xl flex flex-col', className)}>
        {props.children}
      </div>
    </section>
  );
};

export const SettingsHeaderContainer = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  return (
    <section className="w-full flex justify-center border-b border-solid border-gray-200 p-8">
      <div className="w-full max-w-7xl">{props.children}</div>
    </section>
  );
};

type SettingHeaderProps = {
  title: string;
  description: string;
};

export const SettingHeader = ({ title, description }: SettingHeaderProps) => {
  return (
    <SettingsHeaderContainer>
      <h1 className="text-2xl mb-2">{title}</h1>
      <p className="text-sm text-gray-500">{description}</p>
    </SettingsHeaderContainer>
  );
};
