import { newError } from '@/services/errors/errors';
import {
  ApolloClient,
  FetchPolicy,
  InMemoryCache,
  TypedDocumentNode,
  createHttpLink
} from '@apollo/client';

export class GraphQLService {
  URL = '';
  client: ApolloClient<object>;

  constructor(URL: string) {
    this.URL = URL;

    const link = createHttpLink({
      uri: URL,
      credentials: 'include'
    });

    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache()
    });
  }

  async query<T = unknown>(data: {
    query: TypedDocumentNode;
    variable: { [key: string]: string | number };
    fetchPolicy?: FetchPolicy;
  }): Promise<Maybe<T>> {
    try {
      const response = await this.client.query<T>(data);

      if (response.errors) {
        throw newError('GRAQL-buAZ7', response.errors);
      }

      return response.data;
    } catch (e: unknown) {
      newError('GRAQL-nczgw', e);
      return;
    }
  }
}
