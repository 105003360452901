export const enum ParamsList {
  TransitionId = 'transitionId',
  ActionId = 'actionId',
  WorkflowId = 'workflowId',
  ProcessId = 'id',
  NotificationId = 'notificationId',
  GenerationId = 'generationId',
  DatabaseId = 'databaseId',
  AnalyticsId = 'analyticsId',
  PodId = 'podId'
}
