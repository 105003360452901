import { Dispatch, SetStateAction, useState } from 'react';

import { Checkbox } from '@components/ui/checkbox';
import {
  DialogCancel,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogSubmit,
  DialogTitle
} from '@components/ui/dialog';

import { newError } from '@/services/errors/errors';

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  nuke: () => Promise<void>;
  isDraft: boolean;
  stats?: {
    actionCount: number;
    transitionCount: number;
    workflowCount: number;
  };
};

export const DeleteDialogContent = ({
  setOpen,
  nuke,
  isDraft,
  stats
}: Props) => {
  const [checks, setChecks] = useState({
    want: isDraft,
    delete: false
  });
  const canDelete = checks.want && checks.delete;

  const onCancel = () => {
    setChecks({ want: isDraft, delete: false });
    setOpen(false);
  };

  return (
    <DialogContent
      onClick={(e) => e.stopPropagation()}
      className="border border-solid border-destructive w-1/2"
    >
      <DialogHeader>
        <DialogTitle>Are you sure about deleting this project?</DialogTitle>
        <DialogDescription>
          Deleting a process is completely irreversible.{' '}
          <strong>All data about the process will be lost.</strong>
        </DialogDescription>
      </DialogHeader>
      <section className="p-4 flex flex-col gap-8">
        {stats && (
          <section className="flex flex-col gap-2">
            <h3 className="text-base font-medium">Your process has:</h3>
            <ul className="list-disc ml-8">
              {stats?.workflowCount > 0 && (
                <li>
                  <strong>{stats.workflowCount}</strong> workflow
                  {stats.workflowCount > 1 ? 's' : ''}
                </li>
              )}
              {stats?.actionCount > 0 && (
                <li>
                  <strong>{stats.actionCount}</strong> action
                  {stats.actionCount > 1 ? 's' : ''}
                </li>
              )}
              {stats?.transitionCount > 0 && (
                <li>
                  <strong>{stats.transitionCount}</strong> transition
                  {stats.transitionCount > 1 ? 's' : ''}
                </li>
              )}
            </ul>
          </section>
        )}
        <section className="flex flex-col gap-2">
          {!isDraft && (
            <div className="flex items-center space-x-3">
              <Checkbox
                id="want"
                checked={checks.want}
                onCheckedChange={(checked) =>
                  setChecks((prev) => ({ ...prev, want: !!checked }))
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setChecks((prev) => ({ ...prev, want: !prev.want }));
                  }
                }}
              />
              <label
                htmlFor="want"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                I want to delete the process in Trace (all traces will be
                deleted has well)
              </label>
            </div>
          )}
          <div className="flex items-center space-x-3">
            <Checkbox
              id="delete-checkbox"
              checked={checks.delete}
              onCheckedChange={(checked) =>
                setChecks((prev) => ({ ...prev, delete: !!checked }))
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setChecks((prev) => ({ ...prev, delete: !prev.delete }));
                }
              }}
            />
            <label
              htmlFor="delete-checkbox"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              I understand the consequences of this action
            </label>
          </div>
        </section>
      </section>
      <DialogFooter className="p-4">
        <DialogCancel onClick={onCancel}>Cancel</DialogCancel>
        <DialogSubmit
          variant="destructive"
          disabled={!canDelete}
          onClick={(e) => {
            if (!canDelete) {
              e.preventDefault();
              e.stopPropagation();
              return;
            }
            nuke().catch((e) => {
              newError('NUKE-1', e, true);
            });
            setOpen(false);
          }}
        >
          Delete the project 💥
        </DialogSubmit>
      </DialogFooter>
    </DialogContent>
  );
};
