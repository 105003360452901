import { ChangeEvent, useEffect, useState } from 'react';

import { AlertTriangle, Book, ChevronLeft, Trash2 } from 'lucide-react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { SettingsContent } from '@components/ui/settings';

import { CustomElement } from '@pages/Settings/notifications/editor/slate';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import ActionButton from '@atoms/button';
import { InputField } from '@atoms/input';

import { newError } from '@/services/errors/errors';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Sheet,
  Typography
} from '@mui/joy';

import SlateEditor from './SlateEditor';
import { EditBody } from './style';

const DEFAULT_TEXT: CustomElement[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];

const Editor = () => {
  const navigate = useNavigate();
  const process = useProcess();
  const { notificationId } = useParams();
  const { notificationTemplateStore } = useStores();
  const template = notificationId
    ? notificationTemplateStore.get(notificationId)
    : undefined;
  const [newTitle, setNewTitle] = useState(template?.title ?? '');
  const [newText, setNewText] = useState<CustomElement[]>(
    toJS(template?.text) ?? DEFAULT_TEXT
  );
  const [isLoading, setIsLoading] = useState(true);
  const [hasChange, setHasChange] = useState(
    JSON.stringify(newText) === JSON.stringify(template?.text) &&
      newTitle !== template?.title
  );
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    // To disable the button after a save otherwise it does not notice that hasChange is true
    const equalsCheck =
      JSON.stringify(newText) === JSON.stringify(template?.text);
    setHasChange(!equalsCheck || newTitle !== template?.title);
  }, [newText, newTitle, template?.text, template?.title]);

  useEffect(() => {
    // Show a loader to wait for data to load and set it in SlateEditor to avoid crash on page refresh
    if (!process) {
      if (template) {
        setNewTitle(template.title);
        setNewText(toJS(template.text));
        setHasChange(false);
      }
      setIsLoading(false);
    }
  }, [template, process?.loading, process]);

  const handleTitleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setNewTitle(evt.target.value);
  };

  const handleTextChange = (value: CustomElement[]) => {
    setNewText(value);
  };

  const saveTemplate = () => {
    if (newTitle && newText) {
      if (template) {
        template.title = newTitle;
        template.text = newText;
        template.updatedAt = new Date();
        notificationTemplateStore
          .update(template.id)
          .then(() => {
            toast.success('Template updated');
          })
          .catch(() => {
            setHasChange(true);
          });
      } else {
        notificationTemplateStore
          .createNewNotificationTemplate(newTitle, newText, process?.id ?? '')
          .then(() => {
            toast.success('New template created');
          })
          .catch((error) => {
            newError('TEMPLATE-1000', error, true);
          })
          .finally(() => {
            navigate(`..`);
          });
      }
      setHasChange(false);
    }
  };

  const deleteTemplate = () => {
    if (template) {
      notificationTemplateStore
        .delete(template.id)
        .then((isTemplateDeleted) => {
          if (isTemplateDeleted) {
            toast.success('Template deleted');
          }
        })
        .catch((error) => {
          newError('TEMPLATE-2000', error, true);
        })
        .finally(() => {
          navigate(`..`);
        });
    }
  };

  return (
    <>
      <SettingsContent>
        <div className="flex gap-3 justify-start">
          <Link style={{ padding: '2px 0' }} to="..">
            <div className="flex h-full pt-[2px]">
              <ChevronLeft />
            </div>
          </Link>
          <div className="flex gap-1 flex-col justify-center">
            Edit template
            <p className="text-sm text-gray-400">
              Create and change the template of your notification. What you see
              is what you get in the email.
            </p>
          </div>
        </div>
        <div>
          {isLoading ? (
            <Sheet sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color="primary" size="lg" />
            </Sheet>
          ) : (
            <EditBody variant="outlined">
              <Book />
              <div className="flex flex-col justify-center pr-6 w-full gap-5">
                <Typography level="title-lg">
                  {template?.title ?? 'New template'}
                </Typography>
                <InputField
                  label="Template title"
                  value={newTitle || ''}
                  onChange={handleTitleChange}
                />
                <div>
                  <SlateEditor onChange={handleTextChange} value={newText} />
                </div>
                <div className="flex justify-end gap-3 mb-3">
                  <ActionButton
                    value={template ? 'Delete' : 'Abort'}
                    onClick={() => setOpenConfirmModal(true)}
                    color="danger"
                  />
                  <ActionButton
                    value="Save"
                    onClick={saveTemplate}
                    disabled={!newText || !newTitle || !hasChange}
                    loading={isLoading}
                  />
                </div>
              </div>
            </EditBody>
          )}
        </div>
      </SettingsContent>

      <Modal open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <AlertTriangle />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            Are you sure you want to {template ? 'delete' : 'abort creation to'}{' '}
            your template?
          </DialogContent>
          <DialogActions>
            <ActionButton
              value={template ? 'Delete' : 'Abort'}
              onClick={deleteTemplate}
              color="danger"
              endDecorator={<Trash2 size={18} />}
            />
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setOpenConfirmModal(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default observer(Editor);
