import { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '@components/ui/accordion';
import {
  SettingsContainer,
  SettingsContent,
  SettingsHeaderContainer
} from '@components/ui/settings';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';
import { useWorkflow } from '@hooks/useWorkflow';

import { LoaderBox } from '@/components/ui/loader';
import { ParamsList } from '@/routes/routes.types';
import { CircularProgress } from '@mui/joy';
import { LogViewer } from '@patternfly/react-log-viewer';

import AnalyticsCard, { podStatusToProgress } from './AnalyticsCard';

export const POLL_INTERVAL = 5000;

const AnalyticsDetails = () => {
  const process = useProcess();
  const { workflow } = useWorkflow();
  const { analyticsStore } = useStores();
  const analyticsId = useParams<string>()[ParamsList.AnalyticsId];
  const podId = useParams<string>()[ParamsList.PodId];

  const analytics = analyticsStore.get(analyticsId);
  const pod = analytics?.pods?.find((pod) => pod.id === podId);

  const formatLogsForDisplay = (
    logs?: string | Record<string, unknown> | null
  ) => {
    if (!logs) return [];

    try {
      const parsedLogs =
        typeof logs === 'string'
          ? (JSON.parse(logs) as Record<string, unknown> | string)
          : logs;

      if (typeof parsedLogs === 'string') {
        return parsedLogs;
      }
      if (
        typeof parsedLogs === 'object' &&
        parsedLogs !== null &&
        'stack' in parsedLogs
      ) {
        return String(parsedLogs.stack);
      }
      // If logs is an object and not empty
      if (
        typeof parsedLogs === 'object' &&
        Object.keys(parsedLogs).length > 0
      ) {
        return JSON.stringify(parsedLogs, null, 2).split('\n');
      }

      return [];
    } catch (_error) {
      return logs;
    }
  };

  useEffect(() => {
    if (!analytics || !analytics.shouldBePolled || !workflow?.published_id)
      return;
    analyticsStore.startSinglePolling(analytics, workflow);
    return () => analyticsStore.stopPolling(analytics.id);
  }, [analytics, workflow, analyticsStore]);

  if (!analyticsId) {
    return <div>Analytics ID not found</div>;
  }

  if (!analytics || !pod) {
    if (!workflow?.published_id) return;

    void analyticsStore
      .fetchAndParseAnalytics(analyticsId, workflow?.published_id)
      .then((parsedAnalytics) => {
        if (!parsedAnalytics) {
          return;
        }
        return analyticsStore.loadEtlJob(parsedAnalytics);
      });
  }

  if (!process || process.isSmallProcess) {
    return (
      <LoaderBox>
        <CircularProgress />
      </LoaderBox>
    );
  }

  if (!analytics || !pod) {
    return <div className="container m-2">Analytics not found</div>;
  }
  return (
    <SettingsContainer>
      <SettingsHeaderContainer>
        <AnalyticsCard analytics={analytics} pod={pod} />
      </SettingsHeaderContainer>
      <SettingsContent>
        <h1 className="mb-6 font-medium">Analytics details</h1>

        <Accordion type="single" collapsible>
          <AccordionItem
            value="item-1"
            className="rounded-md border border-solid border-zinc-300 bg-white shadow-md bg-clip-border text-sm font-normal"
          >
            <AccordionTrigger className="px-4">
              <div className="flex items-center">
                <div className="mr-2">Logs</div>
                {podStatusToProgress(pod.status)}
              </div>
            </AccordionTrigger>
            <AccordionContent className="bg-zinc-50 h-72 rounded-b-md border-t border-solid border-zinc-300 font-mono pl-1">
              <LogViewer
                data={formatLogsForDisplay(pod.logs)}
                height="100%"
                hasLineNumbers={true}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </SettingsContent>
    </SettingsContainer>
  );
};

export default observer(AnalyticsDetails);
