import { CustomCellEditorProps } from 'ag-grid-react';
import { AtomReference, RowData } from 'shared';

import { MultiSelect } from '@components/database/multi-select';

import { DatabaseModel } from '@models/database.model';

import { AtomModel } from '@/mobx/models/atom.model';
import { newError } from '@/services/errors/errors';

type AgGridMultiSelectEditorProps = CustomCellEditorProps<
  RowData,
  AtomReference[] | string[]
> & {
  multiSelectOptions?: string[];
  databaseReferenced?: DatabaseModel;
};

export type SelectorOption = {
  label: string;
  value: string;
};

export const AgGridMultiSelectEditor = ({
  value,
  data,
  onValueChange,
  databaseReferenced,
  multiSelectOptions
}: AgGridMultiSelectEditorProps) => {
  const _rowAtomId = data.rowAtomId;

  const finalSelectorOptions: SelectorOption[] = [];
  const rowAtomsOfDTRReferenced: AtomModel<RowData>[] = [];

  if (databaseReferenced && multiSelectOptions) {
    newError(
      'AGMSE-5gAqW',
      `Database referenced and options are both provided inside MultiSelectEditor, 
      this is not allowed`
    );
    return null;
  }

  if (!databaseReferenced && !multiSelectOptions) {
    newError(
      'AGMSE-57yDo',
      `Database referenced and options are not provided inside MultiSelectEditor, 
      this is not allowed`
    );
    return null;
  }

  if (databaseReferenced) {
    const primaryKeyColumnOfDTRReferenced =
      databaseReferenced.getPrimaryKeyColumn();

    if (!primaryKeyColumnOfDTRReferenced) {
      newError(
        'AGMSE-MlSWd',
        `Primary key column of DTR ${databaseReferenced.id} 
        not found in Multi select editor, 
        this column will not be rendered`
      );
      return null;
    }

    rowAtomsOfDTRReferenced.push(...databaseReferenced.getRowAtoms());

    finalSelectorOptions.push(
      ...rowAtomsOfDTRReferenced.map((rowAtom) => ({
        label:
          rowAtom.data[primaryKeyColumnOfDTRReferenced.field]?.toString() ?? '',
        value: rowAtom.id
      }))
    );
  }

  if (multiSelectOptions) {
    finalSelectorOptions.push(
      ...multiSelectOptions.map((option) => ({
        label: option,
        value: option
      }))
    );
  }

  const onMultiSelectValuesChange = (values: string[]) => {
    if (databaseReferenced) {
      const correspondingRowAtoms = rowAtomsOfDTRReferenced.filter((rowAtom) =>
        values.includes(rowAtom.id)
      );

      const correspondingReferences: AtomReference[] =
        correspondingRowAtoms.map((rowAtom) => ({
          dataItemId: rowAtom.id,
          blockType: 'Row',
          sourceId: databaseReferenced.id
        }));
      onValueChange(correspondingReferences);
    }

    if (multiSelectOptions) {
      onValueChange(values);
    }
  };

  const getCurrentlySelectedValues = () => {
    return value?.map((val: string | AtomReference) => {
      if (typeof val === 'string') return val;
      return val.dataItemId;
    });
  };

  return (
    <MultiSelect
      options={finalSelectorOptions}
      onValueChange={onMultiSelectValuesChange}
      defaultValue={getCurrentlySelectedValues()}
      variant="pastelBlue"
      maxCount={1}
    />
  );
};
