import { useState } from 'react';

import { LucideIcon, Variable, WandSparkles } from 'lucide-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { DNDBlock } from 'shared';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { MonacoEditor } from '@components/monaco/monacoEditor.code';

import { useAtom } from '@hooks/useAtom';
import useProcess from '@hooks/useProcess';
import useStore from '@hooks/useStore';

import ActionButton from '@atoms/button';
import { GlassModal } from '@atoms/glassModal';

import { ParamsList } from '@/routes/routes.types';
import EndControls from '@library/condition//endControls';
import { OnChange } from '@monaco-editor/react';
import { FormControl, FormLabel } from '@mui/joy';

import { initialCodeConditionData } from './codeCondition.data';

export const CodeConditionIcon: LucideIcon = Variable;

const CodeCondition = (block: DNDBlock) => {
  const [isMonacoModalOpen, setIsMonacoModalOpen] = useState(false);
  const { codeStore } = useStore();
  const process = useProcess();
  const transitionId = useParams()[ParamsList.TransitionId] as string;

  const atom = useAtom({
    type: 'Code Condition',
    initialData: initialCodeConditionData,
    sourceId: block.id,
    parentId: transitionId,
    parentKind: 'transition'
  });

  if (!process) return null;

  if (!atom || !transitionId) return null;

  const onCodeChange: OnChange = (value) => {
    if (!value) return;
    atom.data.code = value;
  };

  return (
    <BlockBase
      dndBlock={block}
      icon={CodeConditionIcon}
      endControls={<EndControls dndBlock={block} />}
      hasTitle={false}
    >
      <Column $width="200px">
        <FormControl size="sm" required>
          <FormLabel>Condition code</FormLabel>
          <ActionButton
            onClick={() => setIsMonacoModalOpen(true)}
            value="Edit condition's code"
            color="neutral"
            variant="soft"
            startDecorator={<WandSparkles size={18} />}
          />
        </FormControl>
      </Column>
      <GlassModal
        open={isMonacoModalOpen}
        onClose={() => setIsMonacoModalOpen(false)}
        header={{
          title: `Code condition editor`,
          description: 'Customize the code of your condition'
        }}
        className="h-full w-full px-4"
      >
        <section className="-ml-4 h-full w-full overflow-hidden">
          <MonacoEditor
            modelId={atom.id}
            preCode={codeStore.computePreCode()}
            code={atom.data.code}
            onCodeChange={onCodeChange}
          />
        </section>
      </GlassModal>
    </BlockBase>
  );
};

export default observer(CodeCondition);
