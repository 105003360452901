import { AlignJustify, LucideIcon } from 'lucide-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import {
  AtomReference,
  AtomType,
  DNDBlock,
  Operator,
  OperatorSchema,
  ResolvedVariableType,
  Suggestion,
  SuggestionSource
} from 'shared';

import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';

import { useAtom } from '@hooks/useAtom';
import useStores from '@hooks/useStore';

import { AtomModel } from '@models/atom.model';

import { VariableSelector } from '@atoms/dataSelectors/variableSelector/variableSelector';
import { InputField } from '@atoms/input';
import { MultiSelectField } from '@atoms/multiSelect';
import { SelectField } from '@atoms/select';

import { ParamsList } from '@/routes/routes.types';
import { newError } from '@/services/errors/errors';
import { isValidType } from '@/utils/parseZodSchema';
import { Autocomplete, FormControl, Option } from '@mui/joy';

import EndControls from '../endControls';
import { operatorOptions } from '../utils';
import { initialConditionData } from './condition.data';

export const ConditionIcon: LucideIcon = AlignJustify;

const Condition = (block: DNDBlock) => {
  let selectedVariable: Maybe<AtomModel<{ title: string }>>;

  const { atomStore } = useStores();

  const transitionId = useParams()[ParamsList.TransitionId] as string;

  const atom = useAtom({
    type: 'Condition',
    initialData: initialConditionData,
    sourceId: block.id,
    parentId: transitionId,
    parentKind: 'transition'
  });

  if (!atom) return null;

  if (atom.data.selectedVariable) {
    const tmpSelectedVariable = atomStore.getAtomById(
      atom.data.selectedVariable.dataItemId,
      atom.data.selectedVariable.blockType as AtomType
    );

    if (tmpSelectedVariable instanceof Error) {
      newError('COND-4029f', "Couldn't find selected variable", false);
    } else {
      selectedVariable = tmpSelectedVariable as Maybe<
        AtomModel<{ title: string }>
      >;
    }
  }

  const initializeCondition = (
    resolvedVariableType: Maybe<ResolvedVariableType>
  ) => {
    if (!atom?.data) return;
    if (!resolvedVariableType) return;
    atom.data.condition = {
      value: null,
      operator: operatorOptions[resolvedVariableType][0].value
    };
  };

  const getSuggestionFromDatabases = () => {
    const databasesSuggestions: Suggestion[] = [];

    // todo
    // const allDatabases = [];

    // allDatabases.forEach((database) => {
    //   const databaseValues = database.data.values;

    //   databaseValues.forEach((value) => {
    //     const newSuggestion: Suggestion = {
    //       label: value.name,
    //       groupeBy: database.data.title,
    //       source: SuggestionSource.Database
    //     };
    //     databasesSuggestions.push(newSuggestion);
    //   });
    // });

    return databasesSuggestions;
  };

  const handleSuggestions = () => {
    if (!atom) return;

    if (!atom.data.condition) {
      initializeCondition(selectedVariable?.variableInfo?.resolvedType);
    }

    if (!selectedVariable) return;
    const variableSuggestions = selectedVariable.possibleValues.map(
      (possibleValue) => {
        return {
          label: possibleValue,
          groupeBy: `${selectedVariable?.data.title} - data source`,
          source: SuggestionSource.Variable
        };
      }
    );

    const newSuggestions: Suggestion[] = [];

    newSuggestions.push(...variableSuggestions);

    const databasesSuggestions = getSuggestionFromDatabases();
    newSuggestions.push(...databasesSuggestions);

    return newSuggestions;
  };

  const suggestions: Suggestion[] = handleSuggestions() ?? [];

  if (atom.data.condition && !atom.data.condition.operator) {
    const resolvedType = selectedVariable?.variableInfo?.resolvedType;
    if (!resolvedType) return;
    atom.data.condition.operator = operatorOptions[resolvedType][0].value;
  }

  const onVariableSelected = (
    seletedVariableReference: Maybe<AtomReference>
  ) => {
    if (!seletedVariableReference) {
      newError(
        'COND-8a3c2',
        'onVariableSelected(): selectedVariable is undefined'
      );
      return;
    }

    atom.data.selectedVariable = seletedVariableReference;
    const selectedVariable = atomStore.getAtomById_Unsafe(
      seletedVariableReference.dataItemId
    );

    if (!selectedVariable) {
      newError(
        'COND-3c108',
        'onVariableSelected(): selectedVariable is undefined'
      );
      return;
    }

    const blockAtom = atomStore.get(block.atomId);

    if (!blockAtom) {
      newError('COND-6c8c4', 'blockAtom is undefined');
      return;
    }

    blockAtom.addReferenceToAtom(selectedVariable.id);
  };

  const onRightSideValueChange = (value: string) => {
    if (!atom.data.condition) {
      newError('COND-0c840', 'dataItem.data.condition is undefined');
      return;
    }
    atom.data.condition.value = value;
  };

  const onOperatorChange = (value: string | null): void => {
    if (!isValidType(OperatorSchema, value, 'COND-d569c')) {
      return;
    }

    if (!atom.data.condition) {
      newError('COND-6acb3', 'dataItem.data.condition is undefined');
      return;
    }

    atom.data.condition.operator = value;
  };

  const renderOperatorSelect = (computedType: ResolvedVariableType) => {
    return (
      <Column $width="auto" $gap="0px">
        <SelectField
          label=""
          disabled={!atom?.data.selectedVariable?.dataItemId}
          value={
            atom.data.condition?.operator ??
            operatorOptions[computedType][0]?.value
          }
          sx={{ minWidth: '50px' }}
          onChange={(_, value) => onOperatorChange(value)}
        >
          {operatorOptions[computedType]?.map(({ value, label }, index) => (
            <Option key={index} value={value}>
              {label}
            </Option>
          ))}
        </SelectField>
      </Column>
    );
  };

  const renderAutocompleteInput = () => {
    return (
      <Column $width="200px" $gap="0px">
        <FormControl size="sm" disabled={!atom.data.condition?.operator}>
          <Autocomplete
            freeSolo
            placeholder="Aa"
            options={suggestions}
            getOptionLabel={(option: string | Suggestion) =>
              typeof option !== 'string' ? option.label : option
            }
            groupBy={(option) => option.groupeBy}
            inputValue={
              typeof atom.data.condition?.value === 'string'
                ? atom.data.condition.value
                : ''
            }
            onInputChange={(_, value) => onRightSideValueChange(value)}
          />
        </FormControl>
      </Column>
    );
  };

  const renderMultiSelect = () => {
    return (
      <Column $width="200px" $gap="0px">
        <MultiSelectField
          onChange={(_, selectedValues) => {
            if (!atom.data.condition) return;
            atom.data.condition.value = selectedValues;
          }}
          value={(atom.data.condition?.value as string[]) ?? []}
        >
          {suggestions
            .filter((s) => s.source === SuggestionSource.Variable)
            .map((option, index) => (
              <Option key={index} value={option.label}>
                {option.label}
              </Option>
            ))}
        </MultiSelectField>
      </Column>
    );
  };

  const renderNumberInput = () => {
    return (
      <Column $width="200px" $gap="0px">
        <InputField
          placeholder={`ex: ${Math.floor(Math.random() * 100) + 1}`}
          type="number"
          value={
            typeof atom.data.condition?.value === 'string'
              ? atom.data.condition.value
              : ''
          }
          onChange={(event) => {
            onRightSideValueChange(event.target.value);
          }}
        ></InputField>
      </Column>
    );
  };

  const renderRightSideValueSelect = (
    computedType: ResolvedVariableType,
    operator: Maybe<Operator>
  ) => {
    if (!operator) return <></>;

    switch (operator) {
      case 'allOf':
      case 'oneOf':
      case 'noneOf':
        return renderMultiSelect();
      case 'defined':
      case 'isTrue':
      case 'isFalse':
        return <></>;
      default: {
        if (computedType === ResolvedVariableType.Number) {
          return renderNumberInput();
        }
        return renderAutocompleteInput();
      }
    }
  };

  const renderRightSide = () => {
    const resolvedVariableType: Maybe<ResolvedVariableType> =
      selectedVariable?.variableInfo?.resolvedType;
    if (!resolvedVariableType) {
      newError(
        'COND-52c34',
        `renderRightSide(): resolvedVariableType is undefined for variable id: "${atom.data.selectedVariable?.dataItemId}"`
      );
      return;
    }

    return (
      <>
        {renderOperatorSelect(resolvedVariableType)}
        {renderRightSideValueSelect(
          resolvedVariableType,
          atom.data.condition?.operator
        )}
      </>
    );
  };

  return (
    <BlockBase
      dndBlock={block}
      isCondition
      icon={ConditionIcon}
      endControls={<EndControls dndBlock={block} />}
      hasTitle={false}
    >
      <Column $width="200px" $gap="0px">
        <FormControl size="sm">
          <VariableSelector
            onSelected={onVariableSelected}
            value={selectedVariable?.data?.title}
          />
        </FormControl>
      </Column>

      {selectedVariable && renderRightSide()}
    </BlockBase>
  );
};

export default observer(Condition);
