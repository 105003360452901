import { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

type Props = PropsWithChildren<{ className?: string }>;

export const DefaultPage = (props: Props) => (
  <div
    className={cn(
      'p-20 pt-8 size-full box-border bg-[#fafafa] flex flex-col items-center overflow-auto',
      props.className
    )}
  >
    {props.children}
  </div>
);
