import { makeObservable, observable, reaction } from 'mobx';
import { GlobalVariableData } from 'shared';

import Model, { ModelError } from '@models/base/base.model';

import BaseStore from '@stores/base/base.store';
import { PageStore } from '@stores/base/page.store';
import { WorkflowStore } from '@stores/workflow.store';

import { newError } from '@/services/errors/errors';
import { DndLibrary } from '@library';

import { ActionModel } from './action/action.model';
import { AnalyticsModel } from './analytics.model';
import { AtomModel } from './atom.model';
import { BpmnModel } from './bpmn.model';
import { DNDModel } from './dnd.model';

type CreateWorkflowDTO = {
  id: Model['id'];
  workflowXML: string;
  published_id: string | null;
  name: string;
  traceInfoDNDId?: DNDModel['id'];
  traceOverviewDndId?: DNDModel['id'];
  globalVariablesDndId?: DNDModel['id'];
  actionIds?: ActionModel['id'][];
  id_environment_variable_name?: string;
  updatedAt: string;
};

export class WorkflowModel extends Model {
  public published_id: string | null;
  public workflowXML: string;
  public name: string;
  public id_environment_variable_name: string;
  public updatedAt: string;
  bpmn: Maybe<BpmnModel>;

  public traceInfoDndId: DNDModel['id'];
  private traceOverviewDndId: DNDModel['id'];
  private globalVariablesDndId: DNDModel['id'];
  private actionIds: ActionModel['id'][];
  private analyticsPageStore = new PageStore<AnalyticsModel>({
    totalNumberOfItemsInDB: 0,
    itemsPerPage: 7,
    baseStore: this.store.rootStore.analyticsStore
  });
  constructor(
    store: BaseStore<WorkflowModel>,
    {
      actionIds,
      globalVariablesDndId,
      id,
      workflowXML,
      published_id,
      traceInfoDNDId,
      traceOverviewDndId,
      name,
      id_environment_variable_name,
      updatedAt
    }: CreateWorkflowDTO,
    loading: boolean = false
  ) {
    super(store, id, loading);

    this.published_id = published_id;

    this.name = name;
    this.traceInfoDndId = traceInfoDNDId || '';
    this.traceOverviewDndId = traceOverviewDndId || '';
    this.globalVariablesDndId = globalVariablesDndId || '';
    this.actionIds = actionIds || [];
    this.workflowXML = workflowXML;
    this.id_environment_variable_name = id_environment_variable_name || '';
    this.updatedAt = updatedAt;
    makeObservable(this, {
      published_id: observable,
      workflowXML: observable,
      name: observable,
      updatedAt: observable
    });

    reaction(
      () => this.name,
      () => {
        this.store.update(this.id).catch((e) => {
          newError(
            'WORKF-f5b6a',
            {
              id: this.id,
              error: JSON.stringify(e),
              message: 'Error updated workflow name'
            },
            false
          );
        });
      },
      {
        delay: 1000
      }
    );

    reaction(
      () => this.id_environment_variable_name,
      () => {
        this.store.update(this.id).catch((e) => {
          newError(
            'WORKF-f5b6a',
            {
              id: this.id,
              error: JSON.stringify(e),
              message: 'Error updated workflow id_environment_variable_name'
            },
            false
          );
        });
      }
    );
  }

  setCurrent() {
    (this.store as WorkflowStore).setCurrentWorkflow(this);
  }

  get traceInfoDnd(): Maybe<DNDModel<DndLibrary.TraceInfo>> {
    return this.store.rootStore.dndStore.get(this.traceInfoDndId) as Maybe<
      DNDModel<DndLibrary.TraceInfo>
    >;
  }

  get traceOverviewDnd(): Maybe<DNDModel<DndLibrary.TraceOverview>> {
    return this.store.rootStore.dndStore.get(this.traceOverviewDndId) as Maybe<
      DNDModel<DndLibrary.TraceOverview>
    >;
  }

  get globalVariablesDnd(): Maybe<DNDModel<DndLibrary.GlobalVariables>> {
    return this.store.rootStore.dndStore.get(
      this.globalVariablesDndId
    ) as Maybe<DNDModel<DndLibrary.GlobalVariables>>;
  }

  get globalVariableAtoms(): AtomModel<GlobalVariableData>[] {
    const atomStore = this.store.rootStore.atomStore;
    const globalVariables = atomStore
      .toArray()
      .filter(
        (atom) =>
          atom.metaInfo.source.parentKind === 'globalVariables' &&
          atom.metaInfo.source.elementId === this.id
      ) as AtomModel<GlobalVariableData>[];
    return globalVariables;
  }

  get analyticPageStore(): PageStore<AnalyticsModel> {
    return this.analyticsPageStore;
  }

  get actions(): Maybe<ActionModel>[] {
    return this.actionIds.map((actionId) => {
      const action = this.store.rootStore.actionStore.get(actionId);
      if (!action) {
        newError(
          'WORKF-qRkfm',
          `Workflow Model: Action not found in store for actionId ${actionId}}`
        );
      }
      return action;
    });
  }

  get toJSON() {
    return {
      metadata: {
        name: this.name,
        id_environment_variable_name: this.id_environment_variable_name
      }
    };
  }

  get errors(): ModelError[] {
    return [];
  }
}
