import { ColDef } from 'ag-grid-community';
import { makeObservable, observable, reaction, toJS } from 'mobx';
import { ResolvedVariableType } from 'shared';
import { AtomReference } from 'shared/src/atom/atomReference.schema';
import { RowData } from 'shared/src/atom/sources/database/row.atom';
import {
  Column,
  Columns,
  DatabaseContext,
  SyncedData,
  SynchronizeOptions,
  SynchronizeOptionsType,
  SynchronizeSource
} from 'shared/src/database/database.schema';
import { TraceKey } from 'shared/src/other/traceKey.schema';

import { AgGridMultiSelectEditor } from '@pages/Database/grid/customEditors/multi-select.editor';
import { GlobalInnerHeaderComponent } from '@pages/Database/grid/customHeaders/global.header';
import { BadgeRenderer } from '@pages/Database/grid/customRenderers/badge.renderer';

import DatabaseStore from '@stores/database.store';

import AccountService from '@/services/account/account.service';
import { newError } from '@/services/errors/errors';
import { AtomNotDeletableError } from '@/services/errors/studio-errors';
import { globalColumnValueSetter } from '@/types/database.types';
import nanoID from '@/utils/nanoID';

import { AtomModel } from './atom.model';
import { ModelError } from './base/base.model';
import { BaseModelWithTraceKey } from './base/baseWithKey.model';

export interface NewRowOptions {
  rowData: RowData;
}

export class DatabaseModel extends BaseModelWithTraceKey {
  constructor(
    store: DatabaseStore,
    id: string,
    private traceKeyDTO: TraceKey,
    private name: string,
    private columns: Columns,
    private rowReferences: AtomReference[],
    private processId: string,
    private created_at: string,
    private updated_at: string,
    private deleted_at: Nullable<string>,
    private context: DatabaseContext
  ) {
    const isLoading = false;
    super(store, id, traceKeyDTO, isLoading);

    makeObservable<DatabaseModel, 'name' | 'columns' | 'rowReferences'>(this, {
      name: observable,
      columns: observable,
      rowReferences: observable
    });

    reaction(
      () => this.name,
      () => {
        this.store.update(this.id).catch((error: Error) => {
          newError('DBMD-wmtOk', error, true);
        });
      }
    );

    reaction(
      () => toJS(this.rowReferences),
      () => {
        this.store.update(this.id).catch((error: Error) => {
          newError('DBMD-N826U', error, true);
        });
      }
    );

    reaction(
      () => toJS(this.columns),
      () => {
        this.store.update(this.id).catch((error: Error) => {
          newError('DBMD-wUqBU', error, true);
        });
      }
    );
  }

  /* ------------------------ Class properties getters ------------------------ */
  public get getRowReferences(): AtomReference[] {
    return this.rowReferences;
  }

  public get getColumns(): Columns {
    return this.columns;
  }

  public get getName(): string {
    return this.name;
  }

  public get getContext(): DatabaseContext {
    return this.context;
  }

  /* ----------------------------- Custom getters ----------------------------- */
  public getPrimaryKeyColumn(): Column | undefined {
    const primaryKeyColumn = this.columns.find(
      (column) => column.context != undefined && column.context.isPrimaryKey
    );

    if (!primaryKeyColumn) {
      newError(
        'DBMD-gcUWX',
        `Primary key column not found in database: ${this.id}, 
        will not render the grid`
      );
      return;
    }

    return primaryKeyColumn;
  }

  public getGridColumnDefinitions(): ColDef[] {
    const columnsDefintions: ColDef[] = [];

    const primaryKeyColumn = this.getPrimaryKeyColumn();

    if (!primaryKeyColumn) {
      return columnsDefintions;
    }

    for (const column of this.columns) {
      const isColumnReferenceToOtherDTR =
        column.context?.reference !== undefined;
      const isPrimaryKey = primaryKeyColumn.field === column.field;

      const baseColumnDefinition: ColDef = {
        field: column.field,
        cellDataType: column.cellDataType,
        context: column.context,
        valueSetter: globalColumnValueSetter,
        sortable: column.context?.synchronizeOptions ? false : true
      };

      if (column.context?.synchronizeOptions) {
        baseColumnDefinition.editable = false;
      }

      const needsCustomHeader =
        column.context?.synchronizeOptions || isPrimaryKey;

      if (needsCustomHeader) {
        baseColumnDefinition.headerComponentParams = {
          innerHeaderComponent: GlobalInnerHeaderComponent,
          innerHeaderComponentParams: {
            onSyncClick: column.context?.synchronizeOptions
              ? this.getOnSyncClickFunc(column.context.synchronizeOptions)
              : undefined,
            columnContext: column.context
          }
        };
      }

      if (isPrimaryKey && isColumnReferenceToOtherDTR) {
        newError(
          'DBMD-CWDDH',
          `Primary key column cannot be a reference in database: 
          ${this.id}, this column will not be rendered`
        );
        throw new Error(
          `Primary key column cannot be a reference in database: 
          ${this.id}`
        );
      }

      if (column.context?.multiSelectOptions && isColumnReferenceToOtherDTR) {
        newError(
          'DBMD-gcUWX',
          `Multi select options and reference to other DTR are mutually exclusive in database, column: 
          ${column.field} in DTR ${this.id}, this column will not be rendered`
        );
        throw new Error(
          `Multi select options and reference to other DTR are mutually exclusive in database, column: 
          ${column.field} in DTR ${this.id}, this column will not be rendered`
        );
      }

      if (column.context?.multiSelectOptions) {
        baseColumnDefinition.cellEditor = AgGridMultiSelectEditor;
        baseColumnDefinition.cellRenderer = BadgeRenderer;
        baseColumnDefinition.cellEditorParams = {
          multiSelectOptions: column.context.multiSelectOptions
        };
      }

      if (isColumnReferenceToOtherDTR) {
        const databaseReferenced = this.store.get(
          column.context?.reference?.databaseId
        );

        if (!databaseReferenced) {
          newError(
            'DBMD-hk_I6',
            `Database referenced by 
            column ${column.field} in DTR ${this.id} not found in store`
          );
          throw new Error(
            `Database referenced by 
            column ${column.field} in DTR ${this.id} not found in store`
          );
        }

        baseColumnDefinition.cellEditor = AgGridMultiSelectEditor;
        baseColumnDefinition.cellRenderer = BadgeRenderer;
        baseColumnDefinition.cellEditorParams = {
          databaseReferenced
        };
      }

      columnsDefintions.push(baseColumnDefinition);
    }

    return columnsDefintions;
  }

  public hasOneSynchronizeColumn(): boolean {
    return this.columns.some((column) => column.context?.synchronizeOptions);
  }

  public getRowAtoms(): AtomModel<RowData>[] {
    const rowAtoms: AtomModel<RowData>[] = [];

    for (const rowReference of this.rowReferences) {
      const rowAtom = this.store.rootStore.atomStore.getAtomById<RowData>(
        rowReference.dataItemId,
        'Row'
      );

      if (!rowAtom || rowAtom instanceof Error) {
        newError(
          'DBMD-08Mzj',
          `Row atom ${rowReference.dataItemId} not found in atom store while getting row references for database ${this.id}`
        );
        throw new Error(
          `Row atom ${rowReference.dataItemId} not found in atom store while getting row references for database ${this.id}`
        );
      }

      rowAtoms.push(rowAtom);
    }

    return rowAtoms;
  }

  private getOnSyncClickFunc(
    synchronizeOptions: SynchronizeOptions
  ): () => Promise<void> {
    const { type, syncedData } = synchronizeOptions;

    switch (type) {
      case SynchronizeOptionsType.Fetch:
        return this.getOnFetchClickFunc(syncedData);
    }
  }

  private getOnFetchClickFunc(syncedData: SyncedData): () => Promise<void> {
    switch (syncedData.source) {
      case SynchronizeSource.StratumnAccountMembers:
        return this.getAndHandleAccountMembers(syncedData);
    }
  }

  private getAndHandleAccountMembers(
    syncedData: SyncedData
  ): () => Promise<void> {
    return async () => {
      const members = await AccountService.fetchAccountMembers({
        type: syncedData.type,
        options: syncedData.options
      });

      const membersEmails = members.members.nodes.map((node) => node.email);

      const existingMemberEmails = this.getRowAtoms().map(
        (rowAtom) => rowAtom.data.user
      ) as string[];
      const newMemberEmails = membersEmails.filter(
        (email) => !existingMemberEmails.includes(email)
      );

      for (const email of newMemberEmails) {
        const rowAtomId = nanoID();
        const newRowAtom = this.store.rootStore.atomStore.createAtom(
          rowAtomId,
          'Row',
          {
            rowAtomId,
            user: email
          },
          {
            source: {
              parentId: this.id,
              elementId: this.id,
              parentKind: 'database'
            }
          },
          this.processId,
          {
            resolvedType: ResolvedVariableType.Row
          }
        );

        if (!newRowAtom) {
          newError('DBMD-08Mzj', 'Failed to create new row atom');
          return;
        }

        this.rowReferences.push({
          dataItemId: rowAtomId,
          blockType: 'Row',
          sourceId: this.id
        });
      }
    };
  }

  /* ----------------------------- Public setters ----------------------------- */
  public addRowReference(newRowReference: AtomReference): boolean {
    const refAtomIds = this.rowReferences.map((ref) => ref.dataItemId);

    if (refAtomIds.includes(newRowReference.dataItemId)) {
      newError(
        'DBMD-Nje8E',
        `Duplicate row reference found while adding a new one: ${newRowReference.dataItemId}`
      );
      return false;
    }

    const matchedAtom = this.store.rootStore.atomStore.getAtomById<RowData>(
      newRowReference.dataItemId,
      'Row'
    );

    if (!matchedAtom || matchedAtom instanceof Error) {
      newError(
        'DBMD-Iyy29',
        `Atom ${newRowReference.dataItemId} not found in atom store while adding a new row reference. Reference will not be added.`
      );
      return false;
    }

    this.rowReferences.unshift(newRowReference);

    return true;
  }

  public async deleteRowAtom(
    rowAtomId: string
  ): Promise<boolean | AtomNotDeletableError> {
    const atomToDelete = this.store.rootStore.atomStore.getAtomById<RowData>(
      rowAtomId,
      'Row'
    );

    if (!atomToDelete || atomToDelete instanceof Error) return false;

    if (!atomToDelete.isDeletable) {
      return new AtomNotDeletableError(`Atom ${rowAtomId} is not deletable`);
    }

    const isAtomDeleted =
      await this.store.rootStore.atomStore.deleteAtom(rowAtomId);
    if (!isAtomDeleted) return false;

    return this.removeRowReference(rowAtomId);
  }

  public removeRowReference(rowAtomIdToRemove: string): boolean {
    const matchedRef = this.rowReferences.find(
      (rowRef) => rowRef.dataItemId == rowAtomIdToRemove
    );

    if (!matchedRef) {
      newError(
        'DBMD-wmtOk',
        `Row reference not found while removing one: ${rowAtomIdToRemove}`,
        false,
        {
          errorType: 'warning'
        }
      );
      return false;
    }

    this.rowReferences = this.rowReferences.filter(
      (ref) => ref.dataItemId !== matchedRef.dataItemId
    );

    return true;
  }

  /* ------------------------- Abstract class methods ------------------------- */
  get toJSON() {
    return {
      name: this.name,
      traceKey: this.traceKeyDTO,
      columnDefinitions: this.columns,
      rowReferences: this.rowReferences,
      context: this.context,
      processId: this.processId,
      createdAt: this.created_at,
      updatedAt: this.updated_at,
      deletedAt: this.deleted_at
    };
  }

  get isDeletable(): boolean {
    return this.getRowAtoms().every((rowAtom) => rowAtom.isDeletable);
  }

  get errors(): ModelError[] {
    return [];
  }
}
