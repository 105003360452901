import { useState } from 'react';

import { camelCase } from 'camel-case';
import { Plus } from 'lucide-react';
import { observer } from 'mobx-react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AtomReference, ResolvedVariableType } from 'shared';
import { RowData } from 'shared/src/atom/sources/database/row.atom';
import { Database as CreateDatabaseDTO } from 'shared/src/database/database.schema';
import { TraceKeyMode } from 'shared/src/other/traceKey.schema';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { AtomModel } from '@models/atom.model';

import ActionButton from '@atoms/button';
import { InputField } from '@atoms/input';
import { BasicModal } from '@atoms/modal';

import { LoaderBox } from '@/components/ui/loader';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ParamsList } from '@/routes/routes.types';
import nanoID from '@/utils/nanoID';
import { Button, CircularProgress, DialogActions, IconButton } from '@mui/joy';

import {
  buidsRowData,
  entitiesRowData,
  programsRowData,
  squadsRowData,
  teamsRowData
} from './assets/clientsData';
import {
  DEFAULT_BUIDS_COLUMNS,
  DEFAULT_CAG_COLUMNS,
  DEFAULT_ENTITIES_COLUMNS,
  DEFAULT_FRAUD_COLUMNS,
  DEFAULT_NON_FRAUD_COLUMNS,
  DEFAULT_PROGRAMS_COLUMNS,
  DEFAULT_SQUADS_COLUMNS,
  DEFAULT_TEAMS_COLUMNS
} from './assets/databasesDefinitions';
import { DatabasesContainer } from './database.style';

const Databases = () => {
  const navigate = useNavigate();
  const databaseId = useParams<string>()[ParamsList.DatabaseId];
  const process = useProcess();
  const { databaseStore, atomStore } = useStores();
  const [showNewRepoInput, setShowNewRepoInput] = useState(false);
  const [newDatabaseName, setNewDatabaseName] = useState('');
  const [firstDataModalOpen, setFirstDataModalOpen] = useState<boolean>(false);
  let currentTab: string = '';

  if (!process || process.isSmallProcess) {
    return (
      <LoaderBox>
        <CircularProgress />
      </LoaderBox>
    );
  }

  const onTabChange = (newTabId: string) => {
    currentTab = newTabId;
    navigate(`${newTabId}`);
  };

  const databases = process.databases.map((database) => {
    return {
      id: database.id,
      getName: database.getName
    };
  });

  if (databases.length == 0 && databaseId) {
    setTimeout(() => {
      navigate('');
    }, 100);
  }

  if (databases.length > 0 && !databaseId) {
    setTimeout(() => {
      onTabChange(databases[0].id);
    }, 100);
  }

  if (databaseId) {
    currentTab = databaseId;
  }

  const isUserOwner = () => {
    return process.permission.can_delete;
  };

  const createNewRowAtom = (gridAtomId: string, data: RowData) => {
    const newRowAtom = atomStore.createAtom(
      data.rowAtomId,
      'Row',
      data,
      {
        source: {
          elementId: gridAtomId,
          parentId: gridAtomId,
          parentKind: 'database'
        }
      },
      process.id,
      {
        resolvedType: ResolvedVariableType.Row
      }
    );

    return newRowAtom;
  };

  // TODO: replace this dingz with sdk
  const createNewDatabase = async (name: string) => {
    console.log('Creating new database: ', name);

    setShowNewRepoInput(false);
    setFirstDataModalOpen(false);

    if (!newDatabaseName) return;

    const newGridAtomId = nanoID();

    const columns =
      name === 'Entities'
        ? DEFAULT_ENTITIES_COLUMNS
        : name === 'Programs'
          ? DEFAULT_PROGRAMS_COLUMNS
          : name === 'BUIDS'
            ? DEFAULT_BUIDS_COLUMNS
            : name === 'CAG Investigators'
              ? DEFAULT_CAG_COLUMNS
              : name === 'Fraud Investigators'
                ? DEFAULT_FRAUD_COLUMNS
                : name === 'Non Fraud Investigators'
                  ? DEFAULT_NON_FRAUD_COLUMNS
                  : name === 'Squads'
                    ? DEFAULT_SQUADS_COLUMNS
                    : name === 'Teams'
                      ? DEFAULT_TEAMS_COLUMNS
                      : DEFAULT_ENTITIES_COLUMNS;

    let teamsDatabaseId: string | undefined;
    let programsDatabaseId: string | undefined;
    let buidsDatabaseId: string | undefined;

    if (name === 'Squads') {
      const teamsDatabase = databaseStore
        .toArray()
        .find((database) => database.getName === 'Teams');
      if (!teamsDatabase) {
        console.log('Teams database not found in store, will not add squads');
        return;
      }

      if (!columns[1]?.context?.reference) {
        console.log(
          'Reference not found in squads columns, will not add squads'
        );
        return;
      }
      teamsDatabaseId = teamsDatabase.id;
      columns[1].context.reference.databaseId = teamsDatabaseId;
    }

    if (name === 'Entities') {
      const programsDatabase = databaseStore
        .toArray()
        .find((database) => database.getName === 'Programs');
      if (!programsDatabase) {
        console.log(
          'Programs database not found in store, will not add programs to entities'
        );
        return;
      }
      if (!columns[2]?.context?.reference) {
        console.log(
          'Reference not found in Programs columns, will not add programs to entities'
        );
        return;
      }
      programsDatabaseId = programsDatabase.id;
      columns[2].context.reference.databaseId = programsDatabaseId;

      const buidsDatabase = databaseStore
        .toArray()
        .find((database) => database.getName === 'BUIDS');
      if (!buidsDatabase) {
        console.log(
          'BUIDS database not found in store, will not add buids to entities'
        );
        return;
      }
      if (!columns[3]?.context?.reference) {
        console.log(
          'Reference not found in BUIDS columns, will not add buids to entities'
        );
        return;
      }
      buidsDatabaseId = buidsDatabase.id;
      columns[3].context.reference.databaseId = buidsDatabaseId;
    }

    const newDatabaseDto: CreateDatabaseDTO = {
      id: newGridAtomId,
      name: newDatabaseName,
      columnDefinitions: columns,
      processId: process?.id ?? '',
      rowReferences: [],
      traceKey: {
        value: camelCase(newDatabaseName),
        mode: TraceKeyMode.Follow
      },
      context: {
        rowDeletionDisabled: name === 'Entities'
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: null
    };

    const newDatabaseModel =
      await databaseStore.createNewDatabase(newDatabaseDto);

    if (!newDatabaseModel) {
      return;
    }

    databases.push(newDatabaseModel);
    process.setDatabaseIds([...process.databaseIds, newDatabaseModel.id]);

    setNewDatabaseName('');

    if (name === 'Programs') {
      for (const program of programsRowData) {
        const newRowAtomId = nanoID();
        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          name: program.name,
          proportional: program.proportional
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);

        await new Promise((r) => setTimeout(r, 100));

        if (!newRowAtom) return;

        newDatabaseModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 100));
      }
    } else if (name === 'BUIDS') {
      for (const buid of buidsRowData) {
        const newRowAtomId = nanoID();
        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          BUID: buid.BUID
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);
        await new Promise((r) => setTimeout(r, 100));

        if (!newRowAtom) return;

        newDatabaseModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 100));
      }
    } else if (name === 'Entities') {
      for (const entity of entitiesRowData) {
        const newRowAtomId = nanoID();

        const programsDatabase = databaseStore.get(programsDatabaseId);
        if (!programsDatabase) {
          console.log(
            `Programs database not found in store, will not add programs to entity ${entity.name}`
          );
          continue;
        }

        const programsRows = programsDatabase
          .getRowAtoms()
          .filter((atom) =>
            entity.programs.includes(atom.data['name'] as string)
          );

        const programsAtomRefs = programsRows.map((atom) => {
          return {
            dataItemId: atom.id,
            blockType: 'Row',
            sourceId: programsDatabase.id
          };
        });

        const buidsDatabase = databaseStore.get(buidsDatabaseId);
        if (!buidsDatabase) {
          console.log(
            `BUIDS database not found in store, will not add buids to entity ${entity.name}`
          );
          continue;
        }

        const buidsRows = buidsDatabase
          .getRowAtoms()
          .filter((atom) => entity.buids.includes(atom.data['BUID'] as string));

        const buidsAtomRefs = buidsRows.map((atom) => {
          return {
            dataItemId: atom.id,
            blockType: 'Row',
            sourceId: buidsDatabase.id
          };
        });

        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          name: entity.name,
          label: entity.label,
          programs: programsAtomRefs,
          BUIDS: buidsAtomRefs
        };

        const newEntityRowAtom = createNewRowAtom(newGridAtomId, rowData);
        await new Promise((r) => setTimeout(r, 100));

        if (!newEntityRowAtom) {
          console.log(
            `New row atom for entity ${entity.name} not found, will not add this entity`
          );
          continue;
        }

        if (programsAtomRefs.length > 0) {
          newEntityRowAtom.syncRowReferences(
            programsAtomRefs,
            programsDatabase.id
          );
          await new Promise((r) => setTimeout(r, 100));
        }

        if (buidsAtomRefs.length > 0) {
          newEntityRowAtom.syncRowReferences(buidsAtomRefs, buidsDatabase.id);
          await new Promise((r) => setTimeout(r, 100));
        }

        newDatabaseModel.addRowReference({
          dataItemId: newEntityRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 100));
      }
    } else if (name === 'Teams') {
      for (const team of teamsRowData) {
        const newRowAtomId = nanoID();
        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          name: team.name,
          code: team.code
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);
        await new Promise((r) => setTimeout(r, 100));

        if (!newRowAtom) return;

        newDatabaseModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 100));
      }
    } else if (name === 'Squads') {
      for (const squad of squadsRowData) {
        const newRowAtomId = nanoID();

        const teamDatabase = databaseStore.get(teamsDatabaseId);
        if (!teamDatabase) {
          console.log(
            `Teams database not found in store, will not add squad ${squad.name}`
          );
          continue;
        }

        const teamRow = teamDatabase
          .getRowAtoms()
          .find(
            (atom: AtomModel<RowData>) => atom.data['code'] === squad.team[0]
          );
        if (!teamRow) {
          console.log(
            `Team row for squad ${squad.name}, code: ${squad.team[0]} not found in store, you will need to add it manually`
          );
        }

        const teamRowAtomRef: AtomReference | undefined = teamRow
          ? {
              dataItemId: teamRow.id,
              blockType: 'Row',
              sourceId: teamDatabase.id
            }
          : undefined;

        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          name: squad.name,
          team: teamRowAtomRef ? [teamRowAtomRef] : []
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);
        await new Promise((r) => setTimeout(r, 100));

        if (!newRowAtom) {
          console.log(
            `New row atom for squad ${squad.name} not found, will not add this squad`
          );
          continue;
        }

        if (teamRowAtomRef) {
          newRowAtom.syncRowReferences([teamRowAtomRef], teamDatabase.id);
          await new Promise((r) => setTimeout(r, 100));
        }

        newDatabaseModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 100));
      }
    } else if (name === 'CAG Investigators') {
      return;
    } else if (name === 'Fraud Investigators') {
      return;
    } else if (name === 'Non Fraud Investigators') {
      return;
    }

    onTabChange(newDatabaseModel.id);
  };

  const handleNewRepoKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    name: string
  ) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      void createNewDatabase(name);
    } else if (event.key == 'Escape') {
      setShowNewRepoInput(false);
    }
  };

  return (
    <>
      <DatabasesContainer>
        {databases.length == 0 ? (
          <ActionButton
            value="Create your first database"
            onClick={() => setFirstDataModalOpen(true)}
            sx={{
              width: '250px',
              alignSelf: 'center',
              justifySelf: 'center',
              marginTop: '20px'
            }}
          />
        ) : (
          <Tabs
            value={currentTab}
            onValueChange={onTabChange}
            className="h-full pl-2"
          >
            {databases.map((database) => (
              <TabsContent
                value={database.id}
                key={database.id}
                className="h-88 -mt-0.5"
              >
                <Outlet />
              </TabsContent>
            ))}
            <TabsList className="flex flex-wrap items-center justify-start bg-transparent mt-2">
              {databases.map((databaseModel) => (
                <TabsTrigger value={databaseModel.id} key={databaseModel.id}>
                  {databaseModel.getName}
                </TabsTrigger>
              ))}
              {isUserOwner() &&
                (!showNewRepoInput ? (
                  <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    onClick={() => setShowNewRepoInput(true)}
                    sx={{
                      maxWidth: '100px',
                      marginLeft: '5px'
                    }}
                  >
                    <Plus />
                  </IconButton>
                ) : (
                  <InputField
                    placeholder="Entities"
                    onChange={(e) => setNewDatabaseName(e.target.value)}
                    value={newDatabaseName}
                    width="100px"
                    onBlur={() => setShowNewRepoInput(false)}
                    onKeyDown={(event) =>
                      handleNewRepoKeyDown(event, newDatabaseName)
                    }
                    sx={{
                      marginLeft: 1
                    }}
                  />
                ))}
            </TabsList>
          </Tabs>
        )}
      </DatabasesContainer>
      <BasicModal
        open={firstDataModalOpen}
        setOpen={setFirstDataModalOpen}
        title="Give it a name"
        width="400px"
      >
        <InputField
          placeholder="Entities"
          onChange={(e) => setNewDatabaseName(e.target.value)}
          value={newDatabaseName}
          width="200px"
        />
        <DialogActions>
          <Button
            variant="solid"
            color="primary"
            size="sm"
            disabled={newDatabaseName.length == 0}
            onClick={() => void createNewDatabase(newDatabaseName)}
            sx={{
              maxWidth: '100px'
            }}
          >
            Create
          </Button>
        </DialogActions>
      </BasicModal>
    </>
  );
};

export default observer(Databases);
