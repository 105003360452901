import { AnchorHTMLAttributes } from 'react';

import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

import { cn } from '@/lib/utils';

import { useSubHeaderContext } from './sub-header.comp';

interface ItemProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  link: string;
  to: string;
  name: string;
}
export const SubheaderItem = ({ link, to, name, ...rest }: ItemProps) => {
  const { repositionHighlight, activeNavItem, handleNavItemClick } =
    useSubHeaderContext();

  return (
    <div className="relative py-[6px]" key={name}>
      <NavLink
        to={to}
        onClick={() => handleNavItemClick(link)}
        onMouseOver={(ev) => repositionHighlight(ev, true)}
        className={({ isActive }) =>
          cn([
            'relative text-[14px] py-2 font-normal transition-all',
            isActive
              ? 'text-gray-900 border-b-2 border-solid'
              : 'text-gray-500 '
          ])
        }
        {...rest}
      >
        <div className="transition-all group-[.active]:bg-gray-300 group-[.active]:text-gray-900  rounded-md px-3 py-1 hover:text-gray-800">
          {name}
        </div>
      </NavLink>
      {activeNavItem === link && (
        <motion.div
          className="absolute bottom-0 left-0 right-0 h-[2px] bg-gray-700"
          layoutId="underline"
        />
      )}
    </div>
  );
};
