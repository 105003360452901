import { z } from 'zod';

import { AtomReferenceSchema } from '../../../other/state-menu.schema';

const CellValuesSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.date(),
  z.array(AtomReferenceSchema),
  z.array(z.string()),
  z.null()
]);
export type CellValues = z.infer<typeof CellValuesSchema>;

export const RowDataSchema = z
  .object({ rowAtomId: z.string() })
  .catchall(CellValuesSchema);
export type RowData = z.infer<typeof RowDataSchema>;
