import React, { createContext, useContext, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import {
  DRAFT_PROCESS_MENU_ITEMS,
  STRATUMN_PROCESS_MENU_ITEMS,
  WORKFLOW_MENU_ITEMS
} from '@pages/Settings/menu';

import useProcess from '@hooks/useProcess';

import { ParamsList } from '@/routes/routes.types';

import {
  SubheaderContainer,
  SubheaderMenu,
  TabsHighlight
} from './sub-header.style';

type TabName =
  | (typeof DRAFT_PROCESS_MENU_ITEMS)[number]['name']
  | (typeof STRATUMN_PROCESS_MENU_ITEMS)[number]['name']
  | (typeof WORKFLOW_MENU_ITEMS)[number]['name'];
interface SubHeaderContextProps {
  repositionHighlight: (e: React.MouseEvent, tab: boolean) => void;
  activeNavItem: string | null;
  handleNavItemClick: (name: string) => void;
}
const SubHeaderContext = createContext<SubHeaderContextProps | null>(null);

export const useSubHeaderContext = () => {
  const context = useContext(SubHeaderContext);
  if (!context) {
    throw new Error('useSubHeaderContext must be used within SubHeader');
  }
  return context;
};

export const SubHeader = ({ children }) => {
  const process = useProcess();
  const location = useLocation();
  const processId = useParams()[ParamsList.ProcessId] as string;
  const currentWorkflowId = useParams()[ParamsList.WorkflowId];

  const [tabBoundingBox, setTabBoundingBox] = React.useState<Maybe<DOMRect>>();
  const [wrapperBoundingBox, setWrapperBoundingBox] =
    React.useState<Maybe<DOMRect>>();
  const [highlightedTab, setHighlightedTab] = React.useState<boolean | null>(
    null
  );
  const [isHoveredFromNull, setIsHoveredFromNull] = React.useState(true);
  const [activeNavItem, setActiveNavItem] = useState<string | null>(null);

  const handleNavItemClick = (navName: string) => {
    setActiveNavItem(navName);
  };

  const highlightRef = React.useRef(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const repositionHighlight = (e: any, tab: boolean) => {
    setTabBoundingBox(
      // eslint-disable-next-line
      e.target!.getBoundingClientRect() as unknown as Maybe<DOMRect>
    );
    setWrapperBoundingBox(wrapperRef.current?.getBoundingClientRect());
    setIsHoveredFromNull(!highlightedTab);
    setHighlightedTab(tab);
  };

  const resetHighlight = () => {
    setHighlightedTab(null);
  };

  const highlightStyles: React.CSSProperties = {};

  if (tabBoundingBox && wrapperBoundingBox) {
    highlightStyles.transitionDuration = isHoveredFromNull ? '0ms' : '150ms';
    highlightStyles.width = `${tabBoundingBox.width}px`;
    highlightStyles.opacity = highlightedTab ? 1 : 0;
    highlightStyles.transform = `translate(${
      tabBoundingBox.left - wrapperBoundingBox.left
    }px)`;
  }
  useEffect(() => {
    let tabName: TabName;
    if (currentWorkflowId) {
      tabName = window.location.pathname
        .split(`${currentWorkflowId}/`)[1]
        ?.split('/')[0];
    } else {
      tabName = window.location.pathname
        .split(`${processId}/`)[1]
        ?.split('/')[0];
    }

    setActiveNavItem(tabName);
  }, [location.pathname, currentWorkflowId, processId]);

  if (!process) {
    return null;
  }

  return (
    <SubHeaderContext.Provider
      value={{ repositionHighlight, activeNavItem, handleNavItemClick }}
    >
      <SubheaderContainer
        ref={wrapperRef}
        className="w-full bg-white pl-[23px]  flex flex-row gap-x-1 border-b border-solid border-gray-200 relative h-10"
        onMouseLeave={resetHighlight}
      >
        <TabsHighlight
          className="bg-gray-200"
          ref={highlightRef}
          style={highlightStyles}
        />
        <SubheaderMenu>{children}</SubheaderMenu>
      </SubheaderContainer>
    </SubHeaderContext.Provider>
  );
};
