import { EventType } from 'shared';

import { WorkflowModel } from '@models/workflow.model';

import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import {
  CreateShapeEvent,
  DeleteShapeEvent,
  ShapeEvents
} from '@/types/event.types';

import { UserAction } from '../shape/shape.plexer';

function bpmnTypeToStudioType(type: string): EventType | undefined {
  switch (type) {
    case 'bpmn:StartEvent':
      return 'START';
    case 'bpmn:EndEvent':
      return 'END';
    default:
      return undefined;
  }
}

export const onEventCreate = async (
  event: CreateShapeEvent,
  workflowModel: WorkflowModel,
  rootStore: RootStore
): Promise<boolean> => {
  const eventType = bpmnTypeToStudioType(event.context.shape?.type || '');

  if (!eventType) {
    newError(
      'EVENT-dKJ6S',
      `Event type ${event.context.shape?.type} is not supported`,
      true
    );
    return false;
  }

  const rawShapeId = event.context.shape?.id;
  const rawWorflowId = event.context.parent?.id;

  if (!rawShapeId || !rawWorflowId) return false;

  const workflowId = workflowModel.id;

  const eventStore = rootStore.eventStore;

  const res = await eventStore.createEvent(rawShapeId, eventType, workflowId);

  if (res) {
    return true;
  } else {
    return false;
  }
};

export const onEventDeleted = async (
  event: DeleteShapeEvent,
  _workflowModel: WorkflowModel,
  rootStore: RootStore
): Promise<boolean> => {
  const eventType = bpmnTypeToStudioType(event.context.shape?.type || '');

  if (!eventType) {
    newError(
      'EVENT-Jngd3',
      `Event type ${event.context.shape?.type} is not supported`,
      true
    );
    return false;
  }

  const shapeId = event.context.shape?.id;

  if (!shapeId) return false;

  const eventStore = rootStore.eventStore;

  return await eventStore.deleteById(shapeId);
};

export const eventFunctionPlexer: Record<
  UserAction,
  (
    event: ShapeEvents,
    workflowModel: WorkflowModel,
    rootStore: RootStore
  ) => Promise<boolean>
> = {
  create: onEventCreate,
  delete: onEventDeleted
};
