import * as React from 'react';
import { forwardRef } from 'react';

import styled from 'styled-components';

const TabsHighlight = styled.div`
  position: absolute;
  left: 0px;
  border-radius: 4px;
  transition: 0.15s ease;
  transition-property: width, transform, opacity;
  top: 4px;
  bottom: 4px;
  height: 80%;
`;

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}
const SubheaderContainer = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={`w-full bg-white pl-[23px] flex flex-row gap-x-1 border-b border-solid border-gray-200 relative h-10 ${className}`}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

const SubheaderMenu: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return <div className="flex">{children}</div>;
};

export { SubheaderContainer, SubheaderMenu, TabsHighlight };
